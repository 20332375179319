import React, { useState, useContext, useEffect } from 'react';
import { ClientContext } from '../../../contexts/clientProvider';
import { AnexosContext } from '../../../contexts/anexosProvider';
import { UserContext } from '../../../contexts/userProvider';
import { anexosCadastro, cadastroComponentMap, anexosLinhas, linhasComponentMap } from '../../General/Format/anexo';
import { BiTrash } from 'react-icons/bi';
import '../styles.css';

const Cadastro = ({ setType }) => {
    const { user, setAlert } = useContext(UserContext);
    const { clientes } = useContext(ClientContext);
    const { userId, createAnexo } = useContext(AnexosContext);
    const [anexoData, setAnexoData] = useState({});
    const [linhas, setLinhas] = useState([]);
    const [clients, setClients] = useState(null);

    useEffect(() => {
        setClients(clientes || []);
    }, [clientes]);

    const handleSelectClient = (e) => {
        setAnexoData((prevData) => ({ ...prevData, id_cliente: e.target.value }));
    };

    const handleInputChangeAnexo = (e) => {
        setAnexoData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    };

    const handleInputChangeLinha = (e, index) => {
        const updatedLinhas = [...linhas];
        updatedLinhas[index] = { ...updatedLinhas[index], [e.target.name]: e.target.value };
        setLinhas(updatedLinhas);
    };

    const handleAddLinha = () => {
        const newLine = { id_cliente: anexoData.id_cliente, id_user: userId, created_at: new Date().toISOString().slice(0, 19).replace('T', ' '), updated_at: new Date().toISOString().slice(0, 19).replace('T', ' ') };
        setLinhas([...linhas, newLine]);
    };

    const handleRemoveLinha = (index) => setLinhas([...linhas.slice(0, index), ...linhas.slice(index + 1)]);

    const handleSaveClick = async () => {
        if (!anexoData.id_cliente) {
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Preencha todos os campos obrigatórios.', confirm: false, type: "warning" });
            return;
        }
        anexoData.telefone = linhas[0]?.linha;
        anexoData.id_usuario = userId;
        anexoData.usuario = user.nome;
        anexoData.created_at = anexoData.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
        anexoData.ativo = '1';
        const anexo = await createAnexo(anexoData, linhas);
        if (anexo) {
            setAnexoData({});
            setLinhas([]);
            setType('consulta');
        }
    };

    const dadosAnexo = anexosCadastro(anexoData);
    const componentMapAnexo = cadastroComponentMap(handleInputChangeAnexo);
    const componentMapLinhas = linhasComponentMap(handleInputChangeLinha);

    return (
        <>
            <select className='anexos-consulta-select' onChange={handleSelectClient}>
                <option value=''>Selecione o cliente</option>
                {clients && clients.slice().sort((a, b) => a.nome.localeCompare(b.nome)).map((client, index) => (
                    <option key={index} value={client.id}>{client.nome}</option>
                ))}
            </select>
            {anexoData.id_cliente && (
                <>
                    <div className='form-container'>
                        {dadosAnexo.map((dado, index) => (
                            <div className='form-group' key={index}>{componentMapAnexo['Cadastro'](dado)}</div>
                        ))}
                    </div>
                    <button className='anexos-cadastro-button' onClick={handleAddLinha}>Adicionar linha</button>
                    {linhas.map((linha, index) => (
                        <div className="form-linhas-wrapper" key={index}>
                            <div className='form-container-linhas'>
                                {anexosLinhas(linha).map((dado, subIndex) => (
                                    <div className='form-group-linhas' key={subIndex}>
                                        {componentMapLinhas['Linhas']({ ...dado, index })}
                                    </div>
                                ))}
                            </div>
                            <BiTrash className='form-linhas-icon' style={{ color: 'red' }} onClick={() => handleRemoveLinha(index)} />
                        </div>
                    ))}
                    <button className='anexos-cadastro-button' onClick={handleSaveClick}>Salvar</button>
                </>
            )}
        </>
    );
};

export default Cadastro;
