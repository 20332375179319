import { createContext, useEffect, useState, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const PatrimonioContext = createContext();

export const PatrimonioProvider = ({ children }) => {
  const { setAlert } = useContext(UserContext);
  const [grupos, setGrupos] = useState([]);
  const [equipamentos, setEquipamentos] = useState([]);
  const [patrimonios, setPatrimonios] = useState([]);
  const [patLoading, setPatLoading] = useState(false);

  const createPatrimonio = async (patrimonio) => {
    try {
      const response = await apiUser.post(`patrimonio/createPatrimonio`, patrimonio);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
        return true;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao criar patrimônio",
      });
    }
  }

  const marcasByFornecedor = async (id) => {
    try {
      const response = await apiUser.post(`patrimonio/marcasByFornecedorId`, { id });
      const { success, message, dados } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      setAlert({
        type: "error",
        message: "Erro ao buscar marcas",
      });
    }
  }

  const modelosByMarca = async (id) => {
    try {
      const response = await apiUser.post(`patrimonio/modelosByMarcaId`, { id });
      const { success, message, dados } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar modelos",
      });
    }
  }

  const criarMarca = async (marca) => {
    try {
      const response = await apiUser.post(`patrimonio/createMarca`, marca);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao criar marca",
      });
    }
  }

  const criarModelo = async (modelo) => {
    try {
      const response = await apiUser.post(`patrimonio/createModelo`, modelo);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao criar modelo",
      });
    }
  }

  const deleteMarca = async (id) => {
    try {
      const response = await apiUser.post(`patrimonio/deleteMarcaById`, { id });
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao deletar marca",
      });
    }
  }

  const deleteModelo = async (id) => {
    try {
      const response = await apiUser.post(`patrimonio/deleteModeloById`, { id });
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao deletar modelo",
      });
    }
  }

  const dadosGrupos = async () => {
    try {
      const response = await apiUser.get(`patrimonio/dadosGrupos`);
      const { success, message, dados } = response.data;
      if (success) {
        setGrupos(dados);
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar grupos",
      });
    }
  }


  const criarGrupo = async (grupo) => {
    try {
      const response = await apiUser.post(`patrimonio/createGrupo`, grupo);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
        dadosGrupos();
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao criar grupo",
      });
    }
  }

  const dadosEquipamentos = async () => {
    try {
      const response = await apiUser.get(`patrimonio/dadosModelos`);
      const { success, message, dados } = response.data;
      if (success) {
        setEquipamentos(dados);
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const equipamentosByGrupo = async (grupo) => {
    try {
      const response = await apiUser.post(`patrimonio/equipamentosByGrupoId`, grupo);
      const { success, message, dados } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const updateGrupoEquipamentos = async (grupo) => {
    try {
      const response = await apiUser.post(`patrimonio/updateEquipamentosByGrupo`, grupo);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
        return true;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }

  }

  const buscarPatrimoniosNfs = async (patrimoniosDados) => {
    setPatLoading(true);
    try {
      const response = await apiUser.post(`patrimonio/buscarPatrimoniosnfs`, patrimoniosDados);
      const { success, message, dados } = response.data;
      if (success) {
        setPatrimonios(dados);
        return true;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setPatLoading(false);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }finally{
      setPatLoading(false);
    }
  }

  const buscarPatrimonios = async (patrimoniosDados) => {
    setPatLoading(true);
    try {
      const response = await apiUser.post(`patrimonio/buscarPatrimonios`, patrimoniosDados);
      const { success, message, dados } = response.data;
      if (success) {
        setPatrimonios(dados);
        return true;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      setPatLoading(false);
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }finally{
      setPatLoading(false);
    }
  }

  const updatePatrimonioById = async (patrimonio) => {
    try {
      const response = await apiUser.post(`patrimonio/updatePatrimonioById`, patrimonio);
      const { success, message, dados } = response.data;
      if (success) {
        updatePatrimonioState(dados, patrimonios, setPatrimonios);
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const updatePatrimonioNfsById = async (patrimonio) => {
    try {
      const response = await apiUser.post(`patrimonio/updatePatrimonioNfsById`, patrimonio);
      const { success, message, dados } = response.data;
      if (success) {
        updatePatrimonioState(dados, patrimonios, setPatrimonios);
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const searchPatrimonioByEtiquetaId = async (id) => {
    try {
      const response = await apiUser.post(`patrimonio/searchPatrimonioByEtiquetaId`, { id });
      const { success, message, dados } = response.data;
      if (success) {
        return dados;
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const movimentarPatrimonios = async (movimento) => {
    try {
      const response = await apiUser.post(`patrimonio/movimentarPatrimonios`, movimento);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }

  const criarPatrimonioNfsByXml = async (xmlData) => {
    try {
      const response = await apiUser.post(`patrimonio/criarPatrimonioNfsByXml`, xmlData);
      const { success, message } = response.data;
      if (success) {
        setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });

      } else {
        setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
      }
      return success;
    } catch (error) {
      console.log(error);
      setAlert({
        type: "error",
        message: "Erro ao buscar equipamentos",
      });
    }
  }


  function updatePatrimonioState(pat, patrimoniosState, setPats) {
    console.log(pat);
    let pats = [...patrimoniosState];
    let index = pats.findIndex((p) => p.id === pat.id);
    pats[index] = pat;
    setPats(pats);
  }


  useEffect(() => {
    dadosEquipamentos();
  }, []);


  useEffect(() => {
    dadosGrupos();
  }, []);

  return (
    <PatrimonioContext.Provider
      value={{
        marcasByFornecedor,
        modelosByMarca,
        criarMarca,
        criarModelo,
        deleteMarca,
        deleteModelo,
        grupos,
        criarGrupo,
        equipamentos,
        equipamentosByGrupo,
        updateGrupoEquipamentos,
        createPatrimonio,
        buscarPatrimoniosNfs,
        buscarPatrimonios,
        patrimonios,
        updatePatrimonioById,
        updatePatrimonioNfsById,
        searchPatrimonioByEtiquetaId,
        movimentarPatrimonios,
        criarPatrimonioNfsByXml,
        patLoading
      }}
    >
      {children}
    </PatrimonioContext.Provider>
  );
};