import { useState } from "react";
import Secretarias from "./Types/secretarias";
import "./styles.css";

const Relatorio = () => {
  const [type, setType] = useState("Secretarias");

  const relatoriosType = {
    "Secretarias": <Secretarias />,
  };

  const relatoriosLabels = Object.keys(relatoriosType);

  return (
    <div className="faturador-contander">
      <div className="faturador-header">
        {relatoriosLabels.map((label) => (
          <button
            key={label}
            className="faturador-button"
            onClick={() => setType(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="faturador-body">{relatoriosType[type]}</div>
    </div>
  );
};

export default Relatorio;
