import React from 'react';
import { 
  BiSolidDashboard, BiPaperclip, BiSolidWrench, BiRss, 
  BiSolidDollarCircle, BiClipboard, BiLogOutCircle 
} from "react-icons/bi";
import { FaUsers, FaPhoneAlt, FaDollyFlatbed, FaLaptopCode, FaTools } from "react-icons/fa";
import { BsCashCoin } from "react-icons/bs";
import { FaXmarksLines } from "react-icons/fa6";

const Icons = (title, sideBar, size) => {
  const iconSize = size || (sideBar ? 28 : 24);
  const margIcon = sideBar ? 2 : 0;
  const iconMap = {
    Dashboard: <BiSolidDashboard className="sidebar-icon" size={iconSize} title="DashBoard" />,
    Clientes: <FaUsers className="sidebar-icon" size={iconSize} title="Clientes" />,
    Anexos: <BiPaperclip className="sidebar-icon" size={iconSize} title="Anexos" />,
    Linhas: <FaPhoneAlt className="sidebar-icon" size={iconSize} title="Linhas" />,
    Serviços: <BiSolidWrench className="sidebar-icon" size={iconSize} title="Serviços" />,
    Fornecedores: <BiRss className="sidebar-icon" size={iconSize} title="Fornecedores" />,
    Patrimônio: <BiSolidDollarCircle className="sidebar-icon" size={iconSize} title="Patrimônio" />,
    Relatórios: <BiClipboard className="sidebar-icon" size={iconSize} title="Relatórios" />,
    Faturador: <BsCashCoin className="sidebar-icon" size={iconSize} title="Faturador" style={{marginLeft: margIcon}}/>,
    SaperX: <FaXmarksLines className="sidebar-icon" size={iconSize} title="SaperX" />,
    Entrada: <FaDollyFlatbed className="sidebar-icon" size={iconSize} title="Entrada" />,
    Sair: <BiLogOutCircle className="sidebar-icon" size={iconSize} title="Sair" />,
    "Vox Softwares": <FaLaptopCode className="sidebar-icon" size={iconSize} title="Vox Softwares" />,
    "Faturar Equipamentos": <FaTools className="sidebar-icon" size={iconSize} title="Faturar Equipamentos" />
  };

  return iconMap[title] || null; 
};

export default Icons;
