import React from 'react'

const value = ({ valor }) => {
    return (
        <p>{valor}</p>
    )

}

export default value
