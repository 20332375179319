import React, { useEffect, useState } from 'react';

const NumberInput = ({ handleInputChange, dados }) => {
    const [inputValue, setInputValue] = useState('R$');

    useEffect(() => {
        if (dados.valor) setInputValue(dados.valor);
    }, [dados.valor]);

    const formatValue = (value) => {
        const cleanedValue = value.replace(/[^\d]/g, '');
        const numberValue = parseFloat(cleanedValue) / 10000; 
        return isNaN(numberValue) ? '' : numberValue.toLocaleString('pt-BR', {
            style: 'decimal',
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
        });
    };

    const handleChange = (e) => {
        const rawValue = e.target.value.replace(/\./g, '').replace(',', '.');
        const formattedValue = formatValue(rawValue); 
        setInputValue(formattedValue); 
        handleInputChange({ ...e, target: { ...e.target, value: formattedValue, name: dados.name } }, dados.index);
    };

    return (
        <div className='number-container'>
            <label style={dados.visible ? { display: 'none' } : null}>{dados.titulo}</label>
            <input
                value={inputValue}
                name={dados.name}
                onChange={handleChange}
                disabled={dados.disabled ? dados.disabled : false}
                placeholder={dados.placeholder ? dados.placeholder : ''}
                style={dados.visible ? { display: 'none' } : null}
            />
        </div>
    );
};

export default NumberInput;
