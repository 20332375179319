import { useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from "react-icons/bi"; 
import Icons from './icons'; 

const Group = ({ title, sideBar, links, handleLinkClick, selectedItem}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSubItems = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (!selectedItem.includes('/')) {
            setIsOpen(false);
        }
    }, [selectedItem]);

    const margItem = sideBar ? 15 : 0;

    return (
        <div className="sidebar-group">
            <div className="sidebar-item" onClick={toggleSubItems}>
                {Icons(title, sideBar, 24)} 
                {sideBar ? <span>{title}</span> : null}
                {sideBar && (
                    isOpen ? <BiChevronUp className="sidebar-icon" size={28} /> : <BiChevronDown className="sidebar-icon" size={28}/>
                )}
            </div>
            {isOpen && (
                <div className="sidebar-item-group">
                    {links.map((subItem, index) => (
                        <div
                            key={index}
                            className={`sidebar-item ${selectedItem === subItem.link ? 'selected' : ''}`}
                            style={{ marginLeft: margItem }}
                            onClick={() => handleLinkClick(subItem.link)}
                        >
                            {!sideBar && Icons(subItem.name, false, 20)}
                            {sideBar ? <span>{subItem.name}</span> : null} 
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Group;
