import React, { useContext } from "react";
import { UserContext } from "../../../../contexts/userProvider";
import Icons from "./icons"; // Importando o componente de ícones

const Normal = (props) => {
  const { signOut } = useContext(UserContext);

  const handleClick = () => {
    if (props.title === "Sair") {
      signOut();
    } else {
      props.handleLinkClick(props.title);
    }
  };

  return (
    <div className="sidebar-item" onClick={handleClick}>
      {Icons(props.title, props.sideBar)}
      {props.sideBar && <span>{props.title}</span>}
    </div>
  );
};

export default Normal;
