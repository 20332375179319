import React, { useState, useContext, useNavigate } from 'react'
import { UserContext } from '../../../contexts/userProvider'
import { BiUser, BiArrowBack  } from 'react-icons/bi'
import '../styles.css'


const Esqueci = () => {
    const { esqueciSenha, setAlert } = useContext(UserContext);
    const [login, setLogin] = useState({ email: '' })

    function handleEmailChange(event) {
        setLogin({ ...login, email: event.target.value })
    }

    function logIn() {
        if(login.email === '') {
            setAlert({visible: true, title: 'Atenção!', placeholder: 'Pare realizar o login, preencha todos os campos!', confirm: false, type: 'warning'})
        } else {
            esqueciSenha(login.email)
        }
    }


    return (
        <div className='login-wrapper'>
            <div className='login-container'>
                <div className='login-header'>
                    <a href='/login'><BiArrowBack  style={{color:"#142a4c",marginRight:"20px"}} size={30}/></a>
                    <h1>Esqueci a senha</h1>
                </div>
                <div className='login-form'>
                    <div className="login-input-content">
                        <input className='login-input' type='text' placeholder='E-mail' onChange={handleEmailChange} />
                        <BiUser className='login-icon' />
                    </div>

                    <button className='login-button' onClick={logIn}>Enviar</button>

                </div>
            </div>

        </div>
    )
}

export default Esqueci
