import { useState, useEffect, useContext } from "react";
import { VoxSoftwaresContext } from "../../../contexts/voxSoftwaresProvider";
import { getDadosUsuarios, getComponentMap } from '../../General/Format/organizacao'
import { BiX } from "react-icons/bi";

const UserModal = ({ newOrg , selectedUser, closeModal }) => {
  const { novoUsuario, addUser, updateUser ,setAlert } = useContext(VoxSoftwaresContext);
  const [newUser, setNewUser] = useState({})
  const [previousUser, setPreviousUser] = useState({})
  const [departamentos, setDepartamentos] = useState([])
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [setores, setSetores] = useState([])

  useEffect(() => {
    if(newOrg) {
      let deps = newOrg?.departamentos ? [...newOrg.departamentos] : []
      let sects = deps.map(dep => dep.setores).flat()
      setDepartamentos(deps)
      setSetores(sects)
    }
  },[])

  useEffect(() => {
    if(selectedUser) {
      setNewUser(selectedUser)
      setPreviousUser(selectedUser)
      setPasswordVisible(true)
    }else{
      setNewUser(novoUsuario)
      setPasswordVisible(false)
    }
  },[selectedUser])

  const handleOutsideClick = (e) => {
      if(e.target.classList.contains('modal-edit')) {
          closeModal()
      }
  }

  const handleInputChange = (e) => {
    const { name, checked, type, value } = e.target;
    
    if (type === 'checkbox') {
      if (['atendenteGrupo', 'admin', 'atendente'].includes(name)) {
        setNewUser({ ...newUser, [name]: checked });
      }
    } else {
      if (name === 'ativo') {
        setNewUser({ ...newUser, [name]: value === 'Inativo' ? false : true });
      } else {
        setNewUser({ ...newUser, [name]: value });
      }
    }
  };

  const handleSave = () => {
    addUser(newUser, newOrg)
    closeModal()
  }

  const handleEdit = () => {
    if(newUser !== previousUser) {
      updateUser(newUser, newOrg)
      closeModal()
    }else{
    setAlert({visible: true,title: 'Atenção',placeholder: 'Nenhum dado foi alterado.',confirm: false,type: 'warning'});
    }
  }

  const dados = getDadosUsuarios(newUser, departamentos, setores, passwordVisible);
  const componentMap = getComponentMap(handleInputChange);

  return (
    <div className="modal-edit" onClick={handleOutsideClick}>
      <div className="modal-edit-body" style={{width:'60%',height:'80%', justifyContent:'flex-start'}}>
        <BiX className="fechar-top-button" onClick={()=>closeModal()} />
        <div className="modal-edit-header">
          <h1>{selectedUser ? selectedUser.nome : 'Adicionar usuário'}</h1>
        </div>
        <div className="modal-edit-content" style={{width:'100%'}}>
          {dados.map((dado, index) => {
            return (
              <div className="form-softwares" key={index}>
                {componentMap["Cadastro"](dado)}
              </div>
            );
          })}
        </div>
        <div className="modal-edit-footer">
          <button className="next-button" style={{width:'200px'}} onClick={selectedUser ? handleEdit : handleSave}>Salvar</button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
