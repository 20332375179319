import { useState, useContext } from "react";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";

const Cadastro = (props) => {
    const { criarGrupo } = useContext(PatrimonioContext);
    const [novoGrupo, setNovoGrupo] = useState({
        nome: "",
    });

    const handleInput = (e) => {
        const { value, name } = e.target;
        setNovoGrupo({ ...novoGrupo, [name]: value });
    };

    const handleCreateGrupo = async () => {
        let newG = { ...novoGrupo };
        newG.created_at = newG.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
        let success = await criarGrupo(newG);
        if(success){
            props.setCadastroModal(false);
        }
    }

    return (
        <div className="vizualizar-modal">
            <div className="vizualizar-modal-body">
                <div className="patrimonio-modal-header">
                    <h1>Cadastro de Grupo</h1>
                </div>
                <div className="vizualizar-modal-content">
                    <div className="patrimonio-modal-content-inputs">
                        <label>Nome: </label>
                        <input
                            type="text"
                            value={novoGrupo.nome}
                            onChange={handleInput}
                            name="nome"
                            className="patrimonio-consulta-select"
                        />
                    </div>
                </div>
                <div className="patrimonio-modal-footer">
                    <button
                        className="patrimonio-cadastro-button"
                        onClick={(e) => props.setCadastroModal(false)}
                    >
                        Fechar
                    </button>
                    <button
                        className="patrimonio-cadastro-button"
                        onClick={handleCreateGrupo}
                    >
                        Salvar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Cadastro;
