import Dashcard from "../DashCard";
import FormComp from "../FormsComp";


export const dadosFornecedores = (fornecedor) => {
    return [
        {
            titulo: 'Situação',
            valor: fornecedor?.ativo  === '1' ? 'Ativo' : 'Desativado',
            valorSelect: fornecedor?.ativo === '1' ? '1' : '0',
            name: 'ativo',
            tipo: 'Select',
            options: [{ id: '1', nome: 'Ativo' }, { id: '0', nome: 'Desativado' }]
        },
        {
            titulo: 'CNPJ',
            valor: fornecedor?.cpf_cnpj || 'Nenhum dado cadastrado.',
            name: 'cpf_cnpj',
            tipo: 'Input'
        },
        {
            titulo: 'Nome do Fornecedor',
            valor: fornecedor?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone',
            valor: fornecedor?.telefone || 'Nenhum dado cadastrado.',
            name: 'telefone',
            tipo: 'Input'
        },
        {
            titulo: 'Endereço',
            valor: fornecedor?.endereco || 'Nenhum dado cadastrado.',
            name: 'endereco',
            tipo: 'Input'
        },
        {
            titulo: 'Número',
            valor: fornecedor?.numero || 'Nenhum dado cadastrado.',
            name: 'numero',
            tipo: 'Input'
        },
        {
            titulo: 'Estado',
            valor: fornecedor?.estado || 'Nenhum dado cadastrado.',
            name: 'estado',
        },
        {
            titulo: 'Cidade',
            valor: fornecedor?.municipio || 'Nenhum dado cadastrado.',
            name: 'municipio',
        },

    ]
}

export const componentsMap = (closeModal, handleCancelClick, handleSaveClick, handleEditClick, handleInputChange) => {
    return {
        Geral: (dado) => <Dashcard titulo={dado.titulo} valor={dado.valor} />,
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='vizualizar-modal-button' onClick={handleCancelClick}>Cancelar</button>
                <button className='vizualizar-modal-button' onClick={handleSaveClick}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='vizualizar-modal-button' onClick={() => closeModal()}>Fechar</button>
                <button className='vizualizar-modal-button' onClick={handleEditClick}>Editar</button>
            </>
        )
    }
}

export const cadastroComponentsMap = (handleInputChange) => {
    return {
        Cadastro: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
    }
}