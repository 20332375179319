import { useState, useContext } from "react";
import { UserContext } from "../../../../contexts/userProvider";
import { PatrimonioContext } from "../../../../contexts/patrimonioProvider";

const Cadastro = (props) => {
  const { user } = useContext(UserContext);
  const { criarMarca } = useContext(PatrimonioContext);
  const [novaMarca, setNovaMarca] = useState({
    nome: "",
    id_fornecedor: props.fornecedor.id,
    id_usuario: user.id,
    usuario: user.nome,
  });

  const handleInput = (e) => {
    const { value, name } = e.target;
    setNovaMarca({ ...novaMarca, [name]: value });
  };

  const handleCreateMarca = async () => {
    let newMarca = { ...novaMarca };
    newMarca.created_at = newMarca.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    let success = await criarMarca(newMarca);
    if(success){
      props.setCadastroModal(false);
      props.receberMarcas()
    }
  }

  return (
    <div className="vizualizar-modal">
      <div className="vizualizar-modal-body">
        <div className="patrimonio-modal-header">
          <h1>Cadastro de Marca</h1>
          <h1>Nome do Fornecedor : {props.fornecedor.nome}</h1>
        </div>
        <div className="vizualizar-modal-content">
          <div className="patrimonio-modal-content-inputs">
            <label>Nome: </label>
            <input
              type="text"
              value={novaMarca.nome}
              onChange={handleInput}
              name="nome"
              className="patrimonio-consulta-select"
            />
          </div>
        </div>
        <div className="patrimonio-modal-footer">
          <button
            className="patrimonio-cadastro-button"
            onClick={(e) => props.setCadastroModal(false)}
          >
            Fechar
          </button>
          <button
            className="patrimonio-cadastro-button"
            onClick={handleCreateMarca}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cadastro;
