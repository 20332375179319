import { useState, useEffect, useContext } from 'react'
import { BiX } from "react-icons/bi";
import { EditPatrimonio, getComponentMap } from '../../../General/Format/patrimonio'
import { AnexosContext } from '../../../../contexts/anexosProvider';
import { PatrimonioContext } from '../../../../contexts/patrimonioProvider';
import { UserContext } from '../../../../contexts/userProvider';

const EditPatrimonioModal = ({ setEditModal, patrimonioSelecionado, patrimonioType, patrimonios }) => {
  const { anexosByClient } = useContext(AnexosContext);
  const { marcasByFornecedor, modelosByMarca, updatePatrimonioById, updatePatrimonioNfsById } = useContext(PatrimonioContext);
  const { setAlert } = useContext(UserContext);
  const [patrimonio, setPatrimonio] = useState({})
  const [anexos, setAnexos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);


  useEffect(() => {
    let patrimonio = patrimonios.find(p => p.id === patrimonioSelecionado);
    setPatrimonio(patrimonio);
  }, [patrimonios]);

  useEffect(() => {
    let idCliente = patrimonio?.id_cliente;
    if (idCliente) {
      anexosByClient(idCliente).then((anexos) => {
        setAnexos(anexos);
      });
    }
  }, [patrimonio?.id_cliente]);

  useEffect(() => {
    let idFornecedor = patrimonio?.id_fornecedor ? patrimonio?.id_fornecedor : 2;
    if (idFornecedor) {
      marcasByFornecedor(idFornecedor).then((marcas) => {
        setMarcas(marcas);
      });
    }
  }, [patrimonio?.id_fornecedor]);

  useEffect(() => {
    let idMarca = patrimonio?.id_marca;
    if (idMarca) {
      modelosByMarca(idMarca).then((modelos) => {
        setModelos(modelos);
      });
    }
  }, [patrimonio?.id_marca]);

  const handleEditCloseModal = () => {
    setEditModal(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatrimonio({ ...patrimonio, [name]: value });
  }


  const handleSaveClick = async (e) => {
    e.preventDefault();
    try {
      const patrimonioAtualizado = { ...patrimonio };

      delete patrimonioAtualizado.equipamento;
      delete patrimonioAtualizado.nome_anexo;
      delete patrimonioAtualizado.nome_cliente;
      patrimonioAtualizado.updated_at = new Date().toISOString().slice(0, 19).replace("T", " ");

      const requiredFields = ['id_cliente', 'id_anexo', 'id_marca', 'id_modelo'];
      //, 'nome', 'setor', 'ramal'
      if (requiredFields.some(field => !patrimonioAtualizado[field])) {
        setAlert({ visible: true, title: "Atenção!", placeholder: 'Todos os campos são obrigatórios.', confirm: false, type: "warning" });
        return;
      }
      if (patrimonioType === 'inventario') delete patrimonioAtualizado.password_telefone;

      const updateFunction = patrimonioType === 'novo' ? updatePatrimonioNfsById : updatePatrimonioById;
      await updateFunction(patrimonioAtualizado);
      setPatrimonio({});
      setEditModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  const dados = EditPatrimonio(patrimonio, anexos, marcas, modelos);
  const componentMap = getComponentMap(handleInputChange);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <div className='modal-edit'>
      <div className='modal-edit-body' style={{ justifyContent: "space-between", width: '70%', height: '80%' }}>
        <div className='modal-edit-header' style={{ display: "flex", alignItems: "center" }}>
          <h1>Editar Patrimônio</h1>
          <BiX onClick={handleEditCloseModal} className='fechar-top-button' />
        </div>
        <form onSubmit={handleSaveClick} onKeyDown={handleKeyDown}>
          <div className='form-container'>
            {dados.map((dado) => (
              <div className='form-group' key={dado.titulo}>
                {componentMap.Cadastro(dado)}
              </div>
            ))}
          </div>
          <div className='modal-edit-footer' style={{ width: "100%", display: "flex", gap: "20px", justifyContent: "center" }}>
            <button className='patrimonio-cadastro-button' onClick={() => handleEditCloseModal()}>Cancelar</button>
            <button className='patrimonio-cadastro-button' type='submit'>Salvar</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditPatrimonioModal
