import React from 'react';
import './styles.css';
import { BiX } from "react-icons/bi";

const index = ({ anexos, removeAnexo, setores, removeSetor, removeAllAnexos, removeAllSetores}) => {
    const both = anexos.length > 0 && setores.length > 0;
    return (
        <div className="frameSelect-box">
            {setores.length > 0 && (
                <div className="frameSelect-container" style={{ marginBottom: "10px", width: !both ? "100%" : null }}>
                    <div className="frameSelect-container-header">
                        <h3>Setores</h3> 
                        <BiX className="remover-setores-anexos" onClick={removeAllSetores} size={14} title='Remover todos'/>
                    </div>
                    <div className="frameSelect-container-body">
                        {setores.map((anexo, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <button onClick={() => removeSetor(index)} className="remove-button">x</button>
                            <span style={{ marginRight: '10px', marginLeft: '5px' }}>{anexo}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )} 
            {anexos.length > 0 && (
                <div className="frameSelect-container" style={{ marginBottom: "10px", width: !both ? "100%" : null }}>
                    <div className="frameSelect-container-header">
                        <h3>Anexos</h3>
                        <BiX className="remover-setores-anexos" onClick={removeAllAnexos} size={14} title='Remover todos'/>
                    </div>
                    <div className="frameSelect-container-body">
                        {anexos.map((anexo, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <button onClick={() => removeAnexo(index)} className="remove-button">x</button>
                            <span style={{ marginRight: '10px', marginLeft: '5px' }}>{anexo}</span>
                            </div>
                        ))}
                    </div>
                </div>
            )} 
        </div>
    )
}

export default index


