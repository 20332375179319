import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { AnexosContext } from '../../../contexts/anexosProvider';
import DataTable from '../../Table';
import LoadingSpinner from '../../Loader/load'
import Visualizar from '../Visualizar';
import Linhas from '../Linhas';

const Consulta = () => {
    const { clientes, setAlert } = useContext(ClientContext);
    const { anexosByClient, deleteAnexo } = useContext(AnexosContext);
    const [clients, setClients] = useState(null);
    const [anexos, setAnexos] = useState(null);
    const [anexoSelecionado, setAnexoSelecionado] = useState(null);
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const components = {
        vizualizar: <Visualizar anexo={anexoSelecionado} setAnexo={setAnexoSelecionado} closeModal={closeModal} />,
        linhas: <Linhas anexo={anexoSelecionado} closeModal={closeModal} />
    }

    useEffect(() => {
        let newClients = clientes ? clientes : [];
        setClients(newClients);
    }, [clientes]);

    async function handleSelectClient(e) {
        setLoading(true);
        setAnexos(null)
        const idCliente = e.target.value
        const anexos = await anexosByClient(idCliente)
        setAnexos(anexos)
        setClienteSelecionado(idCliente)
        setLoading(false);
    }

    const headers = [
        { label: "Anexo", key: "nome", sortable: true },
        { label: "Telefone", key: "telefone", sortable: false },
        { label: "Status", key: "status_local", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "vizualizar": vizualizarAnexo,"linhas": vizualizarLinhas , "deletar": deleteAnexoById} },
    ];

    function vizualizarAnexo(id) {
        const newAnexo = anexos.find((anexo) => anexo.id === id);
        setAnexoSelecionado(newAnexo);
        setType('vizualizar');
    }

    function deleteAnexoById(id) {
        if (!id) return console.error('ID não informado');
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar o anexo?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteAnexo(id),
            params: id
        })
        const anexos = anexosByClient(clienteSelecionado)
        if (anexos) {
            setAnexos(anexos)
        }
    }

    function vizualizarLinhas(id) {
        const newAnexo = anexos.find((anexo) => anexo.id === id);
        setAnexoSelecionado(newAnexo);
        setType('linhas');
    }

    function closeModal() {
        setAnexoSelecionado(null);
        setType('');
    }

    return (
        <div className='anexos-consulta-contander'>
            <select className='anexos-consulta-select' onChange={(e) => handleSelectClient(e)}>
                <option value=''>Selecione o cliente</option>
                {clients &&
                    clients
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((client, index) => (
                            <option key={index} value={client.id}>
                                {client.nome}
                            </option>
                        ))}
            </select>
            {anexos && anexos.length > 0 ? <DataTable headers={headers} data={anexos} itemsPerPage={10} /> : null}
            {anexos && anexos.length === 0 && <p style={{marginTop:100}}>Nenhum anexo encontrado</p>}
            {type && components[type]}
            {loading && <LoadingSpinner />}
        </div>
    )
}

export default Consulta
