import FormComp from "../FormsComp";

export const getDados = (servico) => {
    return [
        {
            titulo: 'Nome do Serviço',
            valor: servico?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        }
    ]
};


export const getComponentMap = (handleInputChange) => {
    return {
        Geral: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
    }
}