import { Route, Routes } from "react-router-dom";
import Authenticator from "./Authenticator";
import Login from "../components/Login";
import Redefinir from "../components/Login/Types/redefenirSenha";
import Esqueci from "../components/Login/Types/esqueciSenha";
import Dashboard from "../components/DashBoard";
import Cliente from "../components/Clientes";
import Home from "../components/Home";
import Anexos from "../components/Anexos";
import Linhas from "../components/Linhas";
import Servicos from "../components/Servicos";
import Fornecedores from "../components/Fornecedores";
import Patrimonio from "../components/Patrimonio";
import Relatorios from "../components/Relatorios";
import Entrada from "../components/Entrada";
import VoxSoftwares from "../components/VoxSoftwares";
import Relatorio from "../components/Faturador/Relatorio";
import Patrimonios from "../components/Faturador/Patrimonios";
import Saperx from "../components/Faturador/SAPERX";
import OpenTable from "../components/OpenTable";
import Termos from "../components/Termos";

export default function Routeror(props) {
    return (
        <Routes>
            <Route path="/" element={<Authenticator />} />
            <Route path="/login" element={<Login />} />
            <Route path="/redefinir-senha/:hash" element={<Redefinir />} />
            <Route path="/esqueciSenha" element={<Esqueci />} />
            <Route path="/openTable/:hash" element={<OpenTable />} />
            <Route path="/termos" element={<Termos />} />
            <Route path="/home/*" element={<Authenticator><Home /> </Authenticator>}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="clientes" element={<Cliente />} />
                <Route path="anexos" element={<Anexos />} />
                <Route path="linhas" element={<Linhas />} />
                <Route path="serviços" element={<Servicos />} />
                <Route path="fornecedores" element={<Fornecedores />} />
                <Route path="Patrimônio" element={<Patrimonio />} />
                <Route path="relatórios" element={<Relatorios />} />
                <Route path="Entrada" element={<Entrada />} />
                <Route path="Vox Softwares" element={<VoxSoftwares />} />
                <Route path="faturador/relatorios" element={<Relatorio />} />
                <Route path="faturador/equipamentos" element={<Patrimonios />} />
                <Route path="faturador/saperx" element={<Saperx />} />
            </Route>
        </Routes>
    );
}