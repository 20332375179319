import axios from 'axios';

let url = "https://api-erp.voxcity.com.br";
//let url = 'http://localhost:4000';

const apiUser = axios.create({
    baseURL: url,
});

axios.defaults.withCredentials = true;

export default apiUser;