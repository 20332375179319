import { useContext } from "react";
import { VoxSoftwaresContext } from "../../../../contexts/voxSoftwaresProvider";
import '../styles.css';

const Servicos = ({ newOrg, setNewOrg, setType, previousOrg, isEdit }) => {
  const { services, handleTitles, updateOrg, setAlert} = useContext(VoxSoftwaresContext);

  const handleCheckService = (e) => {
    const { name, checked } = e.target;
    setNewOrg((prevData) => ({
      ...prevData,
      services: checked
        ? { ...prevData.services, [name]: services[name] }
        : Object.fromEntries(Object.entries(prevData.services).filter(([key]) => key !== name))
    }));
  };

  const handleNext = () => {
    if(!Object.keys(newOrg?.services)){
      setNewOrg((prevData) => ({ ...prevData, services: {} }));
    }else{
      if(isEdit){
        if(JSON.stringify(previousOrg) === JSON.stringify(newOrg)){
          setType('Limites/UCMS')
          return
        }else{
          setAlert({
            visible: true,
            title: "Atenção!",
            placeholder: "Deseja salvar as alterações feitas?",
            confirm: true,
            type: "confirm",
            callback: () => updateOrg(newOrg),
            params: newOrg
          });
          setType('Limites/UCMS')
          return
        }
      }
      setType('Limites/UCMS')
    }
  }

  return (
    <>
      <div className="services-container">
        {Object.keys(services).map((service, index) => {
          return (
            <label className="service-card" key={index}>
              <input
                type="checkbox"
                name={service}
                checked={newOrg?.services[service]?.ativo ?? false}
                onChange={handleCheckService}
                className="service-checkbox"
                style={
                  newOrg.services && newOrg.services[service]
                    ? { backgroundColor: "#9ac31c" }
                    : null
                }
              />
              <span className="service-name">{handleTitles(service)}</span>
            </label>
          );
        })}
      </div>
      <button onClick={handleNext} className='next-button' style={{ width: '200px', marginTop:30 }}>{isEdit ? 'Salvar':'Avançar'}</button>
    </>
  );
};

export default Servicos;