import { useState, useContext, useEffect } from 'react'
import { AnexosContext } from '../../../contexts/anexosProvider'
import { editLinhas, editLinhasComponentMap } from '../../General/Format/anexo'
import { BiX } from "react-icons/bi";

const LinhasEdit = ({ closeEditModal, linha }) => {
    const { updateLinhaById } = useContext(AnexosContext)
    const [editData, setEditData] = useState({})
    const [type, setType] = useState('Geral')

    useEffect(() => {
        setEditData(linha)
    }, [])

    const handleInputChange = (e) => {
        setEditData({ ...editData, [e.target.name]: e.target.value })
    }

    const handleSaveClick = () => {
        updateLinhaById(editData)
        closeEditModal()
    }

    const handleCancelClick = () => {
        setType('Geral')
    }
    const handleEditClick = () => {
        setType('Edit')
        setEditData(linha)
    }

    const dados = editLinhas(editData)
    const linhasComponentMap = editLinhasComponentMap(handleInputChange, handleSaveClick, handleCancelClick, handleEditClick, closeEditModal)
    return (
        <div className='modal-edit'>
            <div className='modal-edit-body'>
                <BiX onClick={() => closeEditModal()} className='fechar-top-button' />
                <div className='modal-edit-header'>
                    <h1>Editar Linha</h1>
                </div>
                <div className="edit-modal-content">
                    {dados.map((dado, index) => (
                        <div key={index} className='edit-modal-content-item '>
                            {linhasComponentMap[type](dado)}
                        </div>
                    )
                    )}
                </div>
                <div className='edit-button-box'>
                    {linhasComponentMap[`buttons${type}`]()}
                </div>
            </div>
        </div>
    )
}

export default LinhasEdit
