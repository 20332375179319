import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import "./styles.css";

export default function TransferTable({
  servicos,
  servicosPrestados,
  leftTableData,
  setLeftTableData,
  rightTableData,
  setRightTableData,
}) {
  const [selectedItemsLeft, setSelectedItemsLeft] = useState([]);
  const [selectedItemsRight, setSelectedItemsRight] = useState([]);
  const [selectAllLeft, setSelectAllLeft] = useState(false);
  const [selectAllRight, setSelectAllRight] = useState(false);

  const toggleSelect = (item, table) => {
    const setSelectedItems =
      table === "left" ? setSelectedItemsLeft : setSelectedItemsRight;
    const selectedItems =
      table === "left" ? selectedItemsLeft : selectedItemsRight;

    setSelectedItems((prevSelected) =>
      selectedItems.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  const moveItems = (fromTable, toTable, selectedItems) => {
    const removeFromTable = fromTable.filter(
      (item) => !selectedItems.includes(item)
    );
    const addToTable = toTable.concat(
      selectedItems.filter((item) => !toTable.includes(item))
    );

    fromTable === leftTableData
      ? setLeftTableData(removeFromTable)
      : setRightTableData(removeFromTable);
    toTable === leftTableData
      ? setLeftTableData(addToTable)
      : setRightTableData(addToTable);
  };

  const moveRight = () => {
    moveItems(leftTableData, rightTableData, selectedItemsLeft);
    setSelectedItemsLeft([]);
    setSelectAllLeft(false);
  };

  const moveLeft = () => {
    moveItems(rightTableData, leftTableData, selectedItemsRight);
    setSelectedItemsRight([]);
    setSelectAllRight(false);
  };

  const handleCheckboxChange = (table) => {
    const setSelectAll =
      table === "left" ? setSelectAllLeft : setSelectAllRight;
    const setSelectedItems =
      table === "left" ? setSelectedItemsLeft : setSelectedItemsRight;
    const tableData = table === "left" ? leftTableData : rightTableData;
    const selectAll = table === "left" ? selectAllLeft : selectAllRight;

    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : tableData || []);
  };

  useEffect(() => {
    if (servicos && servicosPrestados) {
      const servicosRelacionados = servicos.filter((servico) =>
        servicosPrestados.some(
          (prestado) => prestado.nome_servico === servico.nome
        )
      );
      const servicosNaoRelacionados = servicos.filter(
        (servico) =>
          !servicosPrestados.some(
            (prestado) => prestado.nome_servico === servico.nome
          )
      );

      setLeftTableData(servicosNaoRelacionados);
      setRightTableData(servicosRelacionados);
    }
  }, [servicos, servicosPrestados]);

  const renderTable = (
    tableData,
    selectedItems,
    handleCheckboxChange,
    selectAll
  ) => (
    <table className="transfer-table">
      <thead>
        <tr>
          <th className="th-transfer-checkbox">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={() =>
                handleCheckboxChange(
                  tableData === leftTableData ? "left" : "right"
                )
              }
            />
          </th>
          <th className="th-title">
            {tableData === leftTableData
              ? "Selecione o serviço"
              : "Serviço prestado"}
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.length === 0 ? (
          <tr>
            <td className="td-red" colSpan="2">
              Nenhuma informação disponível!
            </td>
          </tr>
        ) : (
          tableData?.map((servico) => (
            <tr key={servico.id} className="tr-td">
              <td className="tr-td">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(servico)}
                  onChange={() =>
                    toggleSelect(
                      servico,
                      tableData === leftTableData ? "left" : "right"
                    )
                  }
                />
              </td>
              <td className="title-table-transfer">{servico.nome}</td>
            </tr>
          ))
        )}
      </tbody>
    </table>
  );

  return (
    <div className="container-table-transfer">
      <div className="container-table-transfer-row">
        {renderTable(
          leftTableData,
          selectedItemsLeft,
          handleCheckboxChange,
          selectAllLeft
        )}

        <div className="container-button-actions">
          <button className="button-transfer" onClick={moveRight}>
            <MdOutlineArrowForwardIos size={15} />
          </button>
          <button className="button-transfer" onClick={moveLeft}>
            <MdOutlineArrowBackIos size={15} />
          </button>
        </div>

        {renderTable(
          rightTableData,
          selectedItemsRight,
          handleCheckboxChange,
          selectAllRight
        )}
      </div>
    </div>
  );
}
