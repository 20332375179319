import { createContext, useState, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const EquipamentosContext = createContext();

export const EquipamentosProvider = ({ children }) => {
  const { setAlert } = useContext(UserContext);
  const [equipamentos, setEquipamentos] = useState(null);
  const [loading, setLoading] = useState(false);

  const getEquipamentosByClient = async (id) => {
    if (!id) setAlert({ visible: true, title: "Atenção!", placeholder: 'Não foi localizado ID do cliente, entre em contato com suporte!', confirm: false, type: "warning" });
        try{
            setLoading(true);
            const response = await apiUser.post('/equipamentos/equipamentosByClient', { id });
            const { success, message, data } = response.data;
            if (success) {
                setEquipamentos(data);
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        }catch(error){
            console.error('Error getting equipamentos:', error);
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Erro ao buscar equipamentos, entre em contato com suporte!', confirm: false, type: "warning" });
        }finally{
            setLoading(false);
        }
    };

    const updateEquipamento = async (id, quantidade, clientId) => {
        if (!id) return setAlert({ visible: true, title: "Atenção!", placeholder: 'ID não informado', confirm: false, type: "warning" });
        if (!quantidade) return setAlert({ visible: true, title: "Atenção!", placeholder: 'Quantidade não informada', confirm: false, type: "warning" });
        try {
            setLoading(true);
            const response = await apiUser.post('/equipamentos/updateEquipamento', { id, quantidade });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                getEquipamentosByClient(clientId);
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.error('Error updating equipamento:', error);
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Erro ao atualizar equipamento, entre em contato com suporte!', confirm: false, type: "warning" });
        } finally {
            setLoading(false);
        }
    };

    const gerarRelatorio = async (id) => {
        if (!id) return setAlert({ visible: true, title: "Atenção!", placeholder: 'ID não informado', confirm: false, type: "warning" });
        try {
            setLoading(true);
            const response = await apiUser.post('/equipamentos/gerarRelatorio', { id });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                getEquipamentosByClient(id);
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.error('Error generating report:', error);
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Erro ao gerar relatório, entre em contato com suporte!', confirm: false, type: "warning" });
        } finally {
            setLoading(false);
        }
    }


  return (
    <EquipamentosContext.Provider
      value={{
        equipamentos,
        setEquipamentos,
        loading,
        setLoading,
        getEquipamentosByClient,
        updateEquipamento,
        gerarRelatorio
      }}
    >
      {children}
    </EquipamentosContext.Provider>
  );
};
