import LabelInput from './Input/labelInput'
import SelectLabel from './Select/selectLabel'
import Calendar from './Calendar/calendar'
import Time from './Time/time'
import Checkbox from './CheckBox/checkbox'
import NumberInput from './Input/numberInput'
import Dashcard from '../DashCard'

const FormComp = ({ dados, handleInputChange }) => {
    const components = {
        Dashcard: <Dashcard titulo={dados.titulo} valor={dados.valor} />,
        NumberInput: <NumberInput dados={dados} handleInputChange={handleInputChange} />,
        Input: <LabelInput dados={dados} handleInputChange={handleInputChange} />,
        Select: <SelectLabel dados={dados} handleInputChange={handleInputChange} />,
        Calendar: <Calendar dados={dados} handleInputChange={handleInputChange} />,
        Time: <Time dados={dados} handleInputChange={handleInputChange} />,
        Checkbox: <Checkbox dados={dados} handleInputChange={handleInputChange} />,
    };

    return (
        <>
            {components[dados.tipo]}
        </>
    )
}

export default FormComp
