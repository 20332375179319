import Dashcard from "../DashCard";
import FormComp from "../FormsComp";

export const getDadosBasico = (organizacao, estados, cidades) => {
    return [
        {
            titulo: 'Nome',
            valor: organizacao?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail',
            valor: organizacao?.email || 'Nenhum dado cadastrado.',
            name: 'email',
            tipo: 'Input'
        },
        {
            titulo : 'Conta',
            valor: organizacao?.conta || 'Nenhum dado cadastrado.',
            name: 'conta',
            tipo: 'Input'
        },
        {   
            titulo: 'Imagem(URL)',
            valor: organizacao?.foto || 'Nenhum dado cadastrado.',
            name: 'foto',
            tipo: 'Input'
        },
        {
            titulo: 'CNPJ',
            valor: organizacao?.cnpj || 'Nenhum dado cadastrado.',
            name: 'cnpj',
            tipo: 'Input'
        },
        {
            titulo: 'Endereço',
            valor: organizacao?.endereco || 'Nenhum dado cadastrado.',
            name: 'endereco',
            tipo: 'Input'
        },
        {
            titulo: 'Estado',
            valor: organizacao?.estado || 'Nenhum dado cadastrado.',
            name: 'estado',
            tipo: 'Select',
            options: estados,
            value:'nome',
        },
        {
            titulo: 'Cidade',
            valor: organizacao?.cidade || 'Nenhum dado cadastrado.',
            name: 'cidade',
            tipo: 'Select',
            options: cidades,
            value:'nome'
        },
        {
            titulo: 'CEP',
            valor: organizacao?.cep || 'Nenhum dado cadastrado.',
            name: 'cep',
            tipo: 'Input'        
        },
        {
            titulo: 'Telefone',
            valor: organizacao?.telefone || 'Nenhum dado cadastrado.',
            name: 'telefone',
            tipo: 'Input'
        },
    ]
}

export const getDadosLimites = (limite) => {
    return[
        {
            titulo: 'Usuários',
            valor: limite?.usuarios || 'Nenhum dado cadastrado.',
            name: 'usuarios',
            tipo: 'Input',
        },
        {
            titulo: 'Atendentes',
            valor: limite?.atendentes || 'Nenhum dado cadastrado.',
            name: 'atendentes',
            tipo: 'Input',
        },
        {
            titulo: 'Administradores',
            valor: limite?.administradores || 'Nenhum dado cadastrado.',
            name: 'administradores',
            tipo: 'Input',
        },
        {
            titulo: 'Agendas',
            valor: limite?.agendas || 'Nenhum dado cadastrado.',
            name: 'agendas',
            tipo: 'Input',
        },
        {
            titulo: 'Serviços',
            valor: limite?.servicos || 'Nenhum dado cadastrado.',
            name: 'servicos',
            tipo: 'Input',
        },
        {
            titulo: 'locais',
            valor: limite?.locais || 'Nenhum dado cadastrado.',
            name: 'locais',
            tipo: 'Input',
        }
    ]
}

export const getDadosUsuarios = (usuario, departamentos, setores, isEdit) => {
    return [
        {
            titulo: 'Status',
            valor: usuario?.ativo === true ? true : false,
            name: 'ativo',
            tipo: 'Select',
            options: [ { id: true, nome: 'Ativo' }, { id: false, nome: 'Inativo' }]
        },
        {
            titulo: 'Nome',
            valor: usuario?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input',
        },
        {
            titulo: 'E-mail',
            valor: usuario?.email || 'Nenhum dado cadastrado.',
            name: 'email',
            tipo: 'Input',
        },
        {
            titulo: 'Senha',
            valor: usuario?.senha || 'Nenhum dado cadastrado.',
            name: 'senha',
            tipo: 'Input',
            visible: isEdit,
        },
        {
            titulo: 'Departamento',
            valor: usuario?.departamento || 'Nenhum dado cadastrado.',
            name: 'departamento',
            tipo: 'Select',
            options: departamentos,
        },
        {
            titulo: 'Setor',
            valor: usuario?.setor || 'Nenhum dado cadastrado.',
            name: 'setor',
            tipo: 'Select',
            options: setores,
        },
        {
            titulo: 'Atendente',
            valor: usuario?.atendente,
            name: 'atendente',
            tipo: 'Checkbox',
        },
        {
            titulo: 'Atendente de Grupo',
            valor: usuario?.atendenteGrupo,
            name: 'atendenteGrupo',
            tipo: 'Checkbox',
        },
        {
            titulo: 'Administrador',
            valor: usuario?.admin,
            name: 'admin',
            tipo: 'Checkbox',
        },
    ]
}

export const getComponentMap = (handleInputChange) => {
    return {
        Cadastro: (dado) => (
            <FormComp dados={dado} handleInputChange={handleInputChange} />
        ),
    };
}


