import { useState, useEffect, useContext } from 'react'
import { editFaturador, editFaturadorMap } from '../../../General/Format/faturador'
import { BiX } from "react-icons/bi";
import LoadingSpinner from '../../../Loader/load';
import { FaturadorContext } from '../../../../contexts/faturadorProvider';
import { UserContext } from '../../../../contexts/userProvider';

const PatrimonioEdit = (props) => {
    const { loading, patrimonios, updatePat, saveValues, toBrl } = useContext(FaturadorContext);
    const { setAlert } = useContext(UserContext);
    const [editData, setEditData] = useState({});
    const [type, setType] = useState('Edit');
    const [oldValue, setOldValue] = useState(null);

    const handleInputChange = (e) => {
        setEditData({ ...editData,  valor_cobrado: e.target.value })
    }

    useEffect(() => {
        let equipamento = patrimonios.find(p => p.id === props.patSel);
        setEditData(equipamento);
        setOldValue(equipamento.valor_cobrado);
    }, [])

    const handleSaveClick = async () => {
        let { modelo_id, marca_id, valor_cobrado } = editData;
        if (valor_cobrado === oldValue) {
            return setAlert({ visible: true, title: "Atenção!", placeholder: 'Não é possível atualizar o mesmo valor.', confirm: false, type: "warning" });
        }
        valor_cobrado = parseFloat(valor_cobrado.replace(/\./g, '').replace(',', '.').trim());
        await saveValues(modelo_id, marca_id, valor_cobrado);
        updatePat({ ...editData, valor_cobrado: toBrl(valor_cobrado) });
        props.closeEditModal();
    }

    const dados = editFaturador(editData)
    const patrimonioComponentMap = editFaturadorMap(handleInputChange, handleSaveClick, props.closeEditModal)
    return (
        <div className='modal-edit'>
            <div className='modal-edit-body'>
                <BiX onClick={() => props.closeEditModal()} className='fechar-top-button' />
                <div className='modal-edit-header'>
                    <h1>Editar patrimônio</h1>
                </div>
                {loading && <LoadingSpinner />}
                <div className="edit-modal-content">
                    {dados.map((dado, index) => (
                            <div key={index} className='edit-modal-content-item '>
                                {patrimonioComponentMap[type](dado)}
                            </div>
                        )
                    )}
                </div>
                <div className='edit-button-box'>
                    {patrimonioComponentMap[`buttons${type}`]()}
                </div>
            </div>
        </div>
    )
}

export default PatrimonioEdit;
