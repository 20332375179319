import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { UserContext } from '../../../contexts/userProvider'
import Editar from '../editServicos'
import LoadingSpinner from '../../Loader/load'
import DataTable from '../../Table'

const Consulta = () => {
    const { getAllServices, deleteServico, updateServico } = useContext(ClientContext);
    const { user, setAlert } = useContext(UserContext);
    const [servicos, setServicos] = useState(null);
    const [servicoSelecionado, setServicoSelecionado] = useState(null);

    useEffect(() => {
        if (!servicos) buscarServicos();
    }, []);

    const buscarServicos = async () => {
        try {
            const servicos = await getAllServices();
            setServicos(servicos);
        } catch (err) {
            console.log(err)
        }
    };

    const editar = (linha) => {
        const service = servicos.find(l => l.id === linha)
        setServicoSelecionado(service)
    }

    const deletar = (linha) => {
        setAlert({
            visible: true,
            title: "Atenção!",
            placeholder: "Deseja realmente deletar este serviço?",
            confirm: true,
            type: "confirm",
            callback: () => deleteServico(linha),
            params: linha
        });
        setServicoSelecionado(null)
        buscarServicos()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let newService = { ...servicoSelecionado }
        newService.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
        newService.id_usuario = user.id;
        newService.usuario = user.nome;
        if (!newService.nome) {
            setAlert({
                type: 'warning',
                placeholder: 'Nome do serviço é obrigatório',
                visible: true,
                confirm: false,
                title: 'Atenção!'
            })
            return;
        }
        try {
            await updateServico(newService.id, newService)
            buscarServicos()
            setServicoSelecionado(null)
        } catch (err) {
            console.log(err)
        }
    }
    const headers = [
        { label: "Serviço", key: "nome", sortable: true },
        { label: "Ações", key: "actions", sortable: false, actions: { "editar": editar, "deletar": deletar } },
    ];

    return (
        <div className='linhas-contander'>
            {!servicos ? <LoadingSpinner /> : <DataTable headers={headers} data={servicos} itemsPerPage={5} />}
            {servicoSelecionado && <Editar servico={servicoSelecionado} setServico={setServicoSelecionado} handleSubmit={handleSubmit} closeModal={() => setServicoSelecionado(null)} />}
        </div>
    )
}

export default Consulta
