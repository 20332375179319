import { useState } from 'react'
import { BiTrash, BiEdit, BiCheck, BiSolidBriefcase, BiPhone, BiPlus   } from 'react-icons/bi';
import { GrDocumentPdf } from "react-icons/gr";
import { RiFileExcel2Line } from "react-icons/ri";
import { FaChartPie } from "react-icons/fa";

const Action = ({ type, action}) => {
    const [actions, setActions] = useState({
        pdfExcel: <GrDocumentPdf onClick={action} className='actions-buttons' title={'Clique para detalhes'} style={{ fontSize: '17px', color: 'green'}}/>,
        excel: <RiFileExcel2Line onClick={action} className='actions-buttons' title={`Clique para ${type}`} />,
        pdf: <GrDocumentPdf onClick={action} className='actions-buttons' title={'Clique para resumo'} style={{ fontSize: '17px' }}/>,
        vizualizar: <BiCheck onClick={action} className='actions-buttons' title={`Clique para ${type}`} />,
        editar: <BiEdit className='actions-buttons' onClick={action} title={`Clique para ${type}`} />,
        deletar: <BiTrash onClick={action} className='actions-buttons' color='red' title={`Clique para ${type}`} />,
        vincular: <BiSolidBriefcase onClick={action} className='actions-buttons' title={`Clique para ${type}`} />,
        linhas: <BiPhone onClick={action} className='actions-buttons' title={`Clique para ${type}`} />,
        adicionar: <BiPlus onClick={action} className='actions-buttons' title={`Clique para ${type}`} />,
        equipamentos: <FaChartPie onClick={action} className='actions-buttons' title={`Clique para ${type}`} />
    })

    return (
        <>
            {actions[type]}
        </>
    )
}

export default Action
