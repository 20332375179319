import { useContext, useState, useEffect } from 'react';
import { FaturadorContext } from '../../../../contexts/faturadorProvider';
import { ClientContext } from '../../../../contexts/clientProvider';
import LoadingSpinner from '../../../Loader/load';
import DataTable from '../../../Table';
import { handleImport, calculateResults, generatePDF, downloadNewSecAndData, generatePDFExcel} from '../Utils/mixerUtil';
import { UserContext } from '../../../../contexts/userProvider';
import NumberInput from '../../../General/FormsComp/Input/numberInput';


const Secretarias = () => {
    const { clientes, setIdCliente } = useContext(ClientContext);
    const { loading, clienteSelecionado, setClienteSelecionado, currSec, setCurrSec } = useContext(FaturadorContext);
    const { setAlert } = useContext(UserContext);
    const [titulo, setTitulo] = useState(null);
    const [imported, setImported] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [dataTable, setDataTable] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    
    const [vc1Value, setVc1Value] = useState(0.0929);
    const [vc2Value, setVc2Value] = useState(0.0928);
    const [vc3Value, setVc3Value] = useState(0.1032);
    const [lcValue, setLcValue] = useState(0.031);
    const [ldnValue, setLdnValue] = useState(0.0619);

    const gerenatePdf = async (id) => {
        let secretaria = dataTable.find((sec) => sec.id === id);
        let checkbox = document.getElementById('myCheckbox');
        let value = checkbox.checked;
        secretaria = { ...secretaria, remove: isChecked };
        await generatePDF(secretaria, titulo, value);
    }

    const generateExcel = (id) =>  {
        const secretaria = dataTable.find((sec) => sec.id === id);
        const secName = secretaria.secretaria ?? '';
        downloadNewSecAndData(secretaria.Detalhes[0].data, secName);
    }

    const generatePDFDetails = (id) => {
        const secretaria = dataTable.find((sec) => sec.id === id);
        let checkbox = document.getElementById('myCheckbox');
        let value = checkbox.checked;
        generatePDFExcel(secretaria, titulo, value);

    }

    const downloadAllPdfs = async () => {
        let checkbox = document.getElementById('myCheckbox');
        let value = checkbox.checked;
        for (const sec of dataTable) {
            await generatePDF(sec, titulo, value);
        }
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    const headers = dataTable 
    ? [
        { label: "Secretaria", key: "secretaria", sortable: true },
        { label: "Linhas", key: "linhas", sortable: false },
        { label: "Tempo VC1", key: "Tempo VC1", sortable: false },
        { label: "Valor VC1", key: "Valor VC1", sortable: false },
        { label: "Tempo VC2", key: "Tempo VC2", sortable: false },
        { label: "Valor VC2", key: "Valor VC2", sortable: false },
        { label: "Tempo VC3", key: "Tempo VC3", sortable: false },
        { label: "Valor VC3", key: "Valor VC3", sortable: false },
        { label: "Tempo LC", key: "Tempo LC", sortable: false },
        { label: "Valor LC", key: "Valor LC", sortable: false },
        { label: "Tempo LDN", key: "Tempo LDN", sortable: false },
        { label: "Valor LDN", key: "Valor LDN", sortable: false },
        { label: "Fatura", key: "Fatura", sortable: false },
        { label: "SaperVal", key: "SaperVal", sortable: false },
        // 'excel': generateExcel 
        { label: "Detalhes", key: "actions", sortable: false, actions: { "pdf": gerenatePdf, "pdfExcel": generatePDFDetails, "excel": generateExcel } },
    ]
    : [
        { label: "secretaria", key: "secretaria", sortable: true },
        { label: "Local", key: "local", sortable: false },
        { label: "Endereço", key: "endereço", sortable: false },
        { label: "Bairro", key: "bairro", sortable: false },
        { label: "Linhas", key: "linhas", sortable: false },
    ];

    useEffect (() => {
        if (clienteSelecionado) {
            const sel = clienteSelecionado;
            setTitulo(sel);
        }
    }, []);

    const handleSelectClient = (e) => {
        const id = e.target.value;
        const selectedClient = clientes?.find((client) => client.id === parseInt(id));
        
        if (selectedClient) {
            setDataTable(null);
            setCurrSec([]);
            setTitulo(selectedClient.nome);
            setIdCliente(id);
            setClienteSelecionado(selectedClient.nome);
        }
    };

    const handleFileChange = (e) => {
        const file = e?.target?.files[0];
        if (file) {
            const fileType = file.name.split('.').pop().toLowerCase();
            if (fileType === 'xlsx' || fileType === 'xls' || fileType === 'csv') {
                setFileName(file.name);
                handleImport(file, (importedData) => {
                    if (currSec.length === 0) return setImported(importedData);
                    setImported(importedData);
                    const newData = calculateResults(importedData, currSec, vc1Value, vc2Value, vc3Value, lcValue, ldnValue);
                    setDataTable(newData); 
                });
            } else {
                return setAlert({ visible: true, title: "Atenção!", 
                    placeholder: 'É aceito arquivos no formato xlsx, xls, csv.', 
                        confirm: false, type: "warning" });
            }
        }
    };

    const calculateAndSetResults = () => {
        if (currSec.length === 0|| !imported) 
            return setAlert({ visible: true, title: "Atenção!", 
                placeholder: 'É necessário ter um cliente que possua secretaria e um arquivo selecionado.', 
                    confirm: false, type: "warning" });
        const newData = calculateResults(imported, currSec, vc1Value, vc2Value, vc3Value, lcValue, ldnValue);
        setDataTable(newData);
    };

    const handleInputChange = (e) => {
        const rawValue = e.target.value;
        const value = rawValue.replace(/\./g, '').replace(',', '.');
        switch (e.target.name) {
            case "vc1Value":
                setVc1Value(value);
                break;
            case "vc2Value":
                setVc2Value(value);
                break;
            case "vc3Value":
                setVc3Value(value);
                break;
            case "lcValue":
                setLcValue(value);
                break;
            case "ldnValue":
                setLdnValue(value);
                break;
            default:
                break;
        }
    };

    return (
        <>
        <div className='saperx-container' >
            <select className="anexos-consulta-select" onChange={handleSelectClient}>
                <option value="">{clienteSelecionado ?? "Selecione o cliente"}</option>
                {clientes &&
                    clientes
                        .slice()
                        .sort((a, b) => a?.nome?.localeCompare(b?.nome))
                        .map((client, index) => (
                            <option key={index} value={client.id}>
                                {client.nome}
                            </option>
                        ))}
            </select>
          
            <div className="info">
                <NumberInput 
                    handleInputChange={handleInputChange} 
                    dados={{ valor: 0.0929, titulo: "VC1", name: "vc1Value", visible: false, placeholder: "R$" }} 
                />
                <NumberInput
                    handleInputChange={handleInputChange}
                    dados={{ valor: 0.0928, titulo: "VC2", name: "vc2Value", visible: false, placeholder: "R$" }}
                />
                <NumberInput
                    handleInputChange={handleInputChange}
                    dados={{ valor: 0.1032, titulo: "VC3", name: "vc3Value", visible: false, placeholder: "R$" }}
                />
                <NumberInput
                    handleInputChange={handleInputChange}
                    dados={{ valor: 0.031, titulo: "LC", name: "lcValue", visible: false, placeholder: "R$" }}
                />
                <NumberInput
                    handleInputChange={handleInputChange}
                    dados={{ valor: 0.0619, titulo: "LDN", name: "ldnValue", visible: false, placeholder: "R$" }}
                />
                <div style={{width: '282px',height: "37px", display: "flex", justifyContent: "center"}}>
                    <button className="faturador-cadastro-button" onClick={calculateAndSetResults}>
                            Calcular 
                    </button>
                </div>
            </div>
            <div className="but-container">
                <div className="file-input-wrapper">
                    <label htmlFor="file-input" className="file-input-label">
                        Escolher Arquivo
                    </label>
                    <input 
                        id="file-input"
                        type="file" 
                        accept=".xlsx, .xls, .csv" 
                        onChange={handleFileChange}
                        className="file-input" 
                    />
                    {fileName && <span className="file-name">{fileName}</span>}
                </div>
                <div style={{width: '282px',height: "37px", display: "flex", justifyContent: "center"}}>
                    {dataTable && dataTable.length > 0 && (
                        
                        <button className="faturador-cadastro-button" onClick={downloadAllPdfs}>
                            Baixar PDFs 
                        </button>
                    )}
                </div>
            </div>
            <div className='myCheckContainer'>
                <input
                    className='myCheck'
                    type="checkbox"
                    name="myCheckbox"
                    id="myCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <label className='checkLabel' htmlFor="myCheckbox">Remover coluna valor </label>
            </div>
           
            {loading && <LoadingSpinner />}
            {currSec && <DataTable headers={headers} data={dataTable ?? currSec} itemsPerPage={5} exportButton={true} docTitle={`Dados gerais do cliente ${titulo}`} />}
            </div>
        </>
    );
};

export default Secretarias;
