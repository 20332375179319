import React,{ useState, useContext } from 'react'
import { UserContext } from '../../contexts/userProvider'
import logo from '../../assets/images/logoRedimensionado.png'
import { BiUser, BiLock } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import './styles.css'


const Login = () => {
    const { signIn, setAlert } = useContext(UserContext);
    const [login, setLogin] = useState({email: '', senha: ''})


    function handleEmailChange(event) {
        setLogin({...login, email: event.target.value})
    }
      
    function handlePasswordChange(event) {
        setLogin({...login, senha: event.target.value})
    }

    function logIn() {
        if(login.email === '' || login.senha === '') {
            setAlert({visible: true, title: 'Atenção!', placeholder: 'Pare realizar o login, preencha todos os campos!', confirm: false, type: 'warning'})
        } else {
            signIn(login.email, login.senha)
        }
    }


  return (
    <div className='login-wrapper'>
        <div className='login-container'>
            <div className='login-header'>
                <img className='logo' src={logo} alt='logomarca voxcity tecnologia' />
            </div>
            <div className='login-form'>
                <div className="login-input-content">
                    <input className='login-input' type='text' placeholder='Usuário' onChange={handleEmailChange} />
                    <BiUser className='login-icon' />
                </div>

                <div className="login-input-content">
                    <input className='login-input' type='password' placeholder='Senha' onChange={handlePasswordChange} />
                    <BiLock className='login-icon' />
                </div>
              
                <button className='login-button' onClick={logIn}>Login</button>

                <div className='login-links'>
                    <Link to="/esqueciSenha" className='login-esqueci'>Esqueci a senha</Link>
                </div>

            </div>
        </div>
      
    </div>
  )
}

export default Login
