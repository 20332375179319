import { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../contexts/userProvider';
import { useNavigate, Outlet } from 'react-router-dom';
import SideBar from './SideBar'
import Header from './Header'
import './styles.css'

const Home = () => {
  const { user } = useContext(UserContext);
  const [sideBar, setSideBar] = useState(true)
  const [selectedItem, setSelectedItem] = useState('Dashboard');
  const navigate = useNavigate();
  // const components = ['Dashboard', 'Clientes', 'Anexos', 'Fornecedores', 'Linhas','Entrada','Patrimônio', 'Serviços', 'Relatórios'];
  const [components, setComponents] = useState([])

  useEffect(() => {
    navigate(`/home/${selectedItem.toLowerCase()}`);
  }, [])

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      if (user?.nivel === '1' || user?.nivel === '2' || !user?.nivel) {
        setComponents([
          { name: 'Dashboard' }, 
          { name: 'Clientes' }, 
          { name: 'Anexos' }, 
          { name: 'Linhas' }, 
          { name: 'Patrimônio' }, 
          { name: 'Relatórios' }
        ]);
      } else if (user?.nivel === '3') {
        setComponents([
          { name: 'Dashboard' }, 
          { name: 'Clientes' }, 
          { name: 'Anexos' }, 
          { name: 'Fornecedores' }, 
          { name: 'Linhas' }, 
          { name: 'Entrada' }, 
          { name: 'Patrimônio' }, 
          { name: 'Serviços' }, 
          { name: 'Relatórios' },
          {
            name: 'Faturador',
            isGroup: true,
            links: [
              { name: 'Relatórios', link: 'faturador/relatorios' },
              { name: 'Faturar Equipamentos', link: 'faturador/equipamentos' },
              { name: 'SaperX', link: 'faturador/saperx' }
            ]
          },
          { name: 'Vox Softwares' }
        ]);
      }
    }
  }, [user]);
  

  const handleSideBar = () => {
    setSideBar(!sideBar)
  }

  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate(`/home/${item.toLowerCase()}`);
  };

  return (
    <div className='home-container'>
      <SideBar handleSideBar={handleSideBar} sideBar={sideBar} components={components} setSelectedItem={setSelectedItem} selectedItem={selectedItem} handleItemClick={handleItemClick} />
      <div className="home-content">
        <Header />
        <Outlet />
      </div>
    </div>
  )
}

export default Home
