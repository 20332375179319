import React from 'react'
import './styles.css'

const Header = () => {
  return (
    <div className='header-container'>
      <h2 className='header-title'>Bem vindo ao Vox ERP</h2>
    </div>
  )
}

export default Header
