import React, { useEffect, useRef } from 'react'
import ApexCharts from 'apexcharts'

function formatKey(key) {
    let words = key.split('total');
    let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    let formattedKey = formattedWords.join(' ');
    return formattedKey;
}

const Charts = ({ data, title, id, total }) => {
    const { totalClientes, totalAnexos, totalFornecedores, ...chartData } = data;
    const seriesData = Object.keys(chartData).map(key => {
        return {
            name: formatKey(key),
            data: [chartData[key]]
        };
    });


    const options = {
        series: seriesData.map(item => item.data[0]), 
        labels: seriesData.map(item => item.name), 
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex];
            },
            style: {
                fontSize: "12px",
                fontWeight: "bold",
            }
        },
        chart: {
            type: 'pie',
            height: 200,
            width: 250,
            fontSize: "12px",
            toolbar: {
                show: false, 
            },
        },
        title: {
            text: title,
            align: 'left',
            style: {
                fontSize: "12px",
                fontWeight: "bold",
                color: "#142a4c"
            }
        },
        colors: ['#142a4c', '#9ac31c'],
        legend: {
            position: 'bottom',
            fontSize: "14px",
        },
    };

    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }
        chartRef.current = new ApexCharts(document.querySelector(`#${id}`), options);
        chartRef.current.render();
    }, [data]);

    return (
        <div id={id}></div>
    )
}

export default Charts
