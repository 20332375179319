import { UserProvider } from './contexts/userProvider';
import { BrowserRouter } from 'react-router-dom';
import Alert from './components/Alert';
import Routeror from './routes/Routeror';
import './App.css';

function App() {
  
  return (
    <div className="App">
      <BrowserRouter>
        <UserProvider>
          <Alert />
          <Routeror />
        </UserProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
