import React from 'react'

const RenderPageNumbers = ({totalPages , handlePageChange , currentPage}) => {
    const pageNumbers = [];
    const ellipsis = <span key={`ellipsis-${Math.random()}`}>...</span>;

    if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageChange(i)}
                    className={i === currentPage ? 'active' : ''}
                >
                    {i}
                </button>
            );
        }
    } else {
        if (currentPage <= 4) {
            for (let i = 1; i <= 5; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={i === currentPage ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
            pageNumbers.push(ellipsis);
            pageNumbers.push(
                <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </button>
            );
        } else if (currentPage >= totalPages - 3) {
            pageNumbers.push(
                <button key={1} onClick={() => handlePageChange(1)}>
                    1
                </button>
            );
            pageNumbers.push(ellipsis);
            for (let i = totalPages - 4; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={i === currentPage ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button key={1} onClick={() => handlePageChange(1)}>
                    1
                </button>
            );
            for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={i === currentPage ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
            pageNumbers.push(ellipsis);
            pageNumbers.push(
                <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                </button>
            );
        }
    }

    return pageNumbers;
};

export default RenderPageNumbers
