import React from 'react'

const Checkbox = ({ handleInputChange, dados }) => {
  return (
    <>
        <label>{dados.titulo}</label>
        <input
            type="checkbox"
            name={dados.name}
            checked={dados.valor}
            onChange={(e) => handleInputChange(e, dados.index)}
            style={{width: '20px', height: '20px'}}
        />
    </>
  )
}

export default Checkbox