import { useState, useEffect, useContext } from "react";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";
import { UserContext } from "../../../contexts/userProvider";
import Cadastro from "./Types/cadastro";
import { BiTrash, BiPlus } from 'react-icons/bi';

const Modelo = (props) => {
    const { modelosByMarca, deleteModelo } = useContext(PatrimonioContext);
    const { setAlert } = useContext(UserContext);
    const [newModelos, setNewModelos] = useState([]);
    const [cadastroModal, setCadastroModal] = useState(false);
    const [modeloSelecionado, setModeloSelecionado] = useState(null);

    useEffect(() => {
        receberModelos();
    }, [props.marca]);

    const receberModelos = async () => {
        const dados = await modelosByMarca(props.marca.id);
        setNewModelos(dados);
    };

    const handleSelectModelo = (e) => {
        const { value } = e.target;
        const newModelo = newModelos.find((marca) => marca.id === parseInt(value));
        setModeloSelecionado(newModelo);
        props.setModelo(newModelo);
    };

    const handleAddModelo = () => {
        setCadastroModal(true);
    };

    const handleDeleteModelo = () => {
        if (!modeloSelecionado) return setAlert({ type: 'warning', message: 'Selecione uma marca para deletar!' })
        setAlert({
            type: 'confirm',
            placeholder: `Realmente deseja deletar a marca ${modeloSelecionado.nome}?`,
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteModelo(modeloSelecionado.id),
            params: modeloSelecionado.id
        })
    }

    return (
        <div className="marcas-container">
            <div className="patrimonio-consulta-select-container">
                <label>Modelo:</label>
                <select
                    className="patrimonio-consulta-select"
                    onChange={handleSelectModelo}
                >
                    <option value="">Selecione o modelo</option>
                    {newModelos &&
                        newModelos
                            .slice()
                            .sort((a, b) => a.nome.localeCompare(b.nome))
                            .map((marca, index) => (
                                <option key={index} value={marca.id}>
                                    {marca.nome}
                                </option>
                            ))}
                </select>
            </div>
            <BiPlus size={24} onClick={handleAddModelo} title="Adicionar Modelo" className="patrimonios-icons" />
            {/* Verificar a a ultilidade e a exclusão de marcas e modelos */}
            {/* <BiTrash size={24} onClick={handleDeleteModelo} title="Apagar Modelo" className="patrimonios-icons" /> */}

            {cadastroModal && (
                <Cadastro
                    fornecedor={props.fornecedor}
                    receberModelos={receberModelos}
                    setCadastroModal={setCadastroModal}
                    marca={props.marca}
                />
            )}
        </div>
    );
};

export default Modelo;
