import React from 'react'

const Calendar = ({ dados, handleInputChange }) => {
    return (
        <>
            <label>{dados.titulo}</label>
            <input
                type="date"
                name={dados.name}
                value={dados.valor? dados.valor : ''}
                onChange={handleInputChange}
                style={{marginLeft: '10px'}}
            />
        </>
    )
}

export default Calendar
