import React,{ useState, useContext, useEffect } from 'react'
import { UserContext } from '../../../contexts/userProvider'
import { BiUser, BiLock } from 'react-icons/bi'
import '../styles.css'


const Redefinir = () => {
    const {  alterarSenha ,setAlert } = useContext(UserContext);
    const [login, setLogin] = useState({email: '', senha: '', senha2: ''})

    useEffect(() => {
        const hash = window.location.pathname.split('/')[2]
        setLogin({...login, hash: hash})
    },[]);

    function handleEmailChange(event) {
        setLogin({...login, email: event.target.value})

    }
      
    function handlePasswordChange(event) {
        setLogin({...login, [event.target.name]: event.target.value
        })
    }

    function logIn() {
        if(login.email === '' || login.senha === '' || login.senha2 === '') {
            setAlert({visible: true, title: 'Atenção!', placeholder: 'Pare realizar o login, preencha todos os campos!', confirm: false, type: 'warning'})
        } else if(login.senha !== login.senha2) {
            setAlert({visible: true, title: 'Atenção!', placeholder: 'As senhas não coincidem!', confirm: false, type: 'warning'})
        } else if(login.hash === undefined){
            setAlert({visible: true, title: 'Atenção!', placeholder: 'Ocorreu um erro ao redefinir a senha, entre em contato com suporte!', confirm: false, type: 'warning'})
        } else{
            delete login.senha2
            alterarSenha(login.email, login.senha, login.hash)
        }
    }


  return (
    <div className='login-wrapper'>
        <div className='login-container'>
            <div className='login-header'>
                <h1>Redefinir senha</h1>
            </div>
            <div className='login-form'>
                <div className="login-input-content">
                    <input className='login-input' type='text' placeholder='Email' onChange={handleEmailChange} />
                    <BiUser className='login-icon' />
                </div>

                <div className="login-input-content">
                    <input className='login-input' type='password' placeholder='Nova senha' name="senha" onChange={handlePasswordChange} />
                    <BiLock className='login-icon' />
                </div>

                <div className="login-input-content">
                    <input className='login-input' type='password' name="senha2" placeholder='Repetir nova a senha' onChange={handlePasswordChange} />
                    <BiLock className='login-icon' />
                </div>
              
                <button className='login-button' onClick={logIn}>Alterar senha</button>

            </div>
        </div>
      
    </div>
  )
}

export default Redefinir
