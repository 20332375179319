import { useState, useContext, useEffect } from "react";
import { faturadorDateFilter, faturadorDateFilterMap } from "../../General/Format/faturador";
import { BiX } from "react-icons/bi";
import { UserContext } from "../../../contexts/userProvider";
import { ClientContext } from "../../../contexts/clientProvider";
import { FaturadorContext } from "../../../contexts/faturadorProvider";

const FilterDate = ({ closeModal, setFilterDates, checkUpdate, setCheckUpdate }) => {
  const { clienteSelecionado } = useContext(FaturadorContext);
  const { clientes } = useContext(ClientContext);
  const { setAlert } = useContext(UserContext);
  const [dataInicio, setDataInicio] = useState(null);
  const [dataFim, setDataFim] = useState(null);

  useEffect(() => {
    if (!clientes) return;
    const selectedClient = clientes.find((client) => client?.nome === clienteSelecionado);
    let inicio = selectedClient?.created_at.split('T')[0];
    setDataInicio(inicio);
    setDataFim(new Date().toISOString().split('T')[0]);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "dataInicio") {
      setDataInicio(value);
    } else {
      setDataFim(value);
    }
  };

  const handleSaveClick = () => {
    let clienteSel = clientes.find((client) => client?.nome === clienteSelecionado);
    
    if (!dataInicio || !dataFim) {
      setAlert({ 
        visible: true, 
        title: "Atenção!", 
        placeholder: 'Preencha as datas corretamente.', 
        confirm: false, 
        type: "warning" 
      });
      return;
    }
  
    if (clienteSel && dataInicio < clienteSel.created_at.split('T')[0]) {
      setAlert({ 
        visible: true, 
        title: "Atenção!", 
        placeholder: 'A data inicial não pode ser menor que a data de cadastro do cliente.', 
        confirm: false, 
        type: "warning" 
      });
      return;
    }
  
    if (dataFim < dataInicio) {
      setAlert({ 
        visible: true, 
        title: "Atenção!", 
        placeholder: 'A data final não pode ser menor que a data inicial.', 
        confirm: false, 
        type: "warning" 
      });
      return;
    }

    if(dataFim > new Date().toISOString().split('T')[0]){
        setAlert({ 
            visible: true, 
            title: "Atenção!", 
            placeholder: 'A data final não pode ser maior que a data atual.', 
            confirm: false, 
            type: "warning" 
        });
        return;
    }
   
    setFilterDates({ dataInicio, dataFim, checkUpdate });
    closeModal();
  };
  

  const dados = faturadorDateFilter({ dataInicio, dataFim });
  const componentMap = faturadorDateFilterMap(
    handleInputChange,
    handleSaveClick,
    closeModal
  );

  return (
    <div className="modal-edit">
      <div className="modal-edit-body">
        <div className="modal-edit-header">
          <h1>Filtrar por data de implantação</h1>
          <BiX onClick={() => closeModal()} className="fechar-top-button" />
        </div>
        {dados.map((dado, index) => (
          <div key={index}>{componentMap["Edit"](dado)}</div>
        ))}

        <div style={{display:'flex', gap:'20px', alignItems:'center',justifyContent:'center'}}>
          <label htmlFor="checkUpdate">Busca por atualização do patrimônio</label>
          <input
            type="checkbox"
            id="checkUpdate"
            name="checkUpdate"
            onChange={() => setCheckUpdate(!checkUpdate)}
            checked={checkUpdate}
          />
        </div>

        <div className="modal-edit-buttons" style={{width:'100%', display:'flex',gap:'20px', justifyContent:'center'}}>
          {componentMap["buttonsEdit"]()}
        </div>
      </div>
    </div>
  );
};

export default FilterDate;
