import { useState, useContext, useEffect } from 'react'
import { PatrimonioContext } from '../../../contexts/patrimonioProvider'
import { UserContext } from '../../../contexts/userProvider'
import { BiPlus } from 'react-icons/bi'
import Cadastro from '../Grupos/cadastro'
import TransferTable from '../Grupos/TransferTable'

const Grupos = () => {
    const { grupos, equipamentos, equipamentosByGrupo, updateGrupoEquipamentos } = useContext(PatrimonioContext)
    const { setAlert } = useContext(UserContext)
    const [newGrupos, setNewGrupos] = useState([])
    const [grupoSelecionado, setGrupoSelecionado] = useState(null)
    const [cadastroModal, setCadastroModal] = useState(false)
    const [novosEquipamentos, setNovosEquipamentos] = useState([])
    const [equipamentosDoGrupo, setEquipamentosDoGrupo] = useState([])
    const [leftTableData, setLeftTableData] = useState([])
    const [rightTableData, setRightTableData] = useState([])

    useEffect(() => {
        let nGrupos = grupos ? [...grupos] : []
        setNewGrupos(nGrupos)
    }, [grupos])

    useEffect(() => {
        let nEquipamentos = equipamentos ? [...equipamentos] : []
        nEquipamentos.sort((a, b) => a.nome.localeCompare(b.nome))
        setNovosEquipamentos(nEquipamentos)
    }, [equipamentos])

    useEffect(() => {
        if (grupoSelecionado) {
            receberEquipamentosDoGrupo()
        }
    }, [grupoSelecionado])

    const receberEquipamentosDoGrupo = async () => {
        const dados = await equipamentosByGrupo(grupoSelecionado)
        setEquipamentosDoGrupo(dados)
    }

    const handleSelectGrupo = (e) => {
        const { value } = e.target;
        const newGrupo = newGrupos.find((grupo) => grupo.id === parseInt(value));
        setGrupoSelecionado(newGrupo);
    }

    const handleAddGrupo = () => {
        setCadastroModal(true);
    }

    const handleNewEquipamento = async () => {
        let nGroup = {}
        let equipamentosId = ''
        for (let i = 0; i < rightTableData.length; i++) {
            equipamentosId += rightTableData[i].id + ','
        }
        nGroup.id_modelo = equipamentosId.substring(0, equipamentosId.length - 1)
        nGroup.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ')
        nGroup.id = grupoSelecionado.id

        if (nGroup.id_modelo === grupoSelecionado.id_modelo) {
            setAlert({
                type: 'warning',
                placeholder: 'Não houve modificações nos equipamentos do grupo!',
                visible: true,
                confirm: false,
                title: 'Atenção!'
            })
            return
        }
        let dado = await updateGrupoEquipamentos(nGroup)
        if (dado) {
            let success = await equipamentosByGrupo(dado)
            return success
        }
    }

    return (
        <>
            <div className="patrimonio-consulta-select-container" style={{ marginBottom: "30px" }}>
                <label>Grupos:</label>
                <select
                    className="patrimonio-consulta-select"
                    onChange={handleSelectGrupo}
                >
                    <option value="">Selecione o grupo</option>
                    {newGrupos &&
                        newGrupos.slice()
                            .sort((a, b) => a.nome.localeCompare(b.nome))
                            .map((grupo, index) => (
                                <option key={index} value={grupo.id}>
                                    {grupo.nome}
                                </option>
                            ))}
                </select>
                <BiPlus size={24} onClick={handleAddGrupo} title="Adicionar Grupo" className="patrimonios-icons" />

                {cadastroModal && <Cadastro grupo={grupoSelecionado} setCadastroModal={setCadastroModal} />}
            </div>

            {grupoSelecionado &&
                <TransferTable
                    equipamentos={novosEquipamentos}
                    equipamentosDoGrupo={equipamentosDoGrupo}
                    leftTableData={leftTableData}
                    setLeftTableData={setLeftTableData}
                    rightTableData={rightTableData}
                    setRightTableData={setRightTableData}
                />}
            <button onClick={handleNewEquipamento} className="patrimonio-cadastro-button">Salvar Equipamento</button>
        </>
    )
}

export default Grupos
