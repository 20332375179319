import Dashcard from "../DashCard";
import value from "../DashCard/value";
import FormComp from "../FormsComp";

export const getDadosTec = (client) => {
    return [
        {
            titulo: 'Nome Responsável TI',
            valor: client?.responsavel_ti || 'Nenhum dado cadastrado.',
            name: 'responsavel_ti',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail Responsável TI',
            valor: client?.email_responsavel_ti || 'Nenhum dado cadastrado.',
            name: 'email_responsavel_ti',
            tipo: 'Input'
        },
        {
            titulo: 'Tec - E1',
            valor: client?.e1  === '1' ? 'SIM' : 'NÃO',
            valorSelect: client?.e1 === '1' ? '1' : '0',
            name: 'e1',
            tipo: 'Select',
            options: [{ id: '1', nome: 'SIM' }, { id: '0', nome: 'NÃO' }]
        },
        {
            titulo: 'Número Piloto',
            valor: client?.numero_piloto || 'Nenhum dado cadastrado.',
            name: 'numero_piloto',
            tipo: 'Input'
        },
        {
            titulo: 'Nome Técnico Responsável VoxCity',
            valor: client?.id_tecnico_voxcity || 'Nenhum dado cadastrado.',
            name: 'id_tecnico_voxcity',
            tipo: 'Input'
        },
    ]
};
export const getDadosGeral = (client, dados, estados, municipios) => {
    return [
        {
            titulo: 'Cadastro',
            valor: client?.ativo  === '1' ? 'Ativo' : 'Desativado',
            valorSelect: client?.ativo === '1' ? '1' : '0',
            name: 'ativo',
            tipo: 'Select',
            options: [{ id: '1', nome: 'Ativo' }, { id: '0', nome: 'Desativado' }]
        },
        {
            titulo: 'Nome',
            valor: client?.nome || 'Nenhum dado cadastrado.',
            name: 'nome',
            tipo: 'Input'
        },
        {
            titulo: 'CPF/CNPJ',
            valor: client?.cpf_cnpj || 'Nenhum dado cadastrado.',
            name: 'cpf_cnpj',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone',
            valor: client?.telefone || 'Nenhum dado cadastrado.',
            name: 'telefone',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail',
            valor: client?.email || 'Nenhum e-mail cadastrado.',
            name: 'email',
            tipo: 'Input'
        },
        {
            titulo: 'Endereço',
            valor: client?.endereco || 'Nenhum dado cadastrado.',
            name: 'endereco',
            tipo: 'Input'
        },
        {
            titulo: 'Estado',
            valor: dados.estado || 'Nenhum dado cadastrado.',
            name: 'estado',
            tipo: 'Select',
            options: estados,
        },
        {
            titulo: 'Cidade',
            valor: dados.cidade || 'Nenhum dado cadastrado.',
            name: 'cidade',
            tipo: 'Select',
            options: municipios,
        },
        {
            titulo: 'Observações',
            valor: client?.observacoes || 'Nenhum dado cadastrado.',
            name: 'observacoes',
            tipo: 'Input'
        },
    ]
}

export const getDadosFinanceiro = (client, splitEmail) => {
    return [
        {
            titulo: 'Responsável Financeiro',
            valor: client?.responsavel_financeiro || 'Nenhum dado cadastrado.',
            name: 'responsavel_financeiro',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone Financeiro',
            valor: client?.telefone_financeiro || 'Nenhum dado cadastrado.',
            name: 'telefone_financeiro',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail de recebimento de NF',
            valor: splitEmail(client?.email_responsalvel_financeiro) || 'Nenhum dado cadastrado.',
            name: 'email_responsalvel_financeiro',
            tipo: 'Input'
        },
        {
            titulo: 'Valor do contrato',
            valor: client?.valor_contrato || 'Nenhum dado cadastrado.',
            name: 'valor_contrato',
            tipo: 'Input'
        },
    ]
};

export const getDadosComercial = (client) => {
    return [
        {
            titulo: 'Nome Prefeito',
            valor: client?.nome_prefeito || 'Nenhum dado cadastrado.',
            name: 'nome_prefeito',
            tipo: 'Input'
        },
        {
            titulo: 'Nome Vice-prefeito',
            valor: client?.nome_vice_prefeito || 'Nenhum dado cadastrado.',
            name: 'nome_vice_prefeito',
            tipo: 'Input'
        },
        {
            titulo: 'Secretário(a) Administração',
            valor: client?.nome_secretario_adm || 'Nenhum dado cadastrado.',
            name: 'nome_secretario_adm',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail Secretario(a) Admin.',
            valor: client?.email_secretario_adm || 'Nenhum dado cadastrado.',
            name: 'email_secretario_adm',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone Secretário(a)',
            valor: client?.telefone_secretario_adm || 'Nenhum dado cadastrado.',
            name: 'telefone_secretario_adm',
            tipo: 'Input'
        },
        {
            titulo: 'Nome do Contato',
            valor: client?.nome_contato_comercial || 'Nenhum dado cadastrado.',
            name: 'nome_contato_comercial',
            tipo: 'Input'
        },
        {
            titulo: 'Telefone',
            valor: client?.telefone_comercial || 'Nenhum dado cadastrado.',
            name: 'telefone_comercial',
            tipo: 'Input'
        },
        {
            titulo: 'E-mail',
            valor: client?.email_comercial || 'Nenhum dado cadastrado.',
            name: 'email_comercial',
            tipo: 'Input'
        },
        {
            titulo: 'Setor',
            valor: client?.setor_comercial || 'Nenhum dado cadastrado.',
            name: 'setor_comercial',
            tipo: 'Input'
        },
    ]
}

export const getComponentMap = (handleFecharClick, handleCancelClick, handleSaveClick, handleEditClick, handleInputChange) => {
    return {
        Geral: (dado) => <Dashcard titulo={dado.titulo} valor={dado.valor} />,
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='vizualizar-modal-button' onClick={handleCancelClick}>Cancelar</button>
                <button className='vizualizar-modal-button' onClick={handleSaveClick}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='vizualizar-modal-button' onClick={() => handleFecharClick()}>Fechar</button>
                <button className='vizualizar-modal-button' onClick={handleEditClick}>Editar</button>
            </>
        )
    };
};

export const getButtonsMap = (handleFecharClick, handleCancelClick, handleSaveClick, handleEditClick) => {
    return {
        buttonsEdit: () => (
            <>
                <button className='vizualizar-modal-button' onClick={handleCancelClick}>Cancelar</button>
                <button className='vizualizar-modal-button' onClick={handleSaveClick}>Salvar</button>
            </>
        ),
        buttonsGeral: () => (
            <>
                <button className='vizualizar-modal-button' onClick={() => handleFecharClick()}>Fechar</button>
                <button className='vizualizar-modal-button' onClick={handleEditClick}>Editar</button>
            </>
        )
    };
}