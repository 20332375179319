import { createContext, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const AnexosContext = createContext();

export const AnexosProvider = ({ children }) => {
    const { setAlert, user } = useContext(UserContext);
    const userId = user?.id

    const anexosByClient = async (id) => {
        try {
            const response = await apiUser.post('/anexos/anexosByCliente', { id })
            return response.data.dados
        }
        catch (err) {
            console.log(err)
        }
    }

    const deleteAnexo = async (id) => {
        try {
            const response = await apiUser.post('/anexos/delete', { id })
            if (response.data.success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: response.data.message, confirm: false, type: "success" })
            }
            else {
                setAlert({ visible: true, title: "Atenção!", placeholder: response.data.message, confirm: false, type: "error" })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const updateAnexo = async (anexo) => {
        try {
            const response = await apiUser.post('/anexos/update', { anexo })
            if (response.data.success) {
                return response.data.dados
            }
            else {
                setAlert({
                    visible: true,
                    title: "Atenção!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "error"
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const createAnexo = async (anexo, linhas) => {
        try {
            const response = await apiUser.post('/anexos/create', { anexo, linhas })
            if (response.data.success) {
                setAlert({
                    visible: true,
                    title: "Sucesso!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "success"
                })
            }
            else {
                setAlert({
                    visible: true,
                    title: "Atenção!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "error"
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const linhasByAnexo = async (id) => {
        try {
            const response = await apiUser.post('/anexos/linhasByAnexo', { id })
            return response.data.dados
        }
        catch (err) {
            console.log(err)
        }
    }

    const updateLinhaById = async (linha) => {
        try {
            const response = await apiUser.post('/anexos/updateLinha', { linha })
            if (response.data.success) {
                setAlert({
                    visible: true,
                    title: "Sucesso!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "success"
                })
            }
            else {
                setAlert({
                    visible: true,
                    title: "Atenção!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "error"
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const deleteLinhaById = async (id) => {
        try {
            const response = await apiUser.post('/anexos/deleteLinha', { id })
            if (response.data.success) {
                setAlert({
                    visible: true,
                    title: "Sucesso!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "success"
                })
            }
            else {
                setAlert({
                    visible: true,
                    title: "Atenção!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "error"
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const createLinha = async (linhas) => {
        try {
            const response = await apiUser.post('/anexos/createLinha', { linhas })
            if (response.data.success) {
                setAlert({
                    visible: true,
                    title: "Sucesso!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "success"
                })
            }
            else {
                setAlert({
                    visible: true,
                    title: "Atenção!",
                    placeholder: response.data.message,
                    confirm: false,
                    type: "error"
                })
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <AnexosContext.Provider value={{
            anexosByClient,
            deleteAnexo,
            updateAnexo,
            userId,
            createAnexo,
            linhasByAnexo,
            updateLinhaById,
            deleteLinhaById,
            createLinha
        }}>
            {children}
        </AnexosContext.Provider>
    );
};
