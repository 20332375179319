import { useState, useContext, useEffect } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'

const GeralCadastro = ({ newClient, handleInputChange }) => {
    const { estados } = useContext(ClientContext);
    const [municipios, setMunicipios] = useState([]);

    useEffect(() => {
        fetchData();
    }, [newClient.estado]);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${newClient.estado}/municipios`
            );
            const data = await response.json();
            if (Array.isArray(data)) {
                setMunicipios(data.map(municipio => municipio));
            } else {
                console.error('Resposta inesperada da API:', data);
            }

        } catch (error) {
            console.error('Erro ao recuperar dados:', error);
        }
    };
    return (
        <div className='vizualizar-modal-content-cadastro'>

            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Nome</h2>
                <input
                    type='text'
                    name='nome'
                    value={newClient.nome}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>CPF/CNPJ</h2>
                <input
                    type='text'
                    name='cpf_cnpj'
                    value={newClient.cpf_cnpj}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Telefone</h2>
                <input
                    type='text'
                    name='telefone'
                    value={newClient.telefone}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Email</h2>
                <input
                    type='text'
                    name='email'
                    value={newClient.email}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Endereço</h2>
                <input
                    type='text'
                    name='endereco'
                    value={newClient.endereco}
                    onChange={handleInputChange}
                />
            </div>

            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Estado</h2>
                <select
                    name='estado'
                    value={newClient.estado}
                    onChange={handleInputChange}
                >
                    <option value=''>Selecione um estado</option>
                    {Object.values(estados).map((estado, index) => {
                        return (
                            <option key={index} value={estado.id}>
                                {estado.nome}
                            </option>
                        );
                    }
                    )}
                </select>
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Cidade</h2>
                <select
                    name='cidade'
                    value={newClient.cidade}
                    onChange={handleInputChange}
                >
                    <option value=''>Selecione uma cidade</option>
                    {municipios?.map((municipio, index) => {
                        return (
                            <option key={index} value={municipio.id}>
                                {municipio.nome}
                            </option>
                        );
                    })}
                </select>
            </div>
        </div>
    )
}

export default GeralCadastro
