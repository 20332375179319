import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../../contexts/clientProvider';
import { VoxSoftwaresContext } from '../../../../contexts/voxSoftwaresProvider';
import { getDadosBasico, getComponentMap } from '../../../General/Format/organizacao'

const Basico = ({ newOrg, setNewOrg, setType, isEdit, previousOrg}) => {
    const { estados } = useContext(ClientContext);
    const { setAlert, updateOrg } = useContext(VoxSoftwaresContext);
    const [cidades, setCidades] = useState([]);

    useEffect(() => {
        fetchData();
    }, [newOrg.estado]);

    const fetchData = async () => {
        try {
            const response = await fetch(
                `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${newOrg.estado}/municipios`
            );
            const data = await response.json();
            if (Array.isArray(data)) {
                setCidades(data.map(municipio => municipio));
            } else {
                console.error('Resposta inesperada da API:', data);
            }

        } catch (error) {
            console.error('Erro ao recuperar dados:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewOrg((prevData) => ({ ...prevData, [name]: value }));
    };

    const dados = getDadosBasico(newOrg, estados, cidades)
    const componentMap = getComponentMap(handleInputChange)

    const handleNext = () => {
        if(isEdit){
            if(JSON.stringify(previousOrg) === JSON.stringify(newOrg)){
                setType('serviços')
                return
            }else{
                setAlert({
                     visible: true,
                     title: "Atenção!",
                     placeholder: "Deseja salvar as alterações feitas?",
                     confirm: true,
                     type: "confirm",
                     callback: () => updateOrg(newOrg),
                     params: newOrg
                    });
                setType('serviços')
                return
            }
        }
        let dados = ['nome', 'cnpj', 'endereco', 'estado', 'cidade'];
        dados.forEach(dado => {
            if (!newOrg[dado]) {
                setAlert({ visible: true, title: "Erro", placeholder: `O campo ${dado} é obrigatório.`, confirm: false, type: "negative" });
                return
            } else {
                setNewOrg((prevData) => ({ ...prevData, services: {} }));
                setType('serviços')
            }
        })
        return
    }

    return (
        <>
            <div className='softwares-box-forms'>
                {dados.map((dado, index) => {
                    return <div className='form-softwares' key={index}>{componentMap['Cadastro'](dado)}</div>
                })}
            </div>

            <div>
                <button className='next-button' style={{width:'200px'}} onClick={handleNext}>{isEdit ? 'Salvar':'Avançar'}</button>
            </div>

        </>
    )
}

export default Basico