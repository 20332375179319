import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { UserContext } from '../../../contexts/userProvider'
import { FornecedoresContext } from '../../../contexts/fornecedoresProvider'
import { dadosFornecedores, cadastroComponentsMap } from '../../General/Format/fornecedores'

const Cadastro = () => {
  const { estados } = useContext(ClientContext);
  const { createFornecedor } = useContext(FornecedoresContext);
  const { user } = useContext(UserContext);
  const [municipios, setMunicipios] = useState([]);
  const [newFornecedor, setNewFornecedor] = useState({});

  const fetchData = async (estado) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao recuperar dados:', error);
    }
  };

  useEffect(() => {
    fetchData(newFornecedor.estado).then(setMunicipios);
  }, [newFornecedor.estado]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewFornecedor((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveClick = async () => {
    let fornecedor = { ...newFornecedor, id_usuario: user.id, usuario: user.nome }
    fornecedor.created_at = fornecedor.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    createFornecedor(fornecedor);
    setNewFornecedor({});
  };

  const dadosGeral = dadosFornecedores(newFornecedor);
  const componentMap = cadastroComponentsMap(handleInputChange);

  return (
    <>
      <div className='form-container' >
        {dadosGeral.map((dado, index) => {
          return <div className='form-group' key={index}>{componentMap['Cadastro'](dado)}</div>
        }
        )}
        <div className='form-group'>
          <label>Estado</label>
          <select name='estado' onChange={handleInputChange}>
            <option value=''>Selecione o estado</option>
            {estados.map((estado, index) => (
              <option key={index} value={estado.nome}>
                {estado.nome}
              </option>
            ))}
          </select>

        </div>
        <div className='form-group'>
          <label>Cidade</label>
          <select name='municipio' onChange={handleInputChange}>
            {municipios.map((municipio, index) => (
              <option key={index} value={municipio.nome}>
                {municipio.nome}
              </option>
            ))}
          </select>
        </div>

      </div>
      <button className='anexos-cadastro-button' onClick={handleSaveClick}>Salvar</button>
    </>
  )
}

export default Cadastro
