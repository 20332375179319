import React, { useContext, useEffect, useState } from 'react';
import { ClientContext } from '../../../contexts/clientProvider';
import Visualizar from '../Visualizar';
import Servicos from '../Servicos';
import DataTable from '../../Table';
import Loader from '../../Loader/load';
import Equipamentos from '../Equipamentos';

const Consulta = () => {
    const { clientes, loading, setAlert, deleteCliente } = useContext(ClientContext);
    const [clients, setClients] = useState(null);
    const [clienteSelecionado, setClienteSelecionado] = useState(null);
    const [servicosPrestados, setServicosPrestados] = useState(null);
    const [type, setType] = useState('');
    const componentes = {
        vizualizar: <Visualizar cliente={clienteSelecionado} closeModal={closeModal} />,
        servicos: <Servicos closeModal={closeModal} clienteSelecionado={clienteSelecionado} servicosPrestados={servicosPrestados}  />,
        equipamentos: <Equipamentos cliente={clienteSelecionado} closeModal={closeModal} />
    }
        
    useEffect(() => {
        let newClients = clientes ? clientes : [];
        setClients(newClients);
    }, [clientes]);


    const headers = [
        { label: 'Nome', key: 'nome', sortable: true },
        { label: 'CPF/CNPJ', key: 'cpf_cnpj', sortable: false },
        { label: 'Telefone', key: 'telefone', sortable: false },
        { label: 'Ações', key: 'actions', sortable: false, actions: { "vizualizar": vizualizarClient, "vincular": vincularServicosPrestados, "equipamentos": equipamentosClient, "deletar": deleteClient, } },
    ];

    function vizualizarClient(id) {
        const client = clients.find((client) => client.id === id);
        setClienteSelecionado(client);
        setType('vizualizar');
    }

    function deleteClient(id) {
        if (!id) return console.error('ID não informado');
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar o cliente?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteCliente(id),
            params: id
        })
    }

    function equipamentosClient(id) {
       // setAlert({ visible: true, title: "Atenção!", placeholder: 'Opção ainda em desenvolvimento.', confirm: false, type: "warning" });
        setClienteSelecionado(id);
        setType('equipamentos');
    }

    async function vincularServicosPrestados(id) {
        const client = clients.find((client) => client.id === id);
        setClienteSelecionado({...client});
        setType('servicos');
    }

    function closeModal() {
        setClienteSelecionado(null);
        setServicosPrestados(null);
        setType('');
    }


    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="cliente-consulta-contander">
                    {clients && <DataTable data={clients} headers={headers} itemsPerPage={10} />}
                    {type && componentes[type]}
                </div>
            )}
        </>
    );
};

export default Consulta;
