import { useState , useEffect, useContext } from 'react';
import { EquipamentosContext } from '../../../contexts/equipamentosProvider';
import { BiEdit } from 'react-icons/bi';
import AlterModal from './alterModal';

const EquipamentoTable = ({ dados, cliente }) => {
    const { gerarRelatorio } = useContext(EquipamentosContext);
    const [grupos, setGrupos] = useState(null);
    const [isAlterarModalOpen, setIsAlterarModalOpen] = useState(false);
    const [selectedGrupo, setSelectedGrupo] = useState(null);

    useEffect(() => {
      let newGrupos = dados ? [...dados] : [];
      newGrupos = newGrupos.map((grupo) => {
        grupo.maxEquipamentos = grupo.maxEquipamentos || 0;
        return grupo;
      });
      setGrupos(newGrupos);
    }, [dados]);

    const handleAlterarModal = (grupo) => {
      if(!isAlterarModalOpen){
        setSelectedGrupo(grupo);
        setIsAlterarModalOpen(true);
      }else{
        setSelectedGrupo(null);
        setIsAlterarModalOpen(false);
      }
    };

    const handleGerarRelatorio = () => {
      gerarRelatorio(cliente.id);
    }

    return (
      <div className="equipamento-table-container">
        {grupos && grupos.length > 0 ? (
          <div className="equipamento-table-card">
            <div className="equipamento-table-card-body">
              <div className="table-responsive">
                <table className="equipamento-table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Grupo Equipamento</th>
                      <th scope="col">Quantidade Total</th>
                      <th scope="col">Instalados/Disponíveis</th>
                      <th scope="col">Ação</th>
                    </tr>
                  </thead>
                  <tbody id="listaEquipamento">
                    {grupos.map((grupoCliente, i) => {
                      const { grupoNome, totalPatrimoniosNfs, totalPatrimonios, maxEquipamentos } = grupoCliente;
                      const totalPats = totalPatrimoniosNfs + totalPatrimonios;
    
                      let progressColor = null;
                      if (totalPats > maxEquipamentos) {
                        progressColor = 'red-bar';
                      } else if (totalPats < maxEquipamentos) {
                        progressColor = 'blue-bar';
                      } else if (totalPats === maxEquipamentos) {
                        progressColor = 'green-bar';
                      }
    
                      return (
                        <tr key={i}>
                          <td className="equipamento-table-item">
                            <b>{i + 1}</b>
                          </td>
                          <td className="equipamento-table-item">{grupoNome}</td>
                          <td className="equipamento-table-item">{totalPats}</td>
                          <td className="equipamento-table-item">
                            <b>{totalPats} / {maxEquipamentos}</b>
                            <div className="progress">
                              <div
                                className={`progress-bar ${progressColor}`}
                                title="Percentual concluído"
                              />
                            </div>
                          </td>
                          <td className="equipamento-table-item">
                            <BiEdit title='Alterar limite contratado' style={{ cursor: 'pointer' }} onClick={() => handleAlterarModal(grupoCliente)} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : (
          <div className="equipamento-table-card-body">
            <button className="patrimonio-cadastro-button" onClick={handleGerarRelatorio}>Gerar relatorio</button>
          </div>
        )}

        {grupos && grupos.length > 0 && (
          <div className="equipamento-table-card-body">
            <button className="patrimonio-cadastro-button" onClick={handleGerarRelatorio}>Atualizar relatorio</button>
          </div>
        )}
    
        {isAlterarModalOpen && (
          <AlterModal closeModal={handleAlterarModal} grupo={selectedGrupo} />
        )}
      </div>
    );
    
};

export default EquipamentoTable;
