import { useState } from 'react'
import Consulta from './Types/consulta'
import Cadastro from './Types/cadastro'
import './styles.css'

const Anexos = () => {
    const [type, setType] = useState('consulta')
    const anexosType = {
        consulta: <Consulta />,
        cadastro: <Cadastro setType={setType} />,
    }
    

    return (
        <div className='anexos-contander'>
            <div className='anexos-header'>
                <button
                    className="anexos-button"
                    onClick={() => setType('consulta')}
                    style={
                        type === "consulta"
                            ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                            : null
                    }
                >
                    Consulta
                </button>
                <button
                    className="anexos-button"
                    onClick={() => setType('cadastro')}
                    style={
                        type === "cadastro"
                            ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                            : null
                    }
                >
                    Cadastro
                </button>
            </div>
            <div className='anexos-body'>{anexosType[type]}</div>
        </div>
    )
}

export default Anexos
