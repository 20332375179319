import { useState, useContext, useEffect } from "react";
import { FornecedoresContext } from "../../../contexts/fornecedoresProvider";
import { UserContext } from "../../../contexts/userProvider";
import { BiX } from "react-icons/bi";

const ProdutoEdit = ({
  produto,
  salvarProduto,
  closeEditModal,
  marcas,
  modelos,
}) => {
  const { fornecedores } = useContext(FornecedoresContext);
    const { setAlert } = useContext(UserContext);
  const [newfornecedores, setNewFornecedores] = useState(null);
  const [newModelos, setNewModelos] = useState([]);
  const [newMarcas, setNewMarcas] = useState([]);
  const [modeloNome, setModeloNome] = useState(null);
  const [marcaNome, setMarcaNome] = useState(null);
  const [fornecedorNome, setFornecedorNome] = useState(null);

  useEffect(() => {
    let newFornecedores = fornecedores ? fornecedores : [];
    setNewFornecedores(newFornecedores);
  }, [fornecedores]);

  useEffect(() => {
    let newMarcas = marcas ? marcas : [];
    setNewMarcas(newMarcas);
  }, [marcas]);

  useEffect(() => {
    let newModelos = modelos ? modelos : [];
    setNewModelos(newModelos);
  }, [modelos]);

  useEffect(() => {
    if (produto.modelo) {
      let modelo = modelos.find((modelo) => modelo.id === produto.modelo);
      if (modelo) {
        setModeloNome(modelo.nome);
      }
    }

    if(!produto.marca && produto.modelo){
        let modelo = modelos.find((modelo) => modelo.id === produto.modelo);
        if(modelo?.id_marca){
            produto.marca = modelo.id_marca;
            setMarcaNome(marcas.find((marca) => marca.id === modelo?.id_marca)?.nome)
        }
    }
  }, []);

  const handleSelectFornecedor = async (e) => {
    const { value } = e.target;
    let fornecedor = newfornecedores.find(
      (fornecedor) => fornecedor.id === parseInt(value)
    );
    if (fornecedor) setFornecedorNome(fornecedor.nome);
    produto.fornecedor = parseInt(value);
  };

  const handleSelectMarca = async (e) => {
    const { value } = e.target;
    produto.marca = parseInt(value);
    
    let marca = newMarcas.find((marca) => marca.id === parseInt(value));
    if (marca?.id_fornecedor === produto.fornecedor) {
        setMarcaNome(marca.nome);
    }else{
        setAlert({ visible: true, title: "Atenção!", placeholder: "Marca não pertence ao fornecedor selecionado", confirm: false, type: "warning" });
        produto.marca = null;
        setMarcaNome('');
    }
  };

  const handleSelectModelo = (e) => {
    const { value } = e.target;
    produto.modelo = parseInt(value);
    let modelo = newModelos.find((modelo) => modelo.id === parseInt(value));
    if (modelo?.id_marca === produto.marca) {
        setModeloNome(modelo.nome);
    }else{
        setAlert({ visible: true, title: "Atenção!", placeholder: "Modelo não pertence à marca selecionada", confirm: false, type: "warning" });
        produto.modelo = null;
        setModeloNome('');
    }
  };

  return (
    <div className="vizualizar-modal">
      <div className="vizualizar-modal-body">
        <BiX onClick={() => closeEditModal()} className="fechar-top-button" />
        <div className="vizualizar-modal-header">
          <h1>Adicionar Modelo / Marca</h1>
        </div>

        <div>
          <div className="patrimonio-consulta-select-container">
            <label>Fornecedor:</label>
            <select
              className="patrimonio-consulta-select"
              onChange={handleSelectFornecedor}
              value={produto.fornecedor}
            >
              <option value="">Selecione o fornecedor</option>
              {newfornecedores &&
                newfornecedores
                  .slice()
                  .sort((a, b) => a.nome.localeCompare(b.nome))
                  .map((fornecedor, index) => (
                    <option key={index} value={fornecedor.id}>
                      {fornecedor.nome}
                    </option>
                  ))}
            </select>
          </div>

          <div className="patrimonio-consulta-select-container">
            <label>Marca:</label>
            <select
              className="patrimonio-consulta-select"
              onChange={handleSelectMarca}
              value={produto?.marca}
            >
              <option value="">Selecione a marca</option>
              {newMarcas &&
                newMarcas
                  .slice()
                  .sort((a, b) => a.nome.localeCompare(b.nome))
                  .map((marca, index) => (
                    <option key={index} value={marca.id}>
                      {marca.nome}
                    </option>
                  ))}
            </select>
          </div>

          <div className="patrimonio-consulta-select-container">
            <label>Modelo:</label>
            <select
              className="patrimonio-consulta-select"
              onChange={handleSelectModelo}
              value={produto.modelo}
            >
              <option value="">Selecione o modelo</option>
              {newModelos &&
                newModelos
                  .slice()
                  .sort((a, b) => a.nome.localeCompare(b.nome))
                  .map((modelo, index) => (
                    <option key={index} value={modelo.id}>
                      {modelo.nome}
                    </option>
                  ))}
            </select>
          </div>
          <div className="info-produtos">
            {
              <p>
                <span>Produto selecionado: </span>
                {produto.nome}
              </p>
            }
            {
              <p>
                <span>Quantidade: </span>
                {produto.quantidade}
              </p>
            }
            {
              <p>
                <span>Fornecedor: </span>
                {produto?.fornecedor
                  ? newfornecedores?.find(
                      (fornecedor) =>
                        fornecedor.id === parseInt(produto.fornecedor)
                    )?.nome
                  : ""}
              </p>
            }
            {
              <p>
                <span>Marca: </span>
                {produto?.marca
                  ? newMarcas?.find(
                      (marca) => marca.id === parseInt(produto.marca)
                    )?.nome
                  : ""}
              </p>
            }
            {
              <p>
                <span>Modelo: </span>
                {produto?.modelo ? modeloNome : ""}
              </p>
            }
          </div>
          {produto && (
            <div className="vizualizar-modal-footer">
              <button
                onClick={() => salvarProduto(produto)}
                className="vizualizar-modal-button"
              >
                Salvar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProdutoEdit;
