import { createContext, useEffect, useState,useContext} from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const FornecedoresContext = createContext();

export const FornecedoresProvider = ({ children }) => {
    const { setAlert } = useContext(UserContext);
    const [fornecedores, setFornecedores] = useState([]);

    const getFornecedores = async () => {
        try {
            const response = await apiUser.get("/fornecedores/dados");
            const { success, message, dados } = response.data;
            if (success) {
                setFornecedores(dados);
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const createFornecedor = async (fornecedor) => {
        try {
            const response = await apiUser.post("/fornecedores/create", fornecedor);
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                getFornecedores();
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateFornecedor = async (fornecedor) => {
        try {
            const response = await apiUser.post("/fornecedores/update", fornecedor);
            const { success, message, dados } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteFornecedor = async (id) => {
        try {
            const response = await apiUser.post(`/fornecedores/delete`, { id });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                getFornecedores();
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const allMarcas = async () => {
        try {
            const response = await apiUser.post('/fornecedores/allMarcas')
            const { success, message, dados } = response.data;
            if (success) {
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const allModelos = async () => {
        try {
            const response = await apiUser.post('/fornecedores/allModelos')
            const { success, message, dados } = response.data;
            if (success) {
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getFornecedores();
    }, []);




    return (
        <FornecedoresContext.Provider value={{
            fornecedores,
            deleteFornecedor,
            updateFornecedor,
            createFornecedor,
            allMarcas,
            allModelos
        }}>
            {children}
        </FornecedoresContext.Provider>
    );
};
