import { createContext, useEffect, useState } from "react";
import apiUser from "../apiUser";

export const DashContext = createContext();

export const DashProvider = ({ children }) => {
    const [alert, setAlert] = useState({ visible: false, title: "", placeholder: "", confirm: false, type: "none" });
    const [loading, setLoading] = useState(false);
    const [dashStatus, setDashStatus] = useState(null);

    const dashBoardItems = async () => {
        setLoading(true);
        try {
            const response = await apiUser.get("/dashboard/dados");
            const { success, dados, message } = response.data;
            if (success) {
                setDashStatus(dados);
                setLoading(false);
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        dashBoardItems();
    }, []);

    return (
        <DashContext.Provider value={{
            dashStatus,
            loading,
            setLoading,
            alert,
            setAlert,
        }}>
            {children}
        </DashContext.Provider>
    );
};
