import { useState, useContext } from "react";
import { UserContext } from "../../../contexts/userProvider";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";
import ConsultaTipo from "../Consulta/index";

const Consulta = () => {
  const { setAlert } = useContext(UserContext);
  const { buscarPatrimoniosNfs, buscarPatrimonios, patLoading } = useContext(PatrimonioContext);
  const [editedData, setEditedData] = useState({});
  const [type, setType] = useState("CONSULTA NOVO");
  const [patrimonioModal, setPatrimonioModal] = useState(false);
  const [patrimonioType, setPatrimonioType] = useState('');

  const handleSearchClick = async () => {
    if (!editedData.id_cliente && !editedData.id_anexo && !editedData.busca) {
      setAlert({ visible: true, title: "Atenção!", placeholder: 'Preencha algum campo para realizar a consulta.', confirm: false, type: "warning" });
      return;
    } else {
      if (type === 'CONSULTA NOVO') {
        let modal = await buscarPatrimoniosNfs(editedData);
        setPatrimonioType('novo');
        setPatrimonioModal(modal);
      } else {
        let modal = await buscarPatrimonios(editedData);
        setPatrimonioType('inventario');
        setPatrimonioModal(modal);
      }
    }
  }

  const consultaType = {
    'CONSULTA NOVO': <ConsultaTipo handleSearchClick={handleSearchClick} editedData={editedData} setEditedData={setEditedData} patrimonioModal={patrimonioModal} setPatrimonioModal={setPatrimonioModal} patrimonioType={patrimonioType} loading={patLoading}/>,
    'CONSULTA INVENTÁRIO': <ConsultaTipo handleSearchClick={handleSearchClick} editedData={editedData} setEditedData={setEditedData} patrimonioModal={patrimonioModal} setPatrimonioModal={setPatrimonioModal} patrimonioType={patrimonioType} loading={patLoading} />,
  };

  const consultaLabels = Object.keys(consultaType);

  const handleButtonClick = (selectedType) => setType(selectedType);

  return (
    <div className="patrimonio-container" style={{backgroundColor:'#f8f8f8', padding:'10px'}}>
      <div className="patrimonio-header" style={{ margin: 0 }}>
        {consultaLabels.map((label) => (
          <button
            key={label}
            className="linhas-button"
            onClick={() => handleButtonClick(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="patrimonio-body">
        {consultaType[type]}
      </div>
    </div>
  );
};

export default Consulta;
