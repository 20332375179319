import { useState, useContext, useEffect } from 'react'
import { ClientContext } from '../../../../contexts/clientProvider'
import {getDadosTec, getComponentMap} from '../../../General/Format/cliente';

const Tecnica = ({ client, setClient, handleFecharClick }) => {
    const { updateCliente } = useContext(ClientContext);
    const [type, setType] = useState('Geral');
    const [editedData, setEditedData] = useState({});

    useEffect(() => {
        setEditedData(client);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleEditClick = () => {
        setType('Edit');
        setEditedData(client);
    }

    const handleSaveClick = async () => {
        const newClient = await updateCliente(editedData);
        setClient(newClient);
        setType('Geral');
        handleFecharClick();
    }

    const handleCancelClick = () => {
        setType('Geral');
    }

    const dadosTec = getDadosTec(editedData);

    const componentMap = getComponentMap(
        handleFecharClick,
        handleCancelClick,
        handleSaveClick,
        handleEditClick,
        handleInputChange,
        type
    );

    return (
        <>
            <div className='vizualizar-modal-content'>
                {dadosTec.map((dado, index) => {
                    return <div className='vizualizar-modal-content-item' key={index}>
                        {componentMap[type](dado)}
                    </div>
                })}
            </div>
            <div className='vizualizar-edit-box'>
                {componentMap[`buttons${type}`]()}
            </div>
        </>
    )
}

export default Tecnica