import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { UserContext } from "../contexts/userProvider";
import { ClientProvider } from "../contexts/clientProvider";
import { DashProvider } from "../contexts/dashProvider";
import { AnexosProvider } from "../contexts/anexosProvider";
import { FornecedoresProvider } from "../contexts/fornecedoresProvider";
import { PatrimonioProvider } from "../contexts/patrimonioProvider";
import { RelatoriosProvider } from "../contexts/relatoriosProvider";
import { VoxSoftwaresProvider } from "../contexts/voxSoftwaresProvider";
import { FaturadorProvider } from "../contexts/faturadorProvider";
import { EquipamentosProvider } from "../contexts/equipamentosProvider";

export default function Authenticator({ children }) {
    const { signed } = useContext(UserContext);
    let location = useLocation();
    const isNotAuth = location.pathname === "/openTable" || location.pathname.includes("senha") || location.pathname === "/termos";

    return (
        <>
            {signed === true ? (
                <>
                    <DashProvider>
                        <ClientProvider>
                            <AnexosProvider>
                                <FornecedoresProvider>
                                    <PatrimonioProvider>
                                        <RelatoriosProvider>
                                            <VoxSoftwaresProvider>
                                                <FaturadorProvider>
                                                    <EquipamentosProvider>
                                                        {children}
                                                    </EquipamentosProvider>
                                                </FaturadorProvider>
                                            </VoxSoftwaresProvider>
                                        </RelatoriosProvider>
                                    </PatrimonioProvider> 
                                </FornecedoresProvider>
                            </AnexosProvider>
                        </ClientProvider>
                    </DashProvider>
                </>
            )
            : isNotAuth ? (
                <>
                    {children}
                </>
            )
            :
            (
                <>
                    <Navigate to="/login" replace state={{ path: location.pathname }} />
                </>
            )}
        </>
    );
}
