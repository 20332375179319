import { useState } from "react";
import Patrimonio from "./Types/patrimonios";
import "./styles.css";

const Index = () => {
  const [type, setType] = useState("Faturador de equipamentos");

  const patrimoniosType = {
    "Faturador de equipamentos": <Patrimonio />,
  };

  const patrimoniosLabels = Object.keys(patrimoniosType);

  return (
    <div className="faturador-patrimonio-contander">
      <div className="faturador-patrimonio-header">
        {patrimoniosLabels.map((label) => (
          <button
            key={label}
            className="faturador-patrimonio-button"
            onClick={() => setType(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="faturador-patrimonio-body">{patrimoniosType[type]}</div>
    </div>
  );
};

export default Index;
