import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts/userProvider";
import { EquipamentosContext } from "../../../contexts/equipamentosProvider";
import { BiX } from "react-icons/bi";

const AlterModal = ({ closeModal, grupo }) => {
  const { setAlert } = useContext(UserContext);
  const { updateEquipamento } = useContext(EquipamentosContext);
  const [maxEquipamentos, setMaxEquipamentos] = useState("");

  useEffect(() => {
    if (grupo) {
      setMaxEquipamentos(grupo.maxEquipamentos);
    }
  }, []);

  const handleChange = (e) => {
    setMaxEquipamentos(e.target.value);
  };

  const handleSave = () => {
    if (!maxEquipamentos) {
      setAlert({
        visible: true,
        title: "Atenção!",
        placeholder: "Preencha o campo de quantidade máxima",
        confirm: false,
        type: "warning",
      });
      return;
    }
    if (maxEquipamentos < grupo.totalPatrimoniosNfs + grupo.totalPatrimonios) {
      setAlert({
        visible: true,
        title: "Atenção!",
        placeholder:
          "A quantidade máxima não pode ser menor que a quantidade de equipamentos instalados",
        confirm: false,
        type: "warning",
      });
      return;
    }
    if (maxEquipamentos === grupo.maxEquipamentos) {
      setAlert({
        visible: true,
        title: "Atenção!",
        placeholder: "Nenhuma alteração foi feita",
        confirm: false,
        type: "warning",
      });
      return;
    }
    updateEquipamento(grupo.equipamentoId, maxEquipamentos, grupo.clienteId);
    closeModal();
  };

  return (
    <div className="modal-edit">
      <div
        className="modal-edit-body"
        style={{
          maxWidth: "550px",
          justifyContent: "flex-start",
          maxHeight: "320px",
        }}
      >
        <BiX onClick={() => closeModal()} className="fechar-top-button" />
        <div className="modal-edit-header">
          <h1>Editar</h1>
        </div>
        <div
          className="edit-modal-content"
          style={{ flexDirection: "column", display: "flex" }}
        >
          <div className="alter-modal-content-item">
            <h2 htmlFor="grupoNome">Grupo Equipamento</h2>
            <span>{grupo.grupoNome}</span>
          </div>
          <div className="alter-modal-content-item">
            <h2 htmlFor="maxEquipamentos">Quantidade Maxíma</h2>
            <input
              type="number"
              id="maxEquipamentos"
              value={maxEquipamentos}
              onChange={handleChange}
            />
          </div>
          <div className="alter-modal-content-item">
            <h2 htmlFor="totalPatrimonios">Instalados</h2>
            <span>{grupo.totalPatrimoniosNfs + grupo.totalPatrimonios}</span>
          </div>
        </div>
        <div className='modal-edit-footer' style={{ width: "100%", display: "flex", gap: "20px", justifyContent: "center" }}>
            <button className='patrimonio-cadastro-button' onClick={() => closeModal()}>Cancelar</button>
            <button className='patrimonio-cadastro-button' onClick={()=> handleSave()}>Salvar</button>
          </div>
      </div>
    </div>
  );
};

export default AlterModal;
