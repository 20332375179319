import { useState, useContext, useEffect } from "react";
import { FornecedoresContext } from "../../../contexts/fornecedoresProvider";
import Marca from "../Marca";
import Modelo from "../Modelo";
import "../styles.css";

const MarcaModelo = () => {
  const { fornecedores } = useContext(FornecedoresContext);
  const [newfornecedores, setNewfornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState(null);
  const [marca, setMarca] = useState(null);
  const [modelo, setModelo] = useState(null);

  useEffect(() => {
    let newFornc = fornecedores ? [...fornecedores] : [];
    setNewfornecedores(newFornc);
  }, [fornecedores]);

  const handleSelectFornecedor = (e) => {
    const value = e.target.value;
    const fornecedorSelecionado = newfornecedores.find((f) => f.id === parseInt(value));
    setFornecedor(fornecedorSelecionado);
  };

  return (
    <div className="patrimonio-consulta-contander">
      <div className="patrimonio-consulta-select-container">
        <label>Fornecedor:</label>
        <select
          className="patrimonio-consulta-select"
          onChange={handleSelectFornecedor}
        >
          <option value="">Selecione o fornecedor</option>
          {newfornecedores &&
            newfornecedores
              .slice()
              .sort((a, b) => a.nome.localeCompare(b.nome))
              .map((fornecedor, index) => (
                <option key={index} value={fornecedor.id}>
                  {fornecedor.nome}
                </option>
              ))}
        </select>
      </div>
      {fornecedor && <Marca fornecedor={fornecedor} setMarca={setMarca} />}
      {marca && <Modelo fornecedor={fornecedor} marca={marca} setModelo={setModelo} />}
    </div>
  );
};

export default MarcaModelo;
