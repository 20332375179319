import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { FornecedoresContext } from '../../../contexts/fornecedoresProvider'
import { dadosFornecedores, componentsMap } from '../../General/Format/fornecedores'
import './styles.css'

const Visualizar = ({ fornecedor, setFornecedor, closeModal }) => {
  const { estados } = useContext(ClientContext);
  const { updateFornecedor } = useContext(FornecedoresContext);
  const [editedData, setEditedData] = useState({});
  const [type, setType] = useState('Geral');
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    setEditedData(fornecedor)
  }, [])

  const fetchData = async (estado) => {
    try {
      const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao recuperar dados:', error);
    }
  };

  useEffect(() => {
    fetchData(editedData.estado).then(setMunicipios);
  }, [editedData.estado]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditClick = () => {
    setType('Edit');
    setEditedData(fornecedor);
  };

  const handleSaveClick = async () => {
    let fornecedor = { ...editedData }
    fornecedor.updated_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
    let newForn = await updateFornecedor(fornecedor);
    setFornecedor(newForn);
    setType('Geral');
  };

  const handleCancelClick = () => {
    setType('Geral');
  };

  const dados = dadosFornecedores(editedData)
  const components = componentsMap(closeModal, handleCancelClick, handleSaveClick, handleEditClick, handleInputChange)

  return (
    <div className='fornecedores-modal'>
      <div className='fornecedores-modal-body'>
        <div className='fornecedores-modal-header'>
          <h1>Fornecedor {fornecedor.nome}</h1>
        </div>
        <div className='fornecedores-modal-content'>
          {dados.map((dado, index) => {
            return (
              <div className='fornecedores-modal-content-item' key={index}>
                {components[type](dado)}
              </div>
            )
          })}

          {type === 'Edit' ? (
            <>
              <div className='fornecedores-modal-content-item'>
                <label>Estado</label>
                <select name='estado' onChange={handleInputChange}>
                  <option value=''>Selecione o estado</option>
                  {estados.map((estado, index) => (
                    <option key={index} value={estado.nome}>
                      {estado.nome}
                    </option>
                  ))}
                </select>

              </div>
              <div className='fornecedores-modal-content-item'>
                <label>Cidade</label>
                <select name='cidade' onChange={handleInputChange}>
                  <option value=''>Selecione a cidade</option>
                  {municipios.map((municipio, index) => (
                    <option key={index} value={municipio.nome}>
                      {municipio.nome}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : (null)}
        </div>
        <div className='fornecedores-edit-box'>
          {components[`buttons${type}`]()}
        </div>
      </div>
    </div>
  )
}

export default Visualizar
