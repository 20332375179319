import { createContext, useContext } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const RelatoriosContext = createContext();

export const RelatoriosProvider = ({ children }) => {
    const { setAlert } = useContext(UserContext);

    const patrimoniosCliente = async (id) => {
        try {
            const response = await apiUser.post("relatorios/patrimoniosByCliente", { id });
            const { success, message, dados } = response.data;
            if (success) {
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.error("Error fetching patrimony by client:", error);
        }
    }

    const finanByAnexo = async (id) => {
        try {
            const response = await apiUser.post("relatorios/finanByAnexo", { id });
            const { success, message, dados } = response.data;
            if (success) {
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.error("Error fetching finan by anexo:", error);
        }
    }

    const statusByInstalacao = async (id) => {
        try {
            const response = await apiUser.post("relatorios/statusInstalacao", { id });
            const { success, message, dados } = response.data;
            if (success) {
                return dados;
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.error("Error fetching status by instalacao:", error);
        }
    }

    return (
        <RelatoriosContext.Provider value={{
            patrimoniosCliente,
            finanByAnexo,
            statusByInstalacao,
        }}>
            {children}
        </RelatoriosContext.Provider>
    );
};