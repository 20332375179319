import { useState, useEffect, useContext } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { RelatoriosContext } from '../../../contexts/relatoriosProvider'
import LoadingSpinner from '../../Loader/load'
import DataTable from '../../Table'

const StatusInstalacao = () => {
  const { clientes } = useContext(ClientContext);
  const { statusByInstalacao } = useContext(RelatoriosContext);
  const [clients, setClients] = useState(null);
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState(null);

  useEffect(() => {
    let newClients = clientes ? clientes : [];
    setClients(newClients);
  }, [clientes]);

  const handleSelectClient = async (e) => {
    setLoading(true);
    const id = e.target.value;
    const nTitulo = clients.find((client) => client.id === parseInt(id)).nome;
    setTitulo(nTitulo);
    let nDados = await statusByInstalacao(id);
    if(nDados){
      nDados = nDados.map((dado) => {
        return {
          ...dado,
          updated_at: new Date(dado.updated_at).toLocaleDateString(),
        }
      })
      setDados(nDados);
    }
    setLoading(false);
  }

  const headers = [
    { label: "Anexo", key: "nome", sortable: true },
    { label: "Bairro", key: "bairro", sortable: false},
    { label: "Situação", key: "status_local", sortable: false},
    { label: "Observações", key: "observacoes_local", sortable: false},
    { label: "Data de Instalação", key: "updated_at", sortable: false},
    { label: "Total equipamentos", key: "quantidade_equipamentos", sortable: false}
  ]


  return (
    <>
      <select
        className="anexos-consulta-select"
        onChange={(e) => handleSelectClient(e)}
      >
        <option value="">Selecione o cliente</option>
        {clients &&
          clients
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((client, index) => (
              <option key={index} value={client.id}>
                {client.nome}
              </option>
            ))}
      </select>
      {loading && <LoadingSpinner />}

      {dados && <DataTable headers={headers} data={dados} itemsPerPage={5} exportButton={true} docTitle={`Status Instalação ${titulo}`} />}

    </>
  )
}

export default StatusInstalacao