import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts/userProvider";
import MarcaModelo from "./Types/marcaModelo";
import Grupos from "./Types/grupos";
import Cadastro from "./Types/cadastro";
import Consulta from "./Types/consulta";
import Historico from "./Types/historico";
import "./styles.css";

const Patrimonio = () => {
  const { user } = useContext(UserContext);
  const [type, setType] = useState("Consulta");

  const patrimonioType = {
    'Consulta': <Consulta />,
    'Cadastro': <Cadastro />,
    "Histórico Patrimônio": <Historico />,
    ...(user?.nivel === '1' || user?.nivel === '2' || !user?.nivel ? {} : {
      "Marca/Modelo": <MarcaModelo />,
      'Grupos': <Grupos />,
    }),
  };

  const patrimonioLabels = Object.keys(patrimonioType);

  const handleButtonClick = (selectedType) => setType(selectedType);

  useEffect(() => {
    if (!user) {
      window.location.href = "/login";
    }
  }, [user]);

  return (
    <div className="patrimonio-container">
      <div className="patrimonio-header">
        {patrimonioLabels.map((label) => (
          <button
            key={label}
            className="patrimonio-button"
            onClick={() => handleButtonClick(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="patrimonio-body">
        {patrimonioType[type]}
      </div>
    </div>
  );
};

export default Patrimonio;
