import { useEffect, useState } from 'react'
import Basico from './components/basico'
import Servicos from './components/servicos'
import Usuarios from './components/usuarios'
import LimitesUcms from './components/limtesUcms'
import './styles.css'

const Cadastro = ({ selectedOrg, isEdit, moveToConsulta }) => {
  const [type, setType] = useState('basico')
  const [newOrg, setNewOrg] = useState({})
  const [previousOrg, setPreviousOrg] = useState(null)

  useEffect(() => {
    if (selectedOrg) {
      setNewOrg(selectedOrg)
      setPreviousOrg(selectedOrg)
    }
  }, [selectedOrg])

  const views = {
    basico: <Basico newOrg={newOrg} setNewOrg={setNewOrg} setType={setType} previousOrg={previousOrg} isEdit={isEdit} />,
    'serviços': <Servicos newOrg={newOrg} setNewOrg={setNewOrg} previousOrg={previousOrg} isEdit={isEdit} setType={setType} />,
    'Limites/UCMS': <LimitesUcms newOrg={newOrg} setNewOrg={setNewOrg} setType={setType} previousOrg={previousOrg} isEdit={isEdit} moveToConsulta={moveToConsulta} />,
    'usuários': <Usuarios newOrg={newOrg} setNewOrg={setNewOrg} setType={setType} />
  }

  const buttons = ['basico', 'serviços', 'Limites/UCMS']
  if (isEdit) {
    buttons.push('usuários')
  }

  return (
    <div style={{ width: '90%', padding: 10, backgroundColor: '#f8f8f8' }}>
      <div className='softwares-header'>
        {
          buttons.map((item) => (
            <button
              key={item}
              className="softwares-button"
              onClick={() => setType(item)}
              style={
                type === item
                  ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                  : null
              }
            >
              {item === 'basico' ? 'Dados Básicos' : item.charAt(0).toUpperCase() + item.slice(1)}
            </button>
          ))
        }
      </div>
      <div className='cadastro-software-body'>{views[type]}</div>
    </div>
  )
}

export default Cadastro
