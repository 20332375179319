import { useState, useContext } from 'react'
import { PatrimonioContext } from '../../../contexts/patrimonioProvider'
import LabelInput from '../../General/FormsComp/Input/labelInput'
import DataTable from '../../Table'
import LoadingSpinner from '../../Loader/load'

const Historico = () => {
    const { searchPatrimonioByEtiquetaId } = useContext(PatrimonioContext)
    const [consultaData, setConsultaData] = useState({})
    const [historicos, setHistoricos] = useState([])
    const [loading, setLoading] = useState(false)

    const dados = {
        titulo: "Nº etiqueta patrimonio",
        placeholder: 'Nº patrimonio',
        name: "patrimonio",
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setConsultaData({ ...consultaData, [name]: value });
    }

    const handleSearchClick = async () => {
        setLoading(true)
        let nHistorico = await searchPatrimonioByEtiquetaId(consultaData.patrimonio)
        if (nHistorico?.length > 0) {
            let newDateHist = [...nHistorico]
            newDateHist.forEach((item) => {
                let date = new Date(item.created_at)
                date.setHours(date.getHours() - 3)
                item.created_at = date.toLocaleString('pt-BR', {
                    timeZone: 'UTC',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                }).replace(',', ' às');
            })            
            setHistoricos(nHistorico)
            setLoading(false)
        }
    }

    const headers = [
        { label: 'Data da última movimentação', key: 'created_at', sortable: true },
        { label: 'Nº patrimônio', key: historicos.some((etiqueta) => etiqueta?.patrimonio) ? 'patrimonio' : 'patrimonioNFS', sortable: false },
        { label: 'Equipamento', key: 'equipamento', sortable: false },
        { label: 'Nº Mac', key: 'nmac', sortable: false },
        { label: 'Nº Série', key: 'nserie', sortable: false },
        { label: 'Cliente', key: 'cliente', sortable: false },
        { label: 'Anexo', key: 'anexo', sortable: false },
    ]

    return (
        <>
            <div className="patrimonio-form-item">
                <LabelInput dados={dados} handleInputChange={handleInputChange} />
                <button className='patrimonio-cadastro-button' style={{ width: "100px" }} onClick={handleSearchClick}>Enviar</button>
            </div>
            {loading && <LoadingSpinner />}
            {historicos?.length > 0 && <DataTable headers={headers} data={historicos} itemsPerPage={5} />}
        </>
    )
}

export default Historico