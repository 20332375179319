import React, { useEffect, useState } from "react";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";

export default function TransferTable({
  equipamentos,
  equipamentosDoGrupo,
  leftTableData,
  setLeftTableData,
  rightTableData,
  setRightTableData,
}) {
  const [selectedItemsLeft, setSelectedItemsLeft] = useState([]);
  const [selectedItemsRight, setSelectedItemsRight] = useState([]);
  const [selectAllLeft, setSelectAllLeft] = useState(false);
  const [selectAllRight, setSelectAllRight] = useState(false);

  const [leftTablePage, setLeftTablePage] = useState(1);
  const [rightTablePage, setRightTablePage] = useState(1);
  const itemsPerPage = 5; 

  const [leftTableSearchTerm, setLeftTableSearchTerm] = useState("");
  const [rightTableSearchTerm, setRightTableSearchTerm] = useState("");

  const toggleSelect = (item, table) => {
    const setSelectedItems =
      table === "left" ? setSelectedItemsLeft : setSelectedItemsRight;
    const selectedItems =
      table === "left" ? selectedItemsLeft : selectedItemsRight;

    setSelectedItems((prevSelected) =>
      selectedItems.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };

  const moveItems = (fromTable, toTable, selectedItems) => {
    const removeFromTable = fromTable.filter(
      (item) => !selectedItems.includes(item)
    );
    const addToTable = toTable.concat(
      selectedItems.filter((item) => !toTable.includes(item))
    );

    fromTable === leftTableData
      ? setLeftTableData(removeFromTable)
      : setRightTableData(removeFromTable);
    toTable === leftTableData
      ? setLeftTableData(addToTable)
      : setRightTableData(addToTable);
  };

  const moveRight = () => {
    moveItems(leftTableData, rightTableData, selectedItemsLeft);
    setSelectedItemsLeft([]);
    setSelectAllLeft(false);
  };

  const moveLeft = () => {
    moveItems(rightTableData, leftTableData, selectedItemsRight);
    setSelectedItemsRight([]);
    setSelectAllRight(false);
  };

  const handleCheckboxChange = (table) => {
    const setSelectAll =
      table === "left" ? setSelectAllLeft : setSelectAllRight;
    const setSelectedItems =
      table === "left" ? setSelectedItemsLeft : setSelectedItemsRight;
    const tableData = table === "left" ? leftTableData : rightTableData;
    const selectAll = table === "left" ? selectAllLeft : selectAllRight;

    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : tableData || []);
  };

  const filterAndPaginateTable = (tableData, page, searchTerm) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const filteredData = tableData.filter((item) => {
      const itemName = String(item?.nome)?.toLowerCase();
      return itemName.includes(searchTerm?.toLowerCase());
    });

    return filteredData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (equipamentos && equipamentosDoGrupo) {
      const servicosRelacionados = equipamentos.filter((servico) =>
        equipamentosDoGrupo.some((prestado) => prestado?.nome === servico?.nome)
      );
      const servicosNaoRelacionados = equipamentos.filter(
        (servico) =>
          !equipamentosDoGrupo.some(
            (prestado) => prestado?.nome === servico?.nome
          )
      );

      setLeftTableData(
        filterAndPaginateTable(
          servicosNaoRelacionados,
          leftTablePage,
          leftTableSearchTerm
        )
      );
      setRightTableData(
        filterAndPaginateTable(
          servicosRelacionados,
          rightTablePage,
          rightTableSearchTerm
        )
      );
    }
  }, [equipamentos, equipamentosDoGrupo, leftTablePage, rightTablePage, leftTableSearchTerm, rightTableSearchTerm, setLeftTableData, setRightTableData]);

  const renderTable = (
    tableData,
    selectedItems,
    handleCheckboxChange,
    selectAll,
    page,
    setPage,
    searchTerm,
    setSearchTerm
  ) => (
    <div className="contander-table-transfer">
      <div className="search-container">
        <input
          type="text"
          placeholder="Pesquisar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
  
      <table className="transfer-table">
        <thead>
          <tr>
            <th className="th-transfer-checkbox">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() =>
                  handleCheckboxChange(
                    tableData === leftTableData ? "left" : "right"
                  )
                }
              />
            </th>
            <th className="th-title">
              {tableData === leftTableData
                ? "Selecione os equipamentos disponíveis"
                : "Equipamentos do grupo"}
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData?.length === 0 ? (
            <tr>
              <td className="td-red" colSpan="2">
                Nenhuma informação disponível!
              </td>
            </tr>
          ) : (
            tableData?.map((servico) => (
              <tr key={servico.id} className="tr-td">
                <td className="tr-td">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(servico)}
                    onChange={() =>
                      toggleSelect(
                        servico,
                        tableData === leftTableData ? "left" : "right"
                      )
                    }
                  />
                </td>
                <td className="title-table-transfer">{servico.nome}</td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2">
              <div className="pagination-container">
                <button
                  onClick={() => setPage(Math.max(1, page - 1))}
                  disabled={page === 1}
                >
                  <MdOutlineArrowBackIos size={15} />
                </button>
                <span>Página {page}</span>
                <button
                  onClick={() => setPage(page + 1)}
                  disabled={tableData.length < itemsPerPage}
                >
                  <MdOutlineArrowForwardIos size={15} />
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
  

  return (
    <div className="container-table-transfer">
      <div className="container-table-transfer-row">
        {renderTable(
          leftTableData,
          selectedItemsLeft,
          handleCheckboxChange,
          selectAllLeft,
          leftTablePage,
          setLeftTablePage,
          leftTableSearchTerm,
          setLeftTableSearchTerm
        )}

        <div className="container-button-actions">
          <button className="button-transfer" onClick={moveRight}>
            <MdOutlineArrowForwardIos size={15} />
          </button>
          <button className="button-transfer" onClick={moveLeft}>
            <MdOutlineArrowBackIos size={15} />
          </button>
        </div>

        {renderTable(
          rightTableData,
          selectedItemsRight,
          handleCheckboxChange,
          selectAllRight,
          rightTablePage,
          setRightTablePage,
          rightTableSearchTerm,
          setRightTableSearchTerm
        )}
      </div>
    </div>
  );
}
