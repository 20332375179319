import React from 'react'
import * as XLSX from 'xlsx';


const PrintToXLS = ({headers, data , docTitle}) => {

    const handlePrintXLS = () => {
        const ws = XLSX.utils.aoa_to_sheet([headers.map(header => header.label), ...data.map(item => headers.map(header => item[header.key]))]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${docTitle}.xlsx`);
    }
  return (
    <button
      className="patrimonio-cadastro-button"
      style={{ width: "100px" }}
      onClick={handlePrintXLS}
    >
      Gerar XLS
    </button>
  )
}

export default PrintToXLS