import { useContext } from 'react'
import { VoxSoftwaresContext } from '../../../../contexts/voxSoftwaresProvider';
import { getDadosLimites , getComponentMap } from '../../../General/Format/organizacao'
import AutoCompleteUcms from './autoCompleteUcms';


const LimitesUcms = ({newOrg, setNewOrg, setType, isEdit, moveToConsulta, previousOrg }) => {
  const { ucms, creatNewOrg, setAlert, updateOrg } = useContext(VoxSoftwaresContext);

  const handleInputChangeService = (e) => {
    const { name, value } = e.target    
    setNewOrg((prevData) => ({
      ...prevData,
      limites: {
        ...prevData.limites,
        [name]: value
      }
    }))
  }

  const handleNext = () => {
    if(isEdit){
      if(JSON.stringify(previousOrg) === JSON.stringify(newOrg)){
        setType('usuários')
        return
      }else{
        setAlert({
          visible: true,
          title: "Atenção!",
          placeholder: "Deseja salvar as alterações feitas?",
          confirm: true,
          type: "confirm",
          callback: () => updateOrg(newOrg),
          params: newOrg
        });
        setType('usuários')
        return
      }
    }
  }

  const dadosLimites = getDadosLimites(newOrg.limites)
  const componentMap = getComponentMap(handleInputChangeService)

  const handleSendOrg = async () => {
    let res = await creatNewOrg(newOrg)
    if(res.success){
      setAlert({ visible: true, title: "Sucesso", placeholder: "Organização cadastrada com sucesso.", confirm: false, type: "success" });
    }
    moveToConsulta()
  }

  return (
    <div style={{overflowY:'auto', maxHeight:'500px'}}>
    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <div className='softwares-box-forms' style={{width:'70%'}}>
        {dadosLimites.map((dado, index) => {
          return <div className='form-softwares' key={index}>{componentMap['Cadastro'](dado)}</div>
        })}
      </div>
    </div>

    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
      <h2 style={{color:'#142a4c'}}>UCMS</h2>
      <AutoCompleteUcms newOrg={newOrg} setNewOrg={setNewOrg} ucms={ucms} />
    </div>

      <button className='next-button' style={{width:'200px'}} onClick={isEdit ? handleNext : handleSendOrg }>Salvar</button>
    </div>
  )
}

export default LimitesUcms