import { useState, useEffect, useContext } from "react";
import { ClientContext } from "../../../../contexts/clientProvider";
import LoadingSpinner from "../../../Loader/load";
import DataTable from "../../../Table";
import EquipamentoEdit from "../Patrimonio/patrimonioEdit";
import { FaturadorContext } from "../../../../contexts/faturadorProvider";

const Patrimonios = () => {
  const { clientes, setIdCliente } = useContext(ClientContext);
  const { patrimonios, loading, clienteSelecionado, setClienteSelecionado } = useContext(FaturadorContext);
  const [newPats, setNewPats] = useState([]);
  const [patSel, setPatSel] = useState(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setClients(clientes || []);
  }, [clientes]);

  const editar = (id) => setPatSel(id);

  const closeEditModal = () => setPatSel(null);

  useEffect(() => {
    const seen = new Set();
    const filtered = patrimonios.filter(e => {
      const uniqueKey = `${e.modelo_id}-${e.marca_id}`;
      if (!seen.has(uniqueKey)) {
        seen.add(uniqueKey);
        return true;
      }
      return false;
    });
    setNewPats(filtered);
  }, [patrimonios]);

  const headers = [
    { label: "Marca", key: "marca_nome", sortable: false },
    { label: "Modelo", key: "modelo_nome", sortable: false },
    { label: "Valor", key: "valor_cobrado", sortable: true },
    { label: "Ações", key: "actions", sortable: false, actions: { "editar": (id) => editar(id) } },
  ];

  const handleSelectClient = (e) => {
    const id = e.target.value;
    const selectedClient = clients.find(client => client.id === parseInt(id));
    if (selectedClient) {
      setIdCliente(id);
      setClienteSelecionado(selectedClient.nome);
    }
  };

  return (
    <>
      <select className="anexos-consulta-select" onChange={handleSelectClient}>
        <option value="">{clienteSelecionado ?? "Selecione o cliente"}</option>
        {clients.slice().sort((a, b) => a.nome.localeCompare(b.nome)).map((client, index) => (
          <option key={index} value={client.id}>
            {client.nome}
          </option>
        ))}
      </select>
      {loading && <LoadingSpinner />}
      <div className="info-container">
        <span>Tipos de Aparelhos Contados: {newPats.length}</span>
      </div>
      <DataTable headers={headers} data={newPats} itemsPerPage={5} docTitle={`Dados gerais do cliente ${clienteSelecionado}`} />
      {patSel && <EquipamentoEdit patSel={patSel} closeEditModal={closeEditModal} />}
    </>
  );
};

export default Patrimonios;

