import { useEffect, useState, useContext } from 'react';
import { VoxSoftwaresContext } from '../../../../contexts/voxSoftwaresProvider';
import DataTable from '../../../Table/index';
import UserModal from '../../usuario/index';

const Usuarios = ({ newOrg, setNewOrg, setType }) => {
  const { deleteUser, setAlert } = useContext(VoxSoftwaresContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!newOrg.users) {
      setNewOrg((prevData) => ({ ...prevData, users: [] }));
    } else if (newOrg.users.length > 0) {
      let nUsers = [...newOrg.users];
      nUsers = nUsers.map((user) => {
        return {
          ...user,
          status: user.ativo ? 'Ativo' : 'Inativo',
        };
      });
      setUsers(nUsers);
    }
  }, [newOrg]);

  const deletarUsuario = (id) => {
    let nUser = users.find((user) => user._id === id);
    setAlert({
      visible: true,
      title: 'Confirmação',
      placeholder: `Deseja realmente deletar o usuário ${nUser.nome}?`,
      confirm: true,
      type: 'confirm',
      callback: () => deleteUser(nUser, newOrg),
      params: {nUser, newOrg}
    });
  };

  const editarUsuario = (id) => {
    let nUser = users.find(user => user._id === id);
    setSelectedUser(nUser);
    setIsModalOpen(true);
  };

  const addUsuario = () => {
    setSelectedUser(null);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const headers = [
    { label: 'Nome', key: 'nome', sortable: true },
    { label: 'Email', key: 'email', sortable: false },
    { label: 'Status', key: 'status' , sortable: false },
    { label: 'Ações', key: 'actions', sortable: false, actions: { 'editar': editarUsuario, 'deletar': deletarUsuario } }
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '80%' }}>
      <button className='next-button' onClick={addUsuario} style={{ width: '200px', marginTop: 30 }}>Adicionar Usuário</button>
      {users.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <DataTable headers={headers} data={users} itemsPerPage={5} checkboxColumn={false} />
        </div>
      )}
      {isModalOpen && (
        <UserModal selectedUser={selectedUser} closeModal={handleCloseModal} newOrg={newOrg} />
      )}
    </div>
  );
};

export default Usuarios;
