import { useContext, useEffect, useState } from "react";
import { ClientContext } from "../../../contexts/clientProvider";
import { UserContext } from "../../../contexts/userProvider";
import LoadingSpinner from "../../Loader/load";
import TransferTable from "./components/TransferTable";
import { BiX } from "react-icons/bi";
import "./styles.css";

const Servicos = ({ closeModal, clienteSelecionado }) => {
  const { getAllServices, getServicosPrestados, vincularServico } = useContext(ClientContext);
  const { user } = useContext(UserContext);
  const [servicos, setServicos] = useState([]);
  const [servicosPrestados, setServicosPrestados] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leftTableData, setLeftTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);

  const buscarServicosPrestados = async () => {
    try {
      const servicosPrestados = await getServicosPrestados(cliente.id);
      setServicosPrestados(servicosPrestados);
    } finally {
      setLoading(false);
    }
  };

  const buscarServicos = async () => {
    try {
      const servicos = await getAllServices();
      setServicos(servicos);
    } finally {
      setLoading(false);
    }
  };

  const salvarServicosPrestados = async () => {
    let newServicosPrestadosAdd = [];
    let newServicosPrestadosRemove = [];

    for (let i = 0; i < rightTableData.length; i++) {
      const servico = rightTableData[i];
      const existeNoServicosPrestados = servicosPrestados.some(
        (servicoPrestado) => servicoPrestado.idservico === servico.id
      );

      if (!existeNoServicosPrestados) {
        newServicosPrestadosAdd.push({
          idcliente: cliente.id,
          idservico: servico.id,
          created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
          updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
          id_usuario: user.id,
          usuario: user.nome,
        });
      }
    }

    for (let i = 0; i < servicosPrestados.length; i++) {
      const servicoPrestado = servicosPrestados[i];
      const existeNoRightTableData = rightTableData.some(
        (servico) => servico.id === servicoPrestado.idservico
      );

      if (!existeNoRightTableData) {
        newServicosPrestadosRemove.push(servicoPrestado);
      }
    }

    if (newServicosPrestadosAdd.length > 0 || newServicosPrestadosRemove.length > 0) {
      await vincularServico(newServicosPrestadosAdd, newServicosPrestadosRemove);
    }
    setRightTableData([]);
    closeModal();
  };

  useEffect(() => {
    buscarServicos();
  }, []);

  useEffect(() => {
    let newClient = clienteSelecionado ? clienteSelecionado : {};
    setCliente(newClient);
  }, [clienteSelecionado]);

  useEffect(() => {
    if (!cliente) return;
    setLoading(true);
    buscarServicosPrestados();
  }, [cliente]);

  return (
    <div className="container-modal">
      <div className="container-modal-body">
        <h1>Cadastro de Serviços Prestados</h1>
        <BiX onClick={() => closeModal()} className='fechar-top-button' />
        <div>
          NOME DA PREFEITURA : <strong>{cliente && cliente.nome}</strong>
        </div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <TransferTable
            servicos={servicos}
            servicosPrestados={servicosPrestados}
            leftTableData={leftTableData}
            setLeftTableData={setLeftTableData}
            rightTableData={rightTableData}
            setRightTableData={setRightTableData}
          />
        )}

        <div className="vizualizar-edit-box">
          <button
            className="vizualizar-modal-button"
            onClick={() => closeModal()}
          >
            Fechar
          </button>
          <button
            className="vizualizar-modal-button"
            onClick={() => salvarServicosPrestados()}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Servicos;
