import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import apiUser from "../apiUser";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    const [token, setToken] = useState(null);
    const [signed, setSigned] = useState(false);
    const [alert, setAlert] = useState({ visible: false, title: "", placeholder: "", confirm: false, type: "none" });

    const validateToken = async () => {
        let refreshToken = document.cookie.split("=")[1]
        let pathName = window.location.pathname;
        if (pathName.includes("openTable")) return;
        if(pathName.includes("senha")) return;
        if(pathName.includes("termos")) return;

        if (refreshToken && !signed) {
            try {
                apiUser.defaults.headers.common['authorization'] = refreshToken;
                const response = await apiUser.post("/validate-token");
                const { success, token, id, nome, nivel } = response.data;
                if (success) {
                    setSigned(true);
                    setToken(token);
                    setUser({ id, nome, nivel});
                    apiUser.defaults.headers.common['authorization'] = token;
                    navigate("/home");
                } else {
                    document.cookie = `RT=; path=/; max-age=0`;
                    setSigned(false);
                    navigate("/login");
                }
            } catch (error) {
                console.error("Error validating token:", error);
            }
        }
    };


    useEffect(() => {
        validateToken();
    }, []);

    const signIn = async (email, senha) => {
        try {
            const response = await apiUser.post("/login", { email, senha });
            const { success, message, token, id, nome , nivel } = response.data;
            if (success) {
                setSigned(true);
                setUser({ id, nome, nivel});
                setToken(token);
                apiUser.defaults.headers.common['authorization'] = token;
                document.cookie = `RT=${token}; path=/; max-age=86400`;
                navigate("/home");
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const signOut = () => {
        setSigned(false);
        setUser({});
        setToken(null);
        document.cookie = `RT=; path=/; max-age=0`;
        navigate("/login");
    };

    const esqueciSenha = async (email) => {
        try {
            const response = await apiUser.post("/esqueci-senha", { email });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const alterarSenha = async (email, senha, hash) => {
        try {
            const response = await apiUser.post("/redefinir-senha", { email, senha, hash });
            const { success, message } = response.data;
            if (success) {
                setAlert({ visible: true, title: "Sucesso!", placeholder: message, confirm: false, type: "success" });
                navigate("/login");
            } else {
                setAlert({ visible: true, title: "Atenção!", placeholder: message, confirm: false, type: "warning" });
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <UserContext.Provider value={{
            signed,
            user,
            alert,
            setAlert,
            token,
            signIn,
            signOut,
            esqueciSenha,
            alterarSenha,
        }}>
            {children}
        </UserContext.Provider>
    );
};