import { useContext, useState, useEffect } from 'react'
import { ClientContext } from '../../../contexts/clientProvider'
import { AnexosContext } from '../../../contexts/anexosProvider'
import { UserContext } from '../../../contexts/userProvider';
import { anexosLinhas, linhasComponentMap } from '../../General/Format/anexo';
import { BiTrash } from 'react-icons/bi';

const Cadastro = () => {
    const { clientes } = useContext(ClientContext);
    const { anexosByClient, createLinha } = useContext(AnexosContext);
    const { user, setAlert } = useContext(UserContext);
    const [clients, setClients] = useState(null);
    const [anexos, setAnexos] = useState(null);
    const [linhas, setLinhas] = useState([]);
    const [anexoData, setAnexoData] = useState({});
    useEffect(() => {
        let newClients = clientes ? clientes : [];
        setClients(newClients);
    }, [clientes]);

    async function handleSelectClient(e) {
        setAnexos(null)
        const idCliente = e.target.value
        const anexos = await anexosByClient(idCliente)
        setAnexoData({ id_cliente: parseInt(idCliente) })
        setAnexos(anexos)
    }

    async function handleSelectAnexo(e) {
        const idAnexo = e.target.value
        setAnexoData({ ...anexoData, id_anexo: parseInt(idAnexo) })
    }

    const handleInputChangeLinha = (e, index) => {
        const updatedLinhas = [...linhas];
        updatedLinhas[index] = { ...updatedLinhas[index], [e.target.name]: e.target.value };
        setLinhas(updatedLinhas);
    };

    const handleAddLinha = () => {
        const newLine = { ...anexoData, id_user: user.id, created_at: new Date().toISOString().slice(0, 19).replace('T', ' '), updated_at: new Date().toISOString().slice(0, 19).replace('T', ' ') };
        setLinhas([...linhas, newLine]);
    };

    const handleSaveClick = async () => {
        if (linhas.length === 0) {
            console.log('Adicione ao menos uma linha');
            setAlert({ visible: true, title: "Atenção!", placeholder: 'Adicione ao menos uma linha', confirm: false, type: "warning" })
            return
        }
        linhas.forEach(linha => {
            if(!linha.id_anexo || !linha.id_anexo || !linha.linha || !linha.status || !linha.operadora ) {
                setAlert({ visible: true, title: "Atenção!", placeholder: 'Preencha todos os campos obrigatórios.', confirm: false, type: "warning" });
                return;
            }
        })
        createLinha(linhas)
        setLinhas([])
        setAnexos(null)
        setAnexoData({})
        setClients(null)
    }

    const handleRemoveLinha = (index) => setLinhas([...linhas.slice(0, index), ...linhas.slice(index + 1)]);

    const componentMapLinhas = linhasComponentMap(handleInputChangeLinha);
    return (
        <div className='anexos-consulta-contander'>
            <select className='anexos-consulta-select' onChange={(e) => handleSelectClient(e)}>
                <option value=''>Selecione o cliente</option>
                {clients &&
                    clients
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((client, index) => (
                            <option key={index} value={client.id}>
                                {client.nome}
                            </option>
                        ))}
            </select>
            <select className='anexos-consulta-select' onChange={(e) => handleSelectAnexo(e)} disabled={anexos ? false : true}>
                <option value=''>Selecione o anexo</option>
                {anexos &&
                    anexos
                        .slice()
                        .sort((a, b) => a.nome.localeCompare(b.nome))
                        .map((anexo, index) => (
                            <option key={index} value={anexo.id}>
                                {anexo.nome}
                            </option>
                        ))}
            </select>
            {anexoData.id_cliente && (
                <>
                    <button className='anexos-cadastro-button' onClick={handleAddLinha}>Adicionar linha</button>
                    {linhas.map((linha, index) => (
                        <div className="form-linhas-wrapper" key={index}>
                            <div className='form-container-linhas'>
                                {anexosLinhas(linha).map((dado, subIndex) => (
                                    <div className='form-group-linhas' key={subIndex}>
                                        {componentMapLinhas['Linhas']({ ...dado, index })}
                                    </div>
                                ))}
                            </div>
                            <BiTrash className='form-linhas-icon' style={{ color: 'red' }} onClick={() => handleRemoveLinha(index)} />
                        </div>
                    ))}
                    {linhas.length > 0 && <button className='anexos-cadastro-button' onClick={handleSaveClick}>Salvar</button>}
                </>
            )}
        </div>
    )
}

export default Cadastro
