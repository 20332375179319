import React from 'react'

const Time = ({ dados, handleInputChange }) => {
    return (
        <>
            <label>{dados.titulo}</label>
            <input
                type="time"
                name={dados.name}
                value={dados.valor ? dados.valor : ''}
                onChange={handleInputChange}
            />
        </>
    )
}

export default Time
