import { useState, useContext, useEffect } from 'react';
import GeralCadastro from '../Cadastrar/geralCadastro';
import ComercialCadastro from '../Cadastrar/comercialCadastro';
import FinanceiroCadastro from '../Cadastrar/financeiroCadastro';
import TecnicaCadastro from '../Cadastrar/tecnicaCadastro';
import { UserContext } from '../../../contexts/userProvider';
import { ClientContext } from '../../../contexts/clientProvider';
import { cpf, cnpj } from 'cpf-cnpj-validator';

const Cadastro = () => {
  const { user, setAlert } = useContext(UserContext);
  const { createCliente } = useContext(ClientContext);
  const [type, setType] = useState('geral');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [newClient, setNewClient] = useState({});

  useEffect(() => {
    if (newClient.cpf_cnpj) {
      if (newClient.cpf_cnpj.length > 14 && cnpj.isValid(newClient.cpf_cnpj)) {
        setNewClient((prevData) => ({ ...prevData, cpf_cnpj: cnpj.format(newClient.cpf_cnpj) }));
      } else if (newClient.cpf_cnpj.length <= 14 && cpf.isValid(newClient.cpf_cnpj)) {
        setNewClient((prevData) => ({ ...prevData, cpf_cnpj: cpf.format(newClient.cpf_cnpj) }));
      }
    }
  }, [newClient.cpf_cnpj]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prevData) => ({ ...prevData, [name]: value }));
  }

  const clientTypes = [
    { key: 'geral', label: 'Geral', content: <GeralCadastro newClient={newClient} handleInputChange={handleInputChange} /> },
    { key: 'financeiro', label: 'Financeiro', content: <FinanceiroCadastro newClient={newClient} handleInputChange={handleInputChange} /> },
    { key: 'comercial', label: 'Comercial', content: <ComercialCadastro newClient={newClient} handleInputChange={handleInputChange} /> },
    { key: 'técnica', label: 'Técnica', content: <TecnicaCadastro newClient={newClient} handleInputChange={handleInputChange} /> },
  ];

  const handleNext = () => {
    if (currentIndex < clientTypes.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setType(clientTypes[currentIndex + 1].key);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSave = () => {
    if (!newClient.valor_contrato) {
      setAlert({
        type: 'warning',
        placeholder: 'Valor do contrato é obrigatório',
        visible: true,
        confirm: false,
        title: 'Atenção!'
      })
      return
    }

    Object.keys(newClient).forEach((key) => {
      if (key.toLowerCase().includes('email')) {
        const email = newClient[key];
        if (email && !isValidEmail(email)) {
          setAlert({
            type: 'warning',
            placeholder: `E-mail inválido no campo ${key}`,
            visible: true,
            confirm: false,
            title: 'Atenção!'
          });
          return;
        }
      }
    });

    if (!newClient?.nome || !newClient.cpf_cnpj) {
      setAlert({
        type: 'warning',
        placeholder: 'Nome e CPF/CNPJ são obrigatórios',
        visible: true,
        confirm: false,
        title: 'Atenção!'
      })
      return
    }
    newClient.ativo = true;
    newClient.id_usuario = user.id;
    newClient.usuario = user.nome;
    newClient.created_at = new Date()
    newClient.updated_at = new Date()
    createCliente(newClient);
  };


  return (
    <div className='cliente-consulta-contander' style={{gap:'20px', backgroundColor:'#f8f8f8', padding:'20px'}}>
      <div className='vizualizar-tabs'>
        {clientTypes.map(({ key, label }, index) => (
          <button
            key={key}
            className='vizualizar-tab-button'
            onClick={() => {
              setType(key);
              setCurrentIndex(index);
            }}
            style={
              currentIndex === index
                ? { borderBottom: '1px solid #9ac31c', color: '#9ac31c' }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      {clientTypes[currentIndex].content}
      <div className='button-container'>
        {currentIndex < clientTypes.length - 1 ? (
          <button className='vizualizar-modal-button' onClick={handleNext}>
            Próximo
          </button>
        ) : (
          <button className='vizualizar-modal-button' onClick={handleSave}>
            Salvar
          </button>
        )}
      </div>
    </div>
  );
};

export default Cadastro;
