import React, { useContext, useState, useEffect } from 'react';
import { DashContext } from '../../contexts/dashProvider';
import Charts from './chartsApex';
import Loader from '../Loader/load';
import './styles.css';

const Dashboard = () => {
  const { dashStatus, loading } = useContext(DashContext);
  const [clientes, setClientes] = useState(null);
  const [anexos, setAnexos] = useState(null);
  const [patrimonios, setPatrimonios] = useState(null);
  const [fornecedores, setFornecedores] = useState(null);

  useEffect(() => {
    if (!clientes && !anexos && !patrimonios && !fornecedores && dashStatus) {
      setClientes(dashStatus.clientes);
      setAnexos(dashStatus.anexos);
      setPatrimonios(dashStatus.patrimonios);
      setFornecedores(dashStatus.fornecedores);
    }
  }, [dashStatus, clientes, anexos, patrimonios, fornecedores]);

  return (
    <div className='dash-container'>
      {loading ? (
        <Loader />
      ) : (
        <div className='dash-content'>

          <div className='dash-item'>
            <div className="dash-card">
              <h3>Patrimônios</h3>
              {patrimonios?.totalPatrimonios > 0 ? (
                <Charts type='pie' data={patrimonios} total={patrimonios?.totalPatrimonios + patrimonios?.totalPatrimoniosnfs} id='patrimonios-chart'/>
              ) : (
                <p>Nenhum patrimonio cadastrado</p>
              )}
            </div>
          </div>
          <div className='dash-item'>

          <div className="dash-card">
              <h3>Anexos</h3>
              {anexos?.totalAnexos > 0 ? (
                <Charts type='pie' data={anexos} total={anexos.totalAnexos}id='anexos-chart'/>
              ) : (
                <p>Nenhum anexo cadastrado</p>
              )}
            </div>

          </div>

          <div className='dash-item'>
            <div className="dash-card">
              <h3>Fornecedores</h3>
              {fornecedores?.totalFornecedores > 0 ? (
                <Charts type='pie' data={fornecedores} total={fornecedores?.totalFornecedores} id='fornecedores-chart'/>
              ) : (
                <p>Nenhum fornecedor cadastrado</p>
              )}
            </div>

          </div>

          <div className='dash-item'>
            <div className="dash-card">
              <h3>Clientes</h3>
              {clientes?.totalClientes > 0 ? 
                (<Charts type='pie' data={clientes} total={clientes?.totalClientes} id='clientes-chart' />) : (<p>Nenhum cliente cadastrado</p>)
              }
            </div>
          </div>
        </div>

      )}
    </div>
  );
};

export default Dashboard;
