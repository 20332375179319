import { useState, useEffect, useContext } from "react";
import { PatrimonioContext } from "../../../contexts/patrimonioProvider";
import { UserContext } from "../../../contexts/userProvider";
import { BiTrash, BiPlus } from 'react-icons/bi';
import Cadastro from "./Types/cadastro";

const Marca = (props) => {
  const { marcasByFornecedor, deleteMarca } = useContext(PatrimonioContext);
  const { setAlert } = useContext(UserContext);
  const [newMarcas, setNewMarcas] = useState([]);
  const [cadastroModal, setCadastroModal] = useState(false);
  const [marcaSelecionada, setMarcaSelecionada] = useState(null);

  useEffect(() => {
    receberMarcas();
  }, [props.fornecedor]);

  const receberMarcas = async () => {
    const dados = await marcasByFornecedor(props.fornecedor.id);
    setNewMarcas(dados);
  };

  const handleSelectMarca = (e) => {
    const { value } = e.target;
    const newMarca = newMarcas.find((marca) => marca.id === parseInt(value));
    setMarcaSelecionada(newMarca);
    props.setMarca(newMarca);
  };

  const handleAddMarca = () => {
    setCadastroModal(true);
  };

  const handleDeleteMarca = () => {
    if (!marcaSelecionada) return setAlert({ type: 'warning', message: 'Selecione uma marca para deletar!' })
    setAlert({
      type: 'confirm',
      placeholder: `Realmente deseja deletar a marca ${marcaSelecionada.nome}?`,
      visible: true,
      confirm: true,
      title: 'Atenção!',
      callback: () => deleteMarca(marcaSelecionada.id),
      params: marcaSelecionada.id
    })
  }
  return (
    <div className="marcas-container">
      <div className="patrimonio-consulta-select-container">
        <label>Marca:</label>
        <select
          className="patrimonio-consulta-select"
          onChange={handleSelectMarca}
        >
          <option value="">Selecione a marca</option>
          {newMarcas &&
            newMarcas
              .slice()
              .sort((a, b) => a.nome.localeCompare(b.nome))
              .map((marca, index) => (
                <option key={index} value={marca.id}>
                  {marca.nome}
                </option>
              ))}
        </select>
      </div>

      <BiPlus size={24} onClick={handleAddMarca} title="Adicionar Marca" className="patrimonios-icons" />
      {/* Verificar a a ultilidade e a exclusão de marcas e modelos */}
      {/* <BiTrash size={24} onClick={handleDeleteMarca} title="Apagar Marca" className="patrimonios-icons" /> */}

      {cadastroModal && (
        <Cadastro
          fornecedor={props.fornecedor}
          receberMarcas={receberMarcas}
          setCadastroModal={setCadastroModal}
        />
      )}
    </div>
  );
};

export default Marca;
