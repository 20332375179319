import React from "react";
import { BiX } from "react-icons/bi";
import Cadastro from "../../cadastro";
import '../styles.css'

const Edit = ({ selectedOrg, closeModal }) => {

  const handleClickOutside = (e) => {
    if(e.target.classList.contains('softwares-modal')) {
        closeModal()
    }
  }

  return (
    <div className="softwares-modal" onClick={handleClickOutside}>
      <div className="softwares-modal-body" style={{backgroundColor:'white'}}>
        <BiX onClick={() => closeModal()} className='softwares-fechar-top-button' />
        <div className='softwares-modal-header'>
            <h1>{selectedOrg.nome}</h1>
        </div>
            <Cadastro selectedOrg={selectedOrg} isEdit={true} />
      </div>
    </div>
  );
};

export default Edit;
