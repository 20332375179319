import React from 'react'

const FinanceiroCadastro = ({ newClient, handleInputChange }) => {
    return (
        <div className='vizualizar-modal-content-cadastro'>
            <div className='vizualizar-modal-content-item-cadastro' >
                <h2>Responsável Financeiro</h2>
                <input type='text' name='responsavel_financeiro' value={newClient?.responsavel_financeiro} onChange={handleInputChange} />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Telefone Financeiro</h2>
                <input type='text' name='telefone_financeiro' value={newClient?.telefone_financeiro} onChange={handleInputChange} />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>E-mail de recebimento de NF</h2>
                <input type='text' name='email_responsalvel_financeiro' value={newClient?.email_responsalvel_financeiro} onChange={handleInputChange} />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2 style={{ color: 'red' }}>Valor do contrato</h2>
                <input type='number' name='valor_contrato' value={newClient?.valor_contrato} onChange={handleInputChange} />
            </div>
        </div>
    )
}

export default FinanceiroCadastro
