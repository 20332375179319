import { useState, useEffect, useContext } from 'react'
import { VoxSoftwaresContext } from '../../../contexts/voxSoftwaresProvider'
import Edit from './edit'
import DataTable from '../../Table'

const Consulta = () => {
  const { organizacoes, deleteOrg, setAlert } = useContext(VoxSoftwaresContext)
  const [newOrgs, setNewOrgs] = useState([])
  const [selectedOrg, setSelectedOrg] = useState(null)

  useEffect(() => {
    let nOrgs = organizacoes ? [...organizacoes] : []
    nOrgs = nOrgs.map(org => {
      let servicos = Object.keys(org.services).filter(servico => org.services[servico].ativo).join(', \n')
      return {
        ...org,
        servicos
      }
    })
    setNewOrgs(nOrgs)
  }, [organizacoes])

  useEffect(() => {
    if(selectedOrg){
      let nSel = newOrgs.find(org => org._id === selectedOrg._id)
      setSelectedOrg(nSel)
    }
  }, [newOrgs])

  const editarOrganizacao = (id) => {
    const nOrg = newOrgs.find(pref => pref._id === id)
    setSelectedOrg(nOrg)
  }

  const excluirOrganizacao = (id) => {
    setAlert({
      type: 'confirm',
      placeholder: 'Deseja realmente excluir a organização? Esta ação é irreversível.',
      visible: true,
      confirm: true,
      title: 'Atenção!',
      callback: () => deleteOrg(id),
      params: id
    })
  }


  const headers = [
    { label: "Nome", key: "nome", sortable: true },
    { label: "CNPJ", key: "cnpj", sortable: false },
    { label: "Serviços", key:'servicos' , sortable: false},
    { label: "Ações", key: "actions", sortable: false, actions: { "editar": editarOrganizacao, "deletar": excluirOrganizacao }},
  ]

  const closeModal = () => {
    setSelectedOrg(null)
  }

  return (
    <div style={{width:'100%'}}>
      <DataTable
        headers={headers}
        data={newOrgs}
        itemsPerPage={5}
        checkboxColumn={false}
      />
      {selectedOrg && <Edit selectedOrg={selectedOrg} closeModal={closeModal} />}
    </div>
  )
}

export default Consulta
