import React from 'react'

const Title = ({titulo}) => {

    return (
       <h2>{titulo}</h2>
    )
}

export default Title
