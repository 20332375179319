import { useState, useEffect, useContext } from "react";
import { ClientContext } from "../../../contexts/clientProvider";
import { EquipamentosContext } from "../../../contexts/equipamentosProvider";
import { BiX } from "react-icons/bi";
import ProgressCards from "./progressCard";
import EquipamentoTable from "./equipamentosTable";
import LoadingSpinner from "../../Loader/load";
import "./styles.css";

const Equipamentos = ({ cliente, closeModal }) => {
  const { clientes } = useContext(ClientContext);
  const { getEquipamentosByClient, equipamentos, loading, setEquipamentos } = useContext(EquipamentosContext);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);


  useEffect(() => {
    let client = clientes.find((client) => client.id === cliente);
    setClienteSelecionado(client);
    setEquipamentos(null);
    getEquipamentosByClient(client.id);
  }, [cliente]);

  return (
    <div className="container-modal-equipamentos">
      <div className="container-modal-content-equipamentos">
        <div className="container-modal-header-equipamentos">
          <h1>Equipamentos - {clienteSelecionado && clienteSelecionado.nome}</h1>
          <BiX onClick={() => closeModal()} className="fechar-top-button" />
        </div>
        <div className="container-modal-body-equipamentos">
          <ProgressCards />
          {!equipamentos && loading ? (<LoadingSpinner />) : <EquipamentoTable dados={equipamentos} cliente={clienteSelecionado} />}
        </div>
      </div>
    </div>
  );
};

export default Equipamentos;
