import FormComp from "../FormsComp";

export const editFaturador = (pat) => {
    return [
        {
            titulo: 'Marca',
            valor: pat?.marca_nome || 'Nenhum dado cadastrado.',
            name: 'marca_nome',
            tipo: 'Dashcard',
            options: [{ id: pat.id, nome: pat?.marca_nome  }],
            value:'nome',
        },
        {
            titulo: 'Modelo',
            valor: pat?.modelo_nome || 'Nenhum dado cadastrado.',
            name: 'modelo_nome',
            tipo: 'Dashcard',
            options: [{ id: pat.id, nome: pat?.modelo_nome }],
            value:'nome',
        },
        {
            titulo: 'Valor',
            valor:  pat?.valor_cobrado,
            name: 'valor_cobrado',
            tipo: 'NumberInput',
        }
    ]
}

export const faturadorDateFilter = (dados) => {
    return [
        {
            titulo: 'Data Inicial',
            valor: dados.dataInicio,
            name: 'dataInicio',
            tipo: 'Calendar',
        },
        {
            titulo: 'Data Final',
            valor: dados.dataFim,
            name: 'dataFim',
            tipo: 'Calendar',
        }
    ]
}


export const editFaturadorMap = (handleInputChange, handleSaveClick, closeEditModal) => {
    return {
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='edit-modal-button' onClick={() => closeEditModal()}>Cancelar</button>
                <button className='edit-modal-button' onClick={() => handleSaveClick()}>Salvar</button>
            </>
        ),
    }
}

export const faturadorDateFilterMap = (handleInputChange, handleSaveClick, closeEditModal) => {
    return {
        Edit: (dado) => <FormComp dados={dado} handleInputChange={handleInputChange} />,
        buttonsEdit: () => (
            <>
                <button className='edit-modal-button' onClick={() => closeEditModal()}>Cancelar</button>
                <button className='edit-modal-button' onClick={() => handleSaveClick()}>Buscar</button>
            </>
        ),
    }
}