import { createContext, useEffect, useState, useContext } from "react";
import ApiUser from "../apiUser";
import { ClientContext } from "./clientProvider";

export const FaturadorContext = createContext();

export const FaturadorProvider = ({ children }) => {
  const { idCliente } = useContext(ClientContext);
  const [patrimonios, setPatrimonios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [clienteSelecionado, setClienteSelecionado] = useState(null);
  const [secretarias, setSecretarias] = useState([]);
  const [currSec, setCurrSec] = useState([]);
  const [filterDates, setFilterDates] = useState({ dataInicio: null, dataFim: null, checkUpdate: false });
  let idCounter = 1;

  useEffect(() => {
    if (idCliente) {
      fetchAndProcessEquipamentos(idCliente);
    }
  }, [idCliente]);

  useEffect(() => {
    if(filterDates.dataInicio && filterDates.dataFim){
      fetchAndProcessEquipamentos(idCliente);
    }
  }, [filterDates]);

  const fetchAndProcessEquipamentos = async (id) => {
    setLoading(true);
    try {
      const pats = await fetchPats(id);
      const filteredPats = filterAndFormatPats(pats);
      setPatrimonios(filteredPats);
      setTotal(calculateTotal(filteredPats));
    } catch (error) {
      console.error('Erro ao buscar e processar equipamentos:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSecs = async () => {
    const resp = await ApiUser.post("/secretarias/buscar");
    setSecretarias(resp?.data);
  }

  useEffect(() => {
      fetchSecs()
  }, [])

  useEffect(() => {
    if (patrimonios.length > 0) {
      const sec = secretarias?.find(s => s.erpID === idCliente)
      if (sec) {
        let idCounter = 1;
        const updatedSec = sec.secretarias.map((sec) => ({
          ...sec,
          id: idCounter++
        }));
        setCurrSec(updatedSec);  
      } else {
        setCurrSec([]);
      }
    }
  }, [patrimonios])

const fetchPats = async (id) => {
  const params = {
    dataInicio: filterDates.dataInicio || null,
    dataFim: filterDates.dataFim || null,
    checkUpdate: filterDates.checkUpdate || false
  };
  
  const [respPatrimonios, respNfs] = await Promise.all([
    ApiUser.get(`/patrimonios/${id}`, { params }),
    ApiUser.get(`/patrimonios/nfs/${id}`, { params })
  ]);
  return [...respPatrimonios.data, ...respNfs.data];
};

  const filterAndFormatPats = (pats) => {
    const seen = new Set();
    return pats.filter(e => {
      if (!seen.has(e.modelo_id)) {
        return true;
      }
      return false;
    }).map(formatPatrimonio);
  };

  const formatPatrimonio = (e) => ({
    ...e,
    id: idCounter++,
    marca_id: e.marca_id || '',
    valor_cobrado: formatCurrency(e.valor_cobrado) ?? 'R$ 0.00',
    modelo_id: e.modelo_id || '',
    marca_nome: e.marca_nome || '',
    modelo_nome: e.modelo_nome || '',
    netiqueta: e.netiqueta || '',
    nmac: e.nmac || '',
    nome_do_anexo: e.nome_do_anexo || '',
    nserie: e.nserie || '',
    pat_nome: e.pat_nome || '',
    produto: e.produto || '',
    setor: e.setor || ''
  });

  const formatCurrency = (valor) => {
    const parsedValue = parseFloat(valor);
    return !isNaN(parsedValue) ? toBrl(parsedValue) : null;
  };

  const toBrl = (valor) => 'R$ ' + parseFloat(valor).toFixed(2).toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const calculateTotal = (pats) => {
    return pats.reduce((acc, cur) => {
      const value = parseFloat(cur.valor_cobrado.replace('R$ ', '').replace('.', '').replace(',', '.'));
      return acc + (isNaN(value) ? 0 : value);
    }, 0);
  };

  const updatePat = (pat) => {
    let newPatrimonios = patrimonios.map((patrimonio) => {
      // Atualiza o patrimônio que corresponde ao ID
      // if (patrimonio.id === pat.id) {
      //   return pat;
      // }
      // Atualiza todos os patrimônios com o mesmo modelo_id
      if (patrimonio.modelo_id === pat.modelo_id && patrimonio.marca_id === pat.marca_id) {
        return { ...patrimonio, valor_cobrado: pat.valor_cobrado }; 
      }
      return patrimonio;
    });
  
    setPatrimonios(newPatrimonios);
    const updatedTotalVal = calculateTotal(newPatrimonios);
    setTotal(updatedTotalVal);
  };

  const saveValues = async (modelId, marcaId, valor) => {
    const body = {
      modelo: modelId,
      valor: valor, 
      marca: marcaId,
      cliente: idCliente,
    };
    setLoading(true);
    try {
      await ApiUser.post('/valores/salvar', body);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.response?.data || err.message);
    }
  };

  return (
    <FaturadorContext.Provider 
    value={{ 
        patrimonios, 
        loading, setLoading, 
        total, 
        updatePat,
        toBrl,
        saveValues,
        calculateTotal,
        clienteSelecionado, setClienteSelecionado,
        secretarias,
        currSec, setCurrSec,
        filterDates, setFilterDates,
    }}>
      {children}
    </FaturadorContext.Provider>
  );
};
