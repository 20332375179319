import React from "react";

const ProgressCards = () => {
  return (
    <div className="progress-container">
      <div className="progress-card">
        <div className="progress-card-header">
          <h1>CONCLUÍDO</h1>
        </div>
        <div className="progress-bar" style={{background: '#38c172'}}>
        </div>
      </div>
      <div className="progress-card">
        <div className="progress-card-header">
          <h1>DISPONÍVEL</h1>
        </div>
        <div className="progress-bar" style={{background: '#3490dc'}}>
        </div>
      </div>
      <div className="progress-card">
        <div className="progress-card-header">
          <h1>EXCEDIDO</h1>
        </div>
        <div className="progress-bar" style={{background: '#e3342f'}}>
        </div>
      </div>
    </div>
  );
};

export default ProgressCards;
