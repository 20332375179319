import { useEffect } from 'react';
import Select from 'react-select';

const AutoCompleteUcms = ({ newOrg, setNewOrg, ucms }) => {

  useEffect(() => {
    if(!newOrg.ucms){
        setNewOrg((prevData) => ({ ...prevData, ucms: [] }));
    }
  }, []);

  const options = ucms.map(ucm => ({
    value: ucm._id,
    label: `${ucm.cidade} - ${ucm.local}`,
  }));

  const selectAllOption = { value: 0, label: 'Selecionar Todas' };

  const handleChange = selectedOptions => {
    if (selectedOptions.some(option => option.value === 0)) {
      if (newOrg.ucms.length === ucms.length) {
        setNewOrg((prevData) => ({ ...prevData, ucms: [] }));
      } else {
        setNewOrg((prevData) => ({
            ...prevData,
            ucms: ucms.map(ucm => ucm._id),
        }));
      }
    } else {
        setNewOrg((prevData) => ({
            ...prevData,
            ucms: selectedOptions.map(option => option.value),
        }));
    }
  };

  return (
      <Select
        isMulti
        options={[selectAllOption, ...options]}
        value={
          newOrg.ucms
            ? newOrg.ucms.map(ucm => ({
                value: ucm,
                label: ucms.find(u => u._id === ucm)?.cidade,
              }))
            : []
        }
        onChange={handleChange}
        closeMenuOnSelect={false}
        placeholder='Escolha as UCMS de acesso'
        noOptionsMessage={() => 'Essa Ucm não existe'}
        styles={{
          control: (styles) => ({ ...styles, minWidth: '300px', margin: '10px 0', maxWidth: '90%' }),
        }}
      />
  );
};

export default AutoCompleteUcms;
