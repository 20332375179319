import { useState, useContext } from "react";
import { UserContext } from "../../contexts/userProvider";
import PatrimonioCliente from "./Types/patrimonioCliente";
import StatusInstalacao from "./Types/statusInstalacao";
import FaturamentoAnexo from "./Types/faturamentoAnexo";
import "./styles.css";

const Relatorios = () => {
  const { user } = useContext(UserContext);
  const [type, setType] = useState("Patrimônio por cliente");

  const relatoriosType = {
    "Patrimônio por cliente": <PatrimonioCliente />,
    "Status da instalação": <StatusInstalacao />,
    ...(user?.nivel === '1' || user?.nivel === '2' || !user?.nivel ? {} : {
      "Faturamento por anexo": <FaturamentoAnexo />,
    }),
  };

  const relatoriosLabels = Object.keys(relatoriosType);

  return (
    <div className="relatorios-contander">
      <div className="relatorios-header">
        {relatoriosLabels.map((label) => (
          <button
            key={label}
            className="relatorios-button"
            onClick={() => setType(label)}
            style={
              type === label
                ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
                : null
            }
          >
            {label}
          </button>
        ))}
      </div>
      <div className="relatorios-body">{relatoriosType[type]}</div>
    </div>
  );
};

export default Relatorios;
