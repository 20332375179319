import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../Loader/load";
import "./styles.css";

const OpenTable = () => {
  const { hash } = useParams();
  const [dataTable, setDataTable] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [totalEquipments, setTotalEquipments] = useState(null);
  const [orderbyAnexo, setOrderbyAnexo] = useState("asc");
  const [orderbyRamal, setOrderbyRamal] = useState("asc");

  const sendEncryptedHash = async (hash) => {
    try {
      const response = await fetch("https://api-erp.voxcity.com.br/openTable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hash: encodeURIComponent(hash) }),
      });
      const data = await response.json();
      const { success, message, dados, nome, total } = data;
      if (success) {
        setDataTable(dados);
        setClientName(nome);
        setTotalEquipments(total);
      } else {
        console.error("Erro na requisição:", message);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  useEffect(() => {
    if (!hash) return;
    sendEncryptedHash(hash);
  }, [hash]);

  const sortbyAnexo = () => {
    if (orderbyAnexo === "asc") {
      setDataTable(
        dataTable.sort((a, b) => a?.nome_anexo?.localeCompare(b?.nome_anexo))
      );
      setOrderbyAnexo("desc");
    } else {
      setDataTable(
        dataTable.sort((a, b) => b?.nome_anexo?.localeCompare(a?.nome_anexo))
      );
      setOrderbyAnexo("asc");
    }
  };

  const sortbyRamal = () => {
    if (orderbyRamal === "asc") {
      setDataTable(dataTable.sort((a, b) => a?.ramal?.localeCompare(b?.ramal)));
      setOrderbyRamal("desc");
    } else {
      setDataTable(dataTable.sort((a, b) => b?.ramal?.localeCompare(a?.ramal)));
      setOrderbyRamal("asc");
    }
  };

  return (
    <div className="open-table-container">
      <p className="open-table-info">Cliente: {clientName}</p>
      <p className="open-table-info">
        Total de equipamentos: {totalEquipments}
      </p>
      {dataTable && dataTable.length > 0 ? (
        <table className="open-table">
          <thead>
            <tr>
              <th>#</th>
              <th onClick={sortbyAnexo}>Anexo {orderbyAnexo === "asc" ? "▲" : "▼"}</th>
              <th>Modelo</th>
              <th>Patrimônio</th>
              <th>Nº Série</th>
              <th>Nº Mac</th>
              <th>Nome</th>
              <th>Setor</th>
              <th onClick={sortbyRamal}>Ramal {orderbyRamal === "asc" ? "▲" : "▼"}</th>
              <th>IP</th>
            </tr>
          </thead>

          <tbody>
            {dataTable && dataTable.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.nome_anexo}</td>
                <td>{data.nome_modelo}</td>
                <td>{data.netiqueta || data.nEtiqueta}</td>
                <td>{data.nserie || data.nSerie}</td>
                <td>{data.nmac || data.nMac}</td>
                <td>{data.nome}</td>
                <td>{data.setor}</td>
                <td>{data.ramal}</td>
                <td>{data.ip_estatico}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default OpenTable;
