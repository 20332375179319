import { useState, useContext } from 'react'
import { IoMenuOutline } from "react-icons/io5";
import { ClientContext } from '../../../contexts/clientProvider';
import { UserContext } from '../../../contexts/userProvider';
import Normal from './types/normal';
import Group from './types/group';
import { BiArrowBack } from 'react-icons/bi'
import Logo from '../../../assets/images/VOX  Branca e Verde Horizontal.png'
import './styles.css'

const SideBar = ({ handleSideBar, sideBar, setSelectedItem, components, selectedItem, handleItemClick }) => {
    const { setAlert } = useContext(UserContext);
    const { clientes } = useContext(ClientContext);
    const [expandedItems, setExpandedItems] = useState([]);

    const toggleExpand = (item) => {
        if (expandedItems.includes(item)) {
          setExpandedItems(expandedItems.filter((i) => i !== item));
        } else {
          setExpandedItems([...expandedItems, item]); 
        }
    };
    
    const handleLinkClick = (item) => {
        if(!clientes){
            setAlert({ visible: true, title: "Atenção!", placeholder: "Aguarde o carregamento dos clientes.", confirm: false, type: "warning" })
            return;
        }
        handleItemClick(item);
        setSelectedItem(item);
        if (item.links && item.links.length > 0) {
            toggleExpand(item.name); 
        } 
    }

    return (
        <div className={`sidebar-container-${sideBar ? 'active' : 'normal'}`}>
            <div className={`sidebar-header-${sideBar ? 'open':'close'}`}>
                {
                    sideBar ? <img src={Logo} alt='logo' className='logo' /> : null
                }
                {
                    sideBar ? <BiArrowBack className='sidebar-icon' onClick={handleSideBar} title='Fechar menu.' /> : <IoMenuOutline size={30} className='sidebar-icon' onClick={handleSideBar} title='Abrir menu' />
                }
            </div>
            <div className='sidebar-content'>

                <ul className='sidebar-list' >
                    {
                        components.map((item, index) => {
                            return (
                                <li className={`li-item ${selectedItem === item.name ? 'selected' : ''}`} key={index}>
                                {item.isGroup ? (
    
                                    <Group 
                                        selectedItem={selectedItem}
                                        title={item.name}
                                        sideBar={sideBar}
                                        links={item.links}  
                                        handleLinkClick={handleLinkClick} 
                                    />
                                ) : (
                                    <Normal 
                                        title={item.name}
                                        sideBar={sideBar}
                                        handleLinkClick={() => handleLinkClick(item.name)} 
                                    />
                                )}
                            </li>
                            )
                        })
                    }

                    <li>
                        <Normal title='Sair' sideBar={sideBar} />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default SideBar
