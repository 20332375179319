import { useState, useEffect, useContext } from 'react';
import { ClientContext } from '../../../../contexts/clientProvider';
import LoadingSpinner from '../../../Loader/load';
import DataTable from '../../../Table';
import { FaturadorContext } from '../../../../contexts/faturadorProvider';
import FilterDate from '../filterDate';
import { BiX } from "react-icons/bi";

const Anexos = () => {
  const { clientes, setIdCliente } = useContext(ClientContext);
  const { patrimonios, loading, total, toBrl, clienteSelecionado, setClienteSelecionado, filterDates, setFilterDates } = useContext(FaturadorContext);
  const [clients, setClients] = useState([]);
  const [groupedPatrimonios, setGroupedPatrimonios] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [checkUpdate, setCheckUpdate] = useState(false);

  const headers = [
    { label: "Anexo", key: "nome_do_anexo", sortable: false },
    { label: "Valor", key: "valor_cobrado", sortable: true },
  ];

  useEffect(() => {
    if (patrimonios && patrimonios.length > 0) {
      let newPats = patrimonios.map((pat) => {
        if(!pat.produto){
          pat.produto = `${pat.marca_nome} ${pat.modelo_nome}`;
        }
        if(pat.data_implantacao){
          pat.data_implantacao = formatDate(pat.data_implantacao.split('T')[0]);
          return pat;
        }
        return pat;
      });
      const grouped = newPats.reduce((acc, i) => {
        const valorCobr = parseFloat(i.valor_cobrado.replace("R$", "").replace(".", "").replace(",", ".").trim());
        if (!acc[i.nome_do_anexo]) {
          acc[i.nome_do_anexo] = {
            nome_do_anexo: i.nome_do_anexo,
            valor_cobrado: valorCobr,
          };
        } else {
          acc[i.nome_do_anexo].valor_cobrado += valorCobr;
        }
        return acc;
      }, {});
      const groupedArray = Object.values(grouped).map(group => ({
        ...group,
        valor_cobrado: toBrl(group.valor_cobrado), 
      }));
      setGroupedPatrimonios(groupedArray);
    }
  }, [patrimonios]);
  

  useEffect(() => {
    setClients(clientes ? clientes : []);
  }, [clientes]);

  const handleSelectClient = (e) => {
    const id = e.target.value;
    const selectedClient = clients.find((client) => client.id === parseInt(id));

    if (selectedClient) {
      setIdCliente(id);
      setClienteSelecionado(selectedClient.nome);
    }
    
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const eraseFilter = () => {
    setFilterDates({ dataInicio: null, dataFim: null, checkUpdate: false });
    const client = clientes.find((client) => client.nome === clienteSelecionado);
    setIdCliente(client.id);
  }

  const handleDateModal = () => {
    setFilterModal(!filterModal);
  };

  return (
    <>
      <select className="anexos-consulta-select" onChange={(e) => handleSelectClient(e)}>
      <option value="">{clienteSelecionado ?? "Selecione o cliente"}</option>
        {clients &&
          clients
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((client, index) => (
              <option key={index} value={client.id}>
                {client.nome}
              </option>
            ))}
      </select>
      {
        clienteSelecionado && (
          <>
            {!filterDates.dataInicio && !filterDates.dataFim ? (
              <button className='anexos-cadastro-button' onClick={() => setFilterModal(true)}>Filtrar por data de implantação</button>
            ) : (
              <div className="info-container" style={{ marginBottom: '10px' }}>
                <span>
                  Data Inicial: {formatDate(filterDates.dataInicio)}
                </span>
                <span>
                  Data Final: {formatDate(filterDates.dataFim)}
                </span>
                <span>
                  <BiX onClick={eraseFilter} />
                </span>
              </div>
            )}
          </>
        )
      }
      <div className="info-container">
        <span>
          Número de Aparelhos Contados: {patrimonios.length}
        </span>
        <span>
          Valor Total de Aparelhos: {toBrl(total)}
        </span>
      </div>
      
      {loading && <LoadingSpinner />}
      {groupedPatrimonios && <DataTable headers={headers} data={groupedPatrimonios} itemsPerPage={5} exportButton={true} docTitle={`Anexos do cliente ${clienteSelecionado}`} />}
      {filterModal && <FilterDate filterDates={filterDates} setFilterDates={setFilterDates} closeModal={handleDateModal} checkUpdate={checkUpdate} setCheckUpdate={setCheckUpdate} />}
    </>
  );
};

export default Anexos;
