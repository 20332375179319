import { useState, useEffect, useContext } from 'react'
import { AnexosContext } from '../../../contexts/anexosProvider'
import { UserContext } from '../../../contexts/userProvider'
import DataTable from '../../Table'
import LoadingSpinner from '../../Loader/load'
import LinhasEdit from './linhasEdit'
import { BiX } from "react-icons/bi";
import './styles.css'

const Linhas = ({ closeModal, anexo }) => {
    const { linhasByAnexo, deleteLinhaById } = useContext(AnexosContext)
    const { setAlert } = useContext(UserContext)
    const [linhas, setLinhas] = useState(null)
    const [linhaSelecionada, setLinhaSelecionada] = useState(null)

    useEffect(() => {
        getLinhas()
    }, [anexo])

    async function getLinhas() {
        const linhas = await linhasByAnexo(anexo.id)
        setLinhas(linhas)
    }

    const vizualizar = (linha) => {
        const linhaSelecionada = linhas.find(l => l.id === linha)
        setLinhaSelecionada(linhaSelecionada)
    }

    const deletar = (linha) => {
        if (!linha) return console.error('ID não informado');
        setAlert({
            type: 'confirm',
            placeholder: 'Deseja realmente deletar a linha?',
            visible: true,
            confirm: true,
            title: 'Atenção!',
            callback: () => deleteLinhaById(linha),
            params: linha
        })
        getLinhas()
    }



    const headers = [
        { label: "Linha", key: "linha", sortable: true },
        { label: "Operadora", key: "operadora", sortable: false },
        { label: "Status", key: "status", sortable: false },
        { label: "Status Atual", key: "status_atual", sortable: false },
        { label: "Ações", key: "actions", sortable: false, actions: { "vizualizar": vizualizar, "deletar": deletar } },
    ]

    const closeEditModal = () => {
        setLinhaSelecionada(null)
        getLinhas()
    }


    return (
        <div className='vizualizar-modal'>
            <div className='vizualizar-modal-body'>
                <BiX onClick={() => closeModal()} className='fechar-top-button' />
                <div className='vizualizar-modal-header'>
                    <h1>Linhas referente ao anexo {anexo?.nome}</h1>
                </div>
                {linhas ? <DataTable headers={headers} data={linhas} itemsPerPage={10} /> : <LoadingSpinner />}
                {linhaSelecionada && <LinhasEdit closeEditModal={closeEditModal} linha={linhaSelecionada} />}
                <div className='vizualizar-edit-box'>
                    <button className='anexos-cadastro-button' onClick={closeModal}>Fechar</button>
                </div>
            </div>
        </div>

    )
}

export default Linhas
