import React from 'react'
import Title from './title'
import Value from './value'

const Dashcard = ({titulo,valor}) => {
  return (
    <>
        <Title titulo={titulo}/>
        <Value valor={valor}/>
    </>
  )
}

export default Dashcard
