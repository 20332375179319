import { useContext } from "react";
import FormComp from "../FormsComp";
import { FornecedoresContext } from "../../../contexts/fornecedoresProvider";
import { ClientContext } from "../../../contexts/clientProvider";

export const GetDadosPatrimonio = (patrimonio, anexos, marcas, modelos) => {
  const { fornecedores } = useContext(FornecedoresContext);
  const { clientes } = useContext(ClientContext);
  return [
    {
      titulo: "Cliente",
      valor: patrimonio?.id_cliente,
      name: "id_cliente",
      tipo: "Select",
      options: clientes.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Anexo",
      valor: patrimonio?.id_anexo,
      name: "id_anexo",
      tipo: "Select",
      options: anexos.sort((a, b) => a.nome?.localeCompare(b.nome)),
    },
    {
      titulo: "Fornecedor",
      valor: patrimonio?.id_fornecedor,
      name: "id_fornecedor",
      tipo: "Select",
      options: fornecedores.sort((a, b) => a.nome?.localeCompare(b.nome)),
    },
    {
      titulo: "Marca",
      valor: patrimonio?.id_marca,
      name: "id_marca",
      tipo: "Select",
      options: marcas.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Modelo",
      valor: patrimonio?.id_modelo,
      name: "id_modelo",
      tipo: "Select",
      options: modelos.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Número de Série",
      valor: patrimonio?.nSerie,
      name: "nSerie",
      tipo: "Input",
    },
    {
      titulo: "MAC",
      valor: patrimonio?.nMac,
      name: "nMac",
      tipo: "Input",
    },
    {
      titulo: "Nº etiqueta patrimonio",
      valor: patrimonio?.nEtiqueta,
      name: "nEtiqueta",
      tipo: "Input",
    },
  ];
};

export const DadosConsultaPatrimonio = (patrimonio, anexos) => {
  const { clientes } = useContext(ClientContext);
  return [
    {
      titulo: "Cliente",
      valor: patrimonio?.id_cliente,
      name: "id_cliente",
      tipo: "Select",
      options: clientes?.sort((a, b) => a.nome?.localeCompare(b.nome)),
    },
    {
      titulo: "Anexo",
      valor: patrimonio?.id_anexo,
      name: "id_anexo",
      tipo: "Select",
      options: anexos?.sort((a, b) => a.nome?.localeCompare(b.nome)),
    },
    {
      titulo: "Busca",
      valor: patrimonio?.busca,
      name: "busca",
      tipo: "Input",
      placeholder: "Nome do Equipamento, Nº Série, MAC, Nº Patrimônio",
    }

  ];
};

export const EditPatrimonio = (patrimonio, anexos, marcas , modelos) => {
  const { clientes } = useContext(ClientContext);
  const { fornecedores } = useContext(FornecedoresContext);
  return[
    {
      titulo: "Equipamento",
      valor: patrimonio?.produto ? patrimonio?.produto : patrimonio?.equipamento,
      name: "equipamento",
      tipo: "Input",
      disabled: true,
    },
    {
      titulo: "Cliente",
      valor: patrimonio?.id_cliente,
      name: "id_cliente",
      tipo: "Select",
      options: clientes.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Anexo",
      valor: patrimonio?.id_anexo,
      name: "id_anexo",
      tipo: "Select",
      options: anexos?.sort((a, b) => a?.nome?.localeCompare(b?.nome)),
    },
    {
      titulo: "Fornecedor",
      valor: patrimonio?.id_fornecedor ? patrimonio?.id_fornecedor : 2,
      name: "id_fornecedor",
      tipo: "Select",
      options: fornecedores.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Marca",
      valor: patrimonio?.id_marca,
      name: "id_marca",
      tipo: "Select",
      options: marcas.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Modelo",
      valor: patrimonio?.id_modelo,
      name: "id_modelo",
      tipo: "Select",
      options: modelos.sort((a, b) => a.nome.localeCompare(b.nome)),
    },
    {
      titulo: "Nome",
      valor: patrimonio?.nome,
      name: "nome",
      tipo: "Input",
    },
    {
      titulo: "Setor",
      valor: patrimonio?.setor,
      name: "setor",
      tipo: "Input",
    },
    {
      titulo:"Ramal",
      valor: patrimonio?.ramal,
      name: "ramal",
      tipo: "Input",
    },
    {
      titulo: "IP",
      valor: patrimonio?.ip_estatico,
      name: "ip_estatico",
      tipo: "Input",
    },
    {
      titulo: "PassWord",
      valor: patrimonio?.password_telefone,
      name: "password_telefone",
      tipo: "Input",
      disabled: Object.keys(patrimonio).includes("password_telefone") ? false : true,
      nextInput: 'Número de Série',
      maxLength: 8
    },
    {
      titulo: "Número de Série",
      valor: patrimonio?.nSerie ? patrimonio?.nSerie : patrimonio?.nserie,
      name: Object.keys(patrimonio).includes("nSerie") ? "nSerie" : "nserie",
      tipo: "Input",
      nextInput: 'MAC',
      maxLength: 16
    },
    {
      titulo: "MAC",
      valor: patrimonio?.nMac ? patrimonio?.nMac : patrimonio?.nmac,
      name: Object.keys(patrimonio).includes("nMac") ? "nMac" : "nmac",
      tipo: "Input",
      nextInput: 'Nº etiqueta patrimonio',
      maxLength: 12
    },
    {
      titulo: "Nº etiqueta patrimonio",
      valor: patrimonio?.nEtiqueta ? patrimonio?.nEtiqueta : patrimonio?.netiqueta,
      name: Object.keys(patrimonio).includes("nEtiqueta") ? "nEtiqueta" : "netiqueta",
      tipo: "Input",
      maxLength: 10
    },
    {
      titulo: "Data de envio",
      valor: patrimonio?.data_envio,
      name: "data_envio",
      tipo: "Calendar",
    },
    {
      titulo: "Hora de envio",
      valor: patrimonio?.hora_envio,
      name: "hora_envio",
      tipo: "Time",
    }
  ]
}

export const getComponentMap = (handleInputChange) => {
  return {
    Cadastro: (dado) => (
      <FormComp dados={dado} handleInputChange={handleInputChange} />
    ),
  };
};
