import React from 'react'

const ComercialCadastro = ({newClient, handleInputChange}) => {
  return (
    <div className='vizualizar-modal-content-cadastro'>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Nome Prefeito</h2>
                <input
                    type='text'
                    name='nome_prefeito'
                    value={newClient?.nome_prefeito}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Nome Vice-prefeito</h2>
                <input
                    type='text'
                    name='nome_vice_prefeito'
                    value={newClient?.nome_vice_prefeito}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Nome Secretário(a) Administração</h2>
                <input
                    type='text'
                    name='nome_secretario_adm'
                    value={newClient?.nome_secretario_adm}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>E-mail Secretario(a) Administração</h2>
                <input
                    type='text'
                    name='email_secretario_adm'
                    value={newClient?.email_secretario_adm}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Telefone Secretário(a)</h2>
                <input
                    type='text'
                    name='telefone_secretario_adm'
                    value={newClient?.telefone_secretario_adm}
                    onChange={handleInputChange}
                />
            </div>

            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Nome do Contato</h2>
                <input
                    type='text'
                    name='nome_contato_comercial'
                    value={newClient?.nome_contato_comercial}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Telefone:</h2>
                <input
                    type='text'
                    name='telefone_comercial'
                    value={newClient?.telefone_comercial}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Email</h2>
                <input
                    type='text'
                    name='email_comercial'
                    value={newClient?.email_comercial}
                    onChange={handleInputChange}
                />
            </div>
            <div className='vizualizar-modal-content-item-cadastro'>
                <h2>Setor</h2>
                <input
                    type='text'
                    name='setor_comercial'
                    value={newClient?.setor_comercial}
                    onChange={handleInputChange}
                />
            </div>
    </div>
  )
}

export default ComercialCadastro
