import { useState, useEffect, useContext } from "react";
import CryptoJS from "crypto-js";
import { ClientContext } from "../../../contexts/clientProvider";
import { RelatoriosContext } from "../../../contexts/relatoriosProvider";
import LoadingSpinner from "../../Loader/load";
import DataTable from "../../Table";

const PatrimonioCliente = () => {
  const { clientes } = useContext(ClientContext);
  const { patrimoniosCliente } = useContext(RelatoriosContext);
  const [clients, setClients] = useState(null);
  const [dados, setDados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [encryptedData, setEncryptedData] = useState(null);

  useEffect(() => {
    let newClients = clientes ? clientes : [];
    setClients(newClients);
  }, [clientes]);

  const handleSelectClient = async (e) => {
    setLoading(true);
    const id = e.target.value;
    const nTitulo = clients.find((client) => client.id === parseInt(id)).nome;
    setClientInfo({
      id: id,
      nome: nTitulo,
    });
    setTitulo(nTitulo);
    let nDados = await patrimoniosCliente(id);
    if (nDados) {
      nDados = nDados.map((pat) => {
        return {
          ...pat,
          netiqueta: pat.nEtiqueta,
          nserie: pat.nSerie,
            nmac: pat.nMac,
        };
      });
      setLoading(false);
    }
    setDados(nDados);
  };

  const encryptData = () => {
    if (!clientInfo) return null;
    const data = JSON.stringify(clientInfo);
    const secretKey = "EXPECTO-PATRONUM";
    const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encodeURIComponent(encrypted);
  };
  
  const headers = [
    { label: "Anexo", key: "nome_anexo", sortable: true },
    { label: "Modelo", key: "nome_modelo", sortable: false },
    { label: "Patrimônio", key: "netiqueta", sortable: false },
    { label: "Nº Série", key: "nserie", sortable: false },
    { label: "Nº Mac", key: "nmac", sortable: false },
    { label: "Nome", key: "nome", sortable: false },
    { label: "Setor", key: "setor", sortable: false },
    { label: "Ramal", key: "ramal", sortable: false },
    { label: "IP", key: "ip_estatico", sortable: false },
  ];

  useEffect(() => {
    if (clientInfo) {
      const encrypted = encryptData();
      if (encrypted) {
        setEncryptedData(encrypted);
      }
    }
  }, [clientInfo]);

  return (
    <>
      <select
        className="anexos-consulta-select"
        onChange={(e) => handleSelectClient(e)}
      >
        <option value="">Selecione o cliente</option>
        {clients &&
          clients
            .slice()
            .sort((a, b) => a.nome.localeCompare(b.nome))
            .map((client, index) => (
              <option key={index} value={client.id}>
                {client.nome}
              </option>
            ))}
      </select>
      {encryptedData && dados?.length > 0 && (
        <button
          className="open-table-button"
          onClick={() => {
            window.open(
              `/openTable/${encryptedData}`,
              "_blank"
            );
          }}
        >
          Visualizar em Tabela
        </button>
      )}

        {loading && <LoadingSpinner />}
        {dados && <DataTable headers={headers} data={dados} itemsPerPage={5} exportButton={true} docTitle={`Patrimônios da ${titulo}`} />}
    </>
  );
};

export default PatrimonioCliente;
