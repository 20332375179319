import { useState, useEffect, useContext } from "react";
import { DadosConsultaPatrimonio, getComponentMap } from "../../General/Format/patrimonio";
import { AnexosContext } from "../../../contexts/anexosProvider";
import LoadingSpinner from "../../Loader/load";
import PatrimonioModal from "./Modal";

const ConsultaTipo = ({ handleSearchClick, editedData, setEditedData, patrimonioModal, setPatrimonioModal, patrimonioType, loading }) => {
    const { anexosByClient } = useContext(AnexosContext);
    const [anexos, setAnexos] = useState([]);

    useEffect(() => {
        let idCliente = editedData?.id_cliente;
        if (idCliente) {
            anexosByClient(idCliente).then((anexos) => {
                setAnexos(anexos);
            });
        }
    }, [editedData?.id_cliente]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedData({ ...editedData, [name]: value });
    };

    const handleCloseModal = () => {
        setPatrimonioModal(false);
    }

    const dados = DadosConsultaPatrimonio(editedData, anexos);
    const componentMap = getComponentMap(handleInputChange);

    return <div className="patrimonio-form">
        {dados.map((dado, index) => (
            <div className="patrimonio-form-item" key={index}>
                {componentMap["Cadastro"](dado)}
            </div>
        ))}
        {loading ? <LoadingSpinner /> : <button className="patrimonio-cadastro-button" onClick={handleSearchClick}>Consultar</button>}
        {patrimonioModal && <PatrimonioModal patrimonioModal={patrimonioModal} handleCloseModal={handleCloseModal} patrimonioType={patrimonioType} />}
    </div>;

};

export default ConsultaTipo;