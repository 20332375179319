import { useContext, useEffect, useState } from 'react'
import { ClientContext } from '../../../../contexts/clientProvider'
import DataTable from '../../../Table'
import LoadingSpinner from '../../../Loader/load'

const Patrimonios = ({ client, handleFecharClick}) => {
    const { getPatrimoniosByCliente } = useContext(ClientContext)
    const [dados, setDados] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDados()
    }, [])

    const getDados = async () => {
        if (dados.length > 0) return
        setLoading(true)
        let newDados = await getPatrimoniosByCliente(client.id)
        setLoading(false)
        setDados(newDados)
    }

    const headers = [
        { label: 'Anexo', key: 'nome_anexo', sortable: true },
        { label: 'Modelo', key: 'nome_modelo', sortable: false },
        { label: 'Patrimonio', key: 'nEtiqueta', sortable: false },
        { label: 'Mac', key: 'nMac', sortable: false },
        { label: 'Nome', key: 'nome', sortable: false },
        { label: 'Setor', key: 'setor', sortable: false },
        { label: 'Ramal', key: 'ramal', sortable: false },
    ]

    return (
        <div>
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className='cliente-patrimonios-contander'>
                    <DataTable data={dados} headers={headers} itemsPerPage={5} />

                    <div className='vizualizar-edit-box'>
                            <button className='vizualizar-modal-button' onClick={() => handleFecharClick()}>Fechar</button>
                        </div>
                </div>
            )}
        </div>
    )
}

export default Patrimonios
