import React from 'react'
import { BiX } from "react-icons/bi";

const Editar = ({ servico, setServico, closeModal, handleSubmit }) => {

    return (
        <div className='servicos-modal'>
            <div className='servicos-modal-body'>
                <div className='servicos-modal-header'>
                    <h1>Editar Serviço</h1>
                    <BiX onClick={()=>closeModal()} className='fechar-top-button' />
                </div>
                <div className='servico-form'>
                    <div className='servico-form-item'>
                        <label>Nome</label>
                        <input
                            name='nome'
                            value={servico.nome}
                            onChange={(e) => setServico({ ...servico, nome: e.target.value })}
                        />
                    </div>
                </div>
                <div className="servicos-modal-footer">
                    <button className='servicos-modal-button' onClick={closeModal}>Fechar</button>
                    <button className='servicos-modal-button' onClick={handleSubmit}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default Editar
