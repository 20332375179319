import { useState } from 'react'
import Consulta from './consulta'
import Cadastro from './cadastro'
import './styles.css'

const VoxSoftwares = () => {
  const [type, setType] = useState('consulta')

  const moveToConsulta = () => {
    setType('consulta')
  }

  const softwaresType = {
    cadastro: <Cadastro moveToConsulta={moveToConsulta} />,
    consulta: <Consulta />,
  }

  return (
    <div className='softwares-contander'>
      <div className='softwares-header'>
        <button
          className="softwares-button"
          onClick={() => setType('consulta')}
          style={
            type === "consulta"
              ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" }
              : null
          }
        >
          Consulta
        </button>
        <button
          className="softwares-button"
          onClick={() => setType('cadastro')}
          style={ type === "cadastro" ? { borderBottom: "1px solid #9ac31c", color: "#9ac31c" } : null}>
          Cadastro
        </button>
      </div>
      <div className='softwares-body'>{softwaresType[type]}</div>
    </div>
  )
}

export default VoxSoftwares
