import React from "react";

const SelectLabel = ({ dados, handleInputChange }) => {

  return (
    <>
      <label>{dados?.titulo}</label>
      <select
        name={dados?.name}
        onChange={(e) => handleInputChange(e, dados?.index)}
        value={dados?.valorSelect ? dados?.valorSelect : dados?.valor}
      >
        <option key={dados.titulo} value="">
          Selecione uma opção
        </option>
        {dados.options &&
          dados.options.map((item, index) => (
            <option key={index} value={dados.value === 'nome' ? item.name : (item.id || item._id)}>
              {item.nome}
            </option>
          ))}
      </select>
    </>
  );
};

export default SelectLabel;
