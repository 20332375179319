import { createContext, useState, useContext, useMemo } from "react";
import { UserContext } from "./userProvider";
import apiUser from "../apiUser";

export const VoxSoftwaresContext = createContext();

export const VoxSoftwaresProvider = ({ children }) => {
    const { setAlert } = useContext(UserContext);
    const [organizacoes, setOrganizacoes] = useState([]);
    const [ucms, setUcms] = useState([]);
    const services = {
        voxchat:{
            ativo: true,
            config:{},
            grupos: [],
        },
        voxbot:{
            ativo: true,
            atendentes: [],
            bots:[],
            config:{},
            contatos: [],
            flows: [],
        },
        agenda:{
            ativo: true,
        },
        voxcount:{
            ativo: true,

        },
        videoconf:{
            ativo: true,
            config:{},
            linkDeRamais: [],
        },
    }

    const novoUsuario = {
        email: "",
        senha: "",
        nome: "",
        permissões: [],
        config: {
            firstAccess: true,
        },
        telefone: "",
        foto: "",
        status: "offline",
        ramal: "",
        departamento: "",
        setor: "",
        atendente: false,
        admin: false,
        organização: "",
        ativo: true,
        profissional: false,
        credencial: "",
        disparador: false,
        atendenteGrupo: false,
        restricao: {
            ativo: false,
            intervalos: [],
        },
    }
    const handleTitles = (service) => {
        const titles = {
          voxchat: "Vox Chat",
          voxbot: "Chat Bot",
          agenda: "Agenda",
          videoconf: "Tele Atendimento",
          voxcount: "Vox Count",
        };
    
        return titles[service] || service;
    }

    const getOrganizacoes = async () => {
        try {
            const response = await apiUser.get("/voxSoftwares/orgs");
            if(response.data){
                setOrganizacoes(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getUcms = async (id) => {
        try {
            const response = await apiUser.get("/voxSoftwares/ucms");
            if(response.data){
                setUcms(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const creatNewOrg = async (data) => {
        try {
            const response = await apiUser.post("/voxSoftwares/novaOrganizacao",{ organizacao: data});
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                let newOrg = response.data.organizacao;
                setOrganizacoes((prevData) => ([...prevData, newOrg]));
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteOrg = async (id) => {
        try {
            const response = await apiUser.post(`/voxSoftwares/deleteOrganizacao`,{orgId: id});
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                setAlert({ visible: true, title: "Sucesso", placeholder: 'Organização removida com sucesso!', confirm: false, type: "success" });
                let newOrgs = organizacoes.filter(org => org._id !== id);
                setOrganizacoes(newOrgs);
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateOrg = async (data) => {
        delete data.servicos
        try {
            const response = await apiUser.post("/voxSoftwares/updateOrganizacao",{ organizacao: data});
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                let newOrg = response.data.organizacao;
                let newOrgs = organizacoes.map(org =>{
                    if(org.dbname === newOrg.dbname){
                        let id = org._id;
                        newOrg._id = id;
                        return newOrg;
                    }
                    return org;
                });
                setOrganizacoes(newOrgs);
                setAlert({ visible: true, title: "Sucesso", placeholder: 'Organização editada com sucesso!', confirm: false, type: "success" });
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    
    }

    const addUser = async (user,organizacao) => {
        try {
            const response = await apiUser.post("/voxSoftwares/newUser",{ user, organizacao });
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                let newUser = response.data.user;
                let orgId = response.data.orgId
                let newOrgs = organizacoes.map(org => {
                    if(org._id.toString() === orgId.toString()){
                        org.users.push(newUser);
                    }
                    return org;
                });
                setOrganizacoes(newOrgs);
                setAlert({ visible: true, title: "Sucesso", placeholder: 'Usuário criado com sucesso!', confirm: false, type: "success" });     
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateUser = async (user,organizacao) => {
        try {
            const response = await apiUser.post("/voxSoftwares/updateUser",{ user, organizacao});
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                let newUser = response.data.user;
                let orgId = response.data.orgId
                let newOrgs = organizacoes.map(org => {
                    if(org._id.toString() === orgId.toString()){
                        org.users = org.users.map(usr => {
                            if(usr._id.toString() === newUser._id.toString()){
                                return newUser;
                            }
                            return usr;
                        });
                    }
                    return org;
                });
                setOrganizacoes(newOrgs);
                setAlert({ visible: true, title: "Sucesso", placeholder: 'Usuário editado com sucesso!', confirm: false, type: "success" });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteUser = async (user,organizacao) => {
        try {
            const response = await apiUser.post("/voxSoftwares/deleteUser",{ user, organizacao});
            if(response.data.error){
                setAlert({ visible: true, title: "Erro", placeholder: response.data.error, confirm: false, type: "negative" });
            }else{
                let orgId = response.data.orgId
                let newOrgs = organizacoes.map(org => {
                    if(org._id.toString() === orgId.toString()){
                        org.users = org.users.filter(usr => usr._id !== user._id);
                    }
                    return org;
                });
                setOrganizacoes(newOrgs);
                setAlert({ visible: true, title: "Sucesso", placeholder: 'Usuário removido com sucesso!', confirm: false, type: "success" });

            }
        } catch (error) {
            console.log(error);
        }
    }

    useMemo(() => {
        getOrganizacoes();
    }, []);

    useMemo(() => {
        getUcms();
    }, []);
    
    return (
        <VoxSoftwaresContext.Provider value={{
            organizacoes,
            setOrganizacoes,
            creatNewOrg,
            deleteOrg,
            updateOrg,
            services,
            handleTitles,
            setAlert,
            ucms,
            novoUsuario,
            addUser,
            updateUser,
            deleteUser
        }}>
            {children}
        </VoxSoftwaresContext.Provider>
    );
};
